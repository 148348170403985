import type { SVGProps } from 'react'

const ColorfulThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.77013 8.7489L3.66546 8.39099V9.91352H1.47119V1.85297L3.39676 1.58428L4.33716 2.12165L14.2337 1.58428L15.1741 2.43512L14.9054 3.59942L15.6219 4.18157V6.01758L16.428 6.77886L15.9354 8.7489L15.6219 10.9435H11.1886L11.9947 14.6155V15.9589L10.8304 16.2725L9.80042 15.6006L7.9644 11.8838L5.77013 8.7489Z'
      fill='#FFB3B3'
    />
    <path
      d='M14.5 8.71332H15.3C15.9667 8.71332 16.5 8.17999 16.5 7.51332C16.5 6.84665 15.9667 6.31332 15.3 6.31332H14.5'
      stroke='#870D0D'
      strokeWidth='1.3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5 3.91333H14.7C15.3667 3.91333 15.9 4.44666 15.9 5.11332C15.9 5.77999 15.3667 6.31332 14.7 6.31332H14.5'
      stroke='#870D0D'
      strokeWidth='1.3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5002 8.71332H14.9668C15.6335 8.71332 16.1668 9.24666 16.1668 9.91332C16.1668 10.58 15.6335 11.1133 14.9668 11.1133H10.9668L11.0335 11.2467C11.7002 12.38 12.1002 13.7133 12.1668 15.1133C12.2335 15.58 12.0335 15.98 11.6335 16.2467C11.3668 16.4467 11.1002 16.4467 10.7668 16.38C10.4335 16.3133 10.1668 16.1133 10.0335 15.8467C8.6735 13.4673 7.76883 10.4667 5.4335 8.77999H3.8335'
      stroke='#870D0D'
      strokeWidth='1.3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5002 3.91333H14.0335C14.7668 3.91333 15.3002 3.31333 15.1668 2.58C15.1002 1.98 14.5668 1.58 13.9668 1.58H9.6335C9.36683 1.58 9.0335 1.58 8.76683 1.64666L5.2335 2.24666H3.8335'
      stroke='#870D0D'
      strokeWidth='1.3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.83333 9.91333H1.5V1.58H3.83333V9.91333Z'
      stroke='#870D0D'
      strokeWidth='1.3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ColorfulThumbsDown
