import type { SVGProps } from 'react'
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 19 19' fill='none' {...props}>
    <path d='M5.771 9.5H13.771' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.771 1.5C14.1893 1.5 17.771 5.08172 17.771 9.5C17.771 13.9183 14.1893 17.5 9.771 17.5C5.35272 17.5 1.771 13.9183 1.771 9.5C1.771 5.08172 5.35272 1.5 9.771 1.5Z'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgAdd
