import type { SVGProps } from 'react'
const ColorfulDemographic = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.5341 11.8226C17.3762 11.9032 17.24 12.0207 17.1373 12.1653C17.0346 12.3098 16.9683 12.477 16.9441 12.6526C16.4541 15.2426 13.3741 16.9326 11.9941 16.9326C10.6141 16.9326 7.53413 15.2426 7.04413 12.6526C7.01997 12.4783 6.95481 12.3122 6.85398 12.1679C6.75316 12.0236 6.61955 11.9053 6.46413 11.8226C5.37413 11.3426 5.10413 9.37264 5.97413 8.93264H6.61413C6.84466 8.93269 7.06813 8.85309 7.24671 8.7073C7.42529 8.56152 7.54802 8.35852 7.59413 8.13264L8.03413 7.30264C8.15183 7.08045 8.34892 6.91075 8.58614 6.82735C8.82335 6.74396 9.08328 6.75299 9.31413 6.85264C10.158 7.22842 11.0704 7.42588 11.9941 7.43264C12.9134 7.42384 13.8214 7.22998 14.6641 6.86264C14.8973 6.7608 15.1606 6.75168 15.4002 6.83715C15.6399 6.92262 15.838 7.09624 15.9541 7.32264L16.3541 8.13264C16.4026 8.35732 16.526 8.55886 16.7041 8.70421C16.8821 8.84957 17.1043 8.93011 17.3341 8.93264H18.0141C18.8941 9.37264 18.6141 11.3426 17.5341 11.8226Z'
      fill='#DCF3FC'
    />
    <path
      d='M18.864 2.84262L18.014 8.93262H17.334C17.1042 8.93008 16.882 8.84954 16.704 8.70419C16.5259 8.55883 16.4025 8.3573 16.354 8.13262L15.954 7.32262C15.8379 7.09621 15.6398 6.92259 15.4001 6.83712C15.1604 6.75166 14.8972 6.76077 14.664 6.86262C13.8213 7.22995 12.9133 7.42381 11.994 7.43262C11.0703 7.42585 10.1579 7.22839 9.31401 6.85262C9.08315 6.75296 8.82323 6.74393 8.58602 6.82733C8.3488 6.91072 8.15171 7.08042 8.03401 7.30262L7.59401 8.13262C7.5479 8.35849 7.42517 8.5615 7.24659 8.70728C7.06801 8.85306 6.84454 8.93266 6.61401 8.93262H5.97401L5.12401 2.84262C5.08762 2.59326 5.14672 2.33935 5.28949 2.1317C5.43225 1.92404 5.64815 1.77794 5.89401 1.72262C7.89786 1.2674 9.9403 1.00288 11.994 0.932617C14.0477 1.00288 16.0902 1.2674 18.094 1.72262C18.3408 1.77621 18.5579 1.92192 18.701 2.13003C18.844 2.33814 18.9023 2.59299 18.864 2.84262Z'
      fill='#DCF3FC'
    />
    <path
      d='M17.4001 18.0125V18.0225C16.925 19.0587 16.1623 19.9366 15.2026 20.5517C14.2429 21.1668 13.1267 21.4932 11.9869 21.4922C10.847 21.4911 9.73141 21.1626 8.77288 20.5457C7.81435 19.9289 7.05323 19.0496 6.58008 18.0125L8.12008 17.5035C8.22242 17.4655 8.33466 17.4635 8.43828 17.4979C8.54191 17.5323 8.63072 17.6009 8.69008 17.6925C9.05741 18.2288 9.54989 18.6674 10.1249 18.9705C10.6999 19.2736 11.3401 19.432 11.9901 19.432C12.6401 19.432 13.2803 19.2736 13.8553 18.9705C14.4303 18.6674 14.9227 18.2288 15.2901 17.6925C15.3494 17.6009 15.4382 17.5323 15.5419 17.4979C15.6455 17.4635 15.7577 17.4655 15.8601 17.5035C16.2141 17.6225 16.7741 17.8125 17.4001 18.0125Z'
      fill='#DCF3FC'
    />
    <path
      d='M23.3571 22.9865V24H0.642822V22.9865C0.644167 22.1603 0.890562 21.3542 1.34924 20.6753C1.80791 19.9964 2.45712 19.4768 3.21052 19.1858L6.65717 18C7.12444 19.0511 7.87611 19.9422 8.82273 20.5674C9.76936 21.1926 10.8711 21.5256 11.9968 21.5267C13.1225 21.5277 14.2248 21.1969 15.1726 20.5734C16.1203 19.95 16.8736 19.0603 17.3428 18.0101C18.8834 18.527 20.7993 19.1858 20.7993 19.1858C21.5519 19.4768 22.2001 19.9966 22.6572 20.6757C23.1143 21.3549 23.3585 22.1611 23.3571 22.9865Z'
      fill='#97DCF7'
    />
    <path
      d='M18.0939 1.72262C16.09 1.2674 14.0476 1.00288 11.9939 0.932617H11.9799V7.42862H11.9939C12.9131 7.41981 13.8212 7.22595 14.6639 6.85862C14.897 6.75677 15.1603 6.74766 15.4 6.83312C15.6396 6.91859 15.8377 7.09221 15.9539 7.31862L16.3539 8.12862C16.4024 8.3533 16.5257 8.55483 16.7038 8.70019C16.8819 8.84554 17.104 8.92608 17.3339 8.92862H18.0139L18.8639 2.83862C18.9013 2.58958 18.8426 2.33561 18.6996 2.12833C18.5566 1.92104 18.34 1.77597 18.0939 1.72262Z'
      fill='#97DCF7'
    />
    <path
      d='M6.58402 18.0126C6.90278 18.7018 7.34701 19.3258 7.89402 19.8526L6.58402 20.2826L3.09402 21.4526C2.53575 21.6609 2.03108 21.9913 1.61689 22.4197C1.20269 22.8481 0.88944 23.3636 0.700019 23.9286H0.494019V22.9286C0.49538 22.1134 0.744874 21.3181 1.20932 20.6482C1.67376 19.9783 2.33114 19.4657 3.09402 19.1786L6.58402 18.0126Z'
      fill='#DCF3FC'
    />
    <path
      d='M23.494 22.9287V23.9287H23.284C23.0978 23.3637 22.7874 22.8477 22.3755 22.4185C21.9636 21.9893 21.4608 21.6579 20.904 21.4487C20.904 21.4487 18.964 20.7987 17.404 20.2897V20.2787C16.894 20.1197 16.434 19.9687 16.094 19.8487C16.6434 19.3278 17.0881 18.7067 17.404 18.0187C18.964 18.5287 20.904 19.1787 20.904 19.1787C21.6661 19.4658 22.3225 19.9786 22.7853 20.6487C23.2481 21.3189 23.4954 22.1143 23.494 22.9287Z'
      fill='#DCF3FC'
    />
    <path
      d='M10.5 13.4286C10.8727 13.7618 11.3582 13.9405 11.858 13.9286H12.142C12.6418 13.9407 13.1274 13.7619 13.5 13.4286'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 9.92851C10.8941 9.76256 10.7452 9.62843 10.5691 9.54038C10.393 9.45234 10.1963 9.41368 10 9.42851C9.80369 9.41368 9.60702 9.45234 9.43094 9.54038C9.25485 9.62843 9.10592 9.76256 9 9.92851'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.0001 9.92851C14.8942 9.76256 14.7453 9.62843 14.5692 9.54038C14.3931 9.45234 14.1964 9.41368 14.0001 9.42851C13.8038 9.41368 13.6071 9.45234 13.4311 9.54038C13.255 9.62843 13.106 9.76256 13.0001 9.92851'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9999 0.928711C14.0539 0.997554 16.0966 1.2631 18.0999 1.72171C18.3461 1.77571 18.5628 1.92067 18.7067 2.1276C18.8505 2.33452 18.911 2.58814 18.8759 2.83771L18.0199 8.92871C18.8949 9.36671 18.6199 11.3357 17.5349 11.8207C17.3792 11.9017 17.2451 12.0187 17.1439 12.1621C17.0426 12.3054 16.9771 12.4709 16.9529 12.6447C16.4599 15.2427 13.3769 16.9287 11.9999 16.9287C10.6229 16.9287 7.5399 15.2427 7.0469 12.6447C7.02265 12.4709 6.95717 12.3054 6.85593 12.1621C6.75468 12.0187 6.62061 11.9017 6.4649 11.8207C5.3779 11.3357 5.1049 9.36671 5.9799 8.92871L5.1269 2.83771C5.0917 2.58856 5.15173 2.33529 5.29501 2.12844C5.43829 1.92159 5.65428 1.77636 5.8999 1.72171C7.90323 1.2631 9.9459 0.997554 11.9999 0.928711V0.928711Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.0221 8.92861H17.3421C17.1114 8.92889 16.8877 8.84939 16.7089 8.70359C16.5301 8.55778 16.4072 8.35465 16.3611 8.12861L15.9551 7.32861C15.84 7.10211 15.6431 6.9279 15.4042 6.84135C15.1654 6.7548 14.9025 6.7624 14.6691 6.86261C13.8264 7.22831 12.9187 7.42079 12.0001 7.42861C11.0758 7.41905 10.1629 7.22314 9.31609 6.85261C9.0857 6.75301 8.82629 6.74369 8.58935 6.8265C8.35241 6.90931 8.15528 7.07819 8.03709 7.29961L7.60009 8.12861C7.55395 8.35465 7.43107 8.55778 7.25228 8.70359C7.07349 8.84939 6.84979 8.92889 6.61909 8.92861H5.93909'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 0.928711V4.42871' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.06287 1.30957L8.49987 3.92857' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.9369 1.30957L15.4999 3.92857' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M23.3571 24V23.0048C23.3567 22.1941 23.1106 21.4028 22.6517 20.7368C22.1929 20.0708 21.5431 19.5618 20.7894 19.2781L15.8219 17.5973C15.7199 17.5628 15.6096 17.5628 15.5076 17.5973C15.4057 17.6318 15.3177 17.6989 15.257 17.7883C14.8942 18.322 14.4079 18.7585 13.84 19.0601C13.2722 19.3617 12.6399 19.5194 11.998 19.5194C11.3561 19.5194 10.7238 19.3617 10.156 19.0601C9.58811 18.7585 9.10175 18.322 8.73898 17.7883C8.67819 17.699 8.59013 17.6321 8.48817 17.5978C8.38622 17.5635 8.27595 17.5637 8.17409 17.5983L3.21052 19.2781C2.4568 19.5618 1.80705 20.0708 1.34818 20.7368C0.889319 21.4028 0.643224 22.1941 0.642822 23.0048V24'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.4089 18.0106C16.9358 19.048 16.1743 19.9275 15.2153 20.5441C14.2563 21.1608 13.1401 21.4887 11.9999 21.4887C10.8598 21.4887 9.74361 21.1608 8.78458 20.5441C7.82554 19.9275 7.06407 19.048 6.59094 18.0106'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulDemographic
