import type { SVGProps } from 'react'
const AutoTag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10.5625 13.0445L12.5343 9.8463C12.6119 9.72153 12.7199 9.61854 12.8482 9.54702C12.9765 9.47551 13.1209 9.43781 13.2678 9.4375C13.4974 9.4375 13.7176 9.52871 13.8799 9.69105C14.0423 9.8534 14.1335 10.0736 14.1335 10.3032V12.5876H17.5481C17.6777 12.5894 17.8054 12.6191 17.9224 12.6747C18.0395 12.7303 18.1432 12.8104 18.2265 12.9097C18.3098 13.009 18.3708 13.125 18.4052 13.2499C18.4395 13.3749 18.4466 13.5058 18.4258 13.6337L17.8126 17.6856C17.781 17.8991 17.6738 18.0941 17.5105 18.2354C17.3473 18.3766 17.1388 18.4545 16.9229 18.4551H10.9352'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.4375 6.95547L7.46565 10.1537C7.38813 10.2785 7.28012 10.3815 7.15182 10.453C7.0235 10.5245 6.87911 10.5622 6.73222 10.5625C6.50262 10.5625 6.28244 10.4713 6.12009 10.3089C5.95773 10.1466 5.86654 9.92641 5.86654 9.69681V7.41236H2.45188C2.32226 7.41058 2.19457 7.38088 2.07759 7.32531C1.96048 7.26973 1.85684 7.18959 1.77351 7.09031C1.69019 6.99104 1.62923 6.87499 1.59484 6.75006C1.56046 6.62511 1.55336 6.49423 1.57417 6.36632L2.18736 2.31441C2.21898 2.10088 2.32623 1.90586 2.48947 1.76458C2.65274 1.62343 2.86124 1.54551 3.0771 1.54491H9.06477'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default AutoTag
