import type { SVGProps } from 'react'
const ArrowCurvedLeftDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='20' viewBox='0 0 22 20' fill='none' {...props}>
    <path
      d='M11.7154 3.57129L7.42969 7.857L11.7154 12.1427'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.2868 16.4284V13.5712C20.2868 12.0557 19.6848 10.6022 18.6132 9.53061C17.5415 8.45897 16.0881 7.85693 14.5725 7.85693H7.42969'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.00056 3.57129L1.71484 7.857L6.00056 12.1427'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ArrowCurvedLeftDouble
