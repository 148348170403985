import type { SVGProps } from 'react'

export const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.23077 2.75H15.7692C16.0881 2.75 16.25 2.97726 16.25 3.14286V12.8571C16.25 13.0227 16.0881 13.25 15.7692 13.25H2.23077C1.91194 13.25 1.75 13.0227 1.75 12.8571V3.14286C1.75 2.97726 1.91194 2.75 2.23077 2.75Z'
      stroke='currentcolor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.57153 3.42871L8.26868 9.143C8.47403 9.31404 8.73284 9.40771 9.0001 9.40771C9.26737 9.40771 9.52618 9.31404 9.73153 9.143L16.4287 3.42871'
      stroke='currentcolor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EnvelopeIcon
