import type { SVGProps } from 'react'
const User = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13 7C13 10.3137 10.3137 13 7 13C3.6863 13 1 10.3137 1 7C1 3.6863 3.6863 1 7 1C10.3137 1 13 3.6863 13 7Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.95889 9.48511C8.95889 9.48511 8.46106 10.4647 6.99971 10.4647C5.53837 10.4647 5.04053 9.48511 5.04053 9.48511'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.71414 7.57207C4.57888 7.57207 4.46924 7.46243 4.46924 7.32717C4.46924 7.19192 4.57888 7.08228 4.71414 7.08228'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.71436 7.57207C4.84961 7.57207 4.95925 7.46243 4.95925 7.32717C4.95925 7.19192 4.84961 7.08228 4.71436 7.08228'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.28543 7.57207C9.15017 7.57207 9.04053 7.46243 9.04053 7.32717C9.04053 7.19192 9.15017 7.08228 9.28543 7.08228'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.28564 7.57207C9.4209 7.57207 9.53054 7.46243 9.53054 7.32717C9.53054 7.19192 9.4209 7.08228 9.28564 7.08228'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.0873 5.60804C5.83135 5.60804 7.39716 4.84502 8.46916 3.63457C9.53835 4.84185 11.0988 5.60405 12.8374 5.60802C12.2096 2.96555 9.83417 1 6.99965 1C4.41086 1 2.205 2.63952 1.36377 4.93688C2.17724 5.36546 3.10395 5.60804 4.0873 5.60804Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default User
