import type { SVGProps } from 'react'
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.54353 1.3524L10.2599 4.96569C10.2989 5.0574 10.3603 5.13684 10.438 5.19609C10.5156 5.25534 10.6068 5.29233 10.7025 5.30338L14.4914 5.88871C14.6011 5.90341 14.7045 5.95036 14.7896 6.02407C14.8747 6.09778 14.9379 6.19521 14.9719 6.30498C15.0058 6.41475 15.0091 6.53233 14.9814 6.64399C14.9537 6.75564 14.896 6.85677 14.8152 6.93555L12.0842 9.7609C12.0145 9.8288 11.9622 9.91381 11.932 10.0082C11.9018 10.1025 11.8948 10.2033 11.9115 10.3012L12.5699 14.2747C12.589 14.3889 12.577 14.5063 12.5352 14.6137C12.4934 14.7211 12.4235 14.8141 12.3335 14.8821C12.2435 14.9502 12.1371 14.9905 12.0262 14.9985C11.9153 15.0065 11.8045 14.982 11.7064 14.9276L8.29526 13.0478C8.20791 13.003 8.1119 12.9798 8.0146 12.9798C7.91729 12.9798 7.82128 13.003 7.73393 13.0478L4.32282 14.9276C4.22468 14.982 4.11387 15.0065 4.003 14.9985C3.89213 14.9905 3.78564 14.9502 3.69566 14.8821C3.60568 14.8141 3.53582 14.7211 3.49403 14.6137C3.45223 14.5063 3.44018 14.3889 3.45924 14.2747L4.11772 10.2562C4.13441 10.1582 4.12735 10.0575 4.09718 9.96314C4.06702 9.86878 4.01471 9.78378 3.945 9.71587L1.18157 6.93555C1.09981 6.85461 1.04232 6.75069 1.01605 6.63637C0.989778 6.52205 0.995861 6.40225 1.03356 6.29146C1.07126 6.18067 1.13896 6.08366 1.22845 6.01218C1.31795 5.9407 1.4254 5.89781 1.53779 5.88871L5.32672 5.30338C5.42236 5.29233 5.51355 5.25534 5.59121 5.19609C5.66886 5.13684 5.73027 5.0574 5.7693 4.96569L7.48566 1.3524C7.5324 1.24716 7.60703 1.15806 7.70076 1.09562C7.79448 1.03318 7.90338 1 8.0146 1C8.12582 1 8.23471 1.03318 8.32844 1.09562C8.42216 1.15806 8.49679 1.24716 8.54353 1.3524Z'
      stroke='currentcolor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgStar
