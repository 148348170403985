import type { SVGProps } from 'react'
const SvgAutomation = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 9.5 1.857 22.643M22.46 2.72l-1.078 3.174a.524.524 0 0 0 0 .474l2.015 2.688a.511.511 0 0 1-.423.82h-3.362a.553.553 0 0 0-.424.217l-1.873 2.688a.513.513 0 0 1-.912-.14l-1-3.2a.487.487 0 0 0-.334-.334L11.86 8.11a.513.513 0 0 1-.141-.909l2.746-1.933a.55.55 0 0 0 .218-.422V1.49A.51.51 0 0 1 15.202 1a.514.514 0 0 1 .252.069l2.695 2.01a.527.527 0 0 0 .474.063l3.183-1.075a.515.515 0 0 1 .654.653ZM4.184 1v3.546m0 0L1.796 6.934m2.388-2.388L6.57 6.934M4.184 4.546 1 2.955m3.184 1.591 3.183-1.591'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.357 17.5a.857.857 0 1 0-1.714 0v1.143H18.5a.857.857 0 1 0 0 1.714h1.143V21.5a.857.857 0 1 0 1.714 0v-1.143H22.5a.857.857 0 1 0 0-1.714h-1.143V17.5Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgAutomation
