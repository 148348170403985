import type { SVGProps } from 'react'
const SvgChatBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.9978 1.03983C7.67169 1.04024 6.37053 1.40044 5.23289 2.08208C4.09526 2.76371 3.16376 3.74124 2.53761 4.91056C1.91146 6.07987 1.6141 7.39718 1.67722 8.72218C1.74033 10.0472 2.16155 11.3302 2.89601 12.4347L1.66663 15.7065L5.78336 14.9619C6.77466 15.4464 7.86251 15.7009 8.96582 15.7064C10.0691 15.7119 11.1594 15.4682 12.1555 14.9934C13.1515 14.5187 14.0276 13.8253 14.7184 12.9647C15.4092 12.1042 15.897 11.0987 16.1453 10.0234C16.3935 8.94805 16.396 7.83052 16.1524 6.7541C15.9088 5.67769 15.4255 4.67015 14.7384 3.8066C14.0513 2.94305 13.1783 2.24575 12.1843 1.76669C11.1904 1.28764 10.1011 1.03918 8.9978 1.03983V1.03983Z'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SvgChatBubble
