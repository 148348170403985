import type { SVGProps } from 'react'
const ColorfulCircleClock = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2925_16517)'>
      <path
        d='M8.00018 15.2428C12.0004 15.2428 15.243 12.0001 15.243 7.99994C15.243 3.99982 12.0004 0.75708 8.00018 0.75708C4.00006 0.75708 0.757324 3.99982 0.757324 7.99994C0.757324 12.0001 4.00006 15.2428 8.00018 15.2428Z'
        fill='#FFCA99'
      />
      <path
        d='M8.00018 15.2428C12.0004 15.2428 15.243 12.0001 15.243 7.99994C15.243 3.99982 12.0004 0.75708 8.00018 0.75708C4.00006 0.75708 0.757324 3.99982 0.757324 7.99994C0.757324 12.0001 4.00006 15.2428 8.00018 15.2428Z'
        stroke='#EA6100'
        strokeWidth='1.11'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.19873 5.19946V7.98518L5.36844 11.2835' stroke='#EA6100' strokeWidth='1.11' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_2925_16517'>
        <rect width='15.6' height='15.6' fill='white' transform='translate(0.200195 0.199951)' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulCircleClock
