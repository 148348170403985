import type { SVGProps } from 'react'
const SvgAudience = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#audience_svg__a)' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M12 15.429a3.857 3.857 0 1 0 0-7.715 3.857 3.857 0 0 0 0 7.715ZM18.857 23.143a7.714 7.714 0 0 0-13.714 0' />
      <path d='M20.571 18.103a10.715 10.715 0 1 0-17.005 0' />
    </g>
    <defs>
      <clipPath id='audience_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgAudience
