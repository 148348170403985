import type { SVGProps } from 'react'

const ColorfulOrganization = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 41 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Group-Meeting-Call--Streamline-Core.svg'>
      <g id='Group-Meeting-Call--Streamline-Core.svg_2'>
        <g id='Group 2659'>
          <path
            id='Ellipse 1159'
            d='M20.0433 13.2031C16.6261 13.2031 13.6893 15.2601 12.4033 18.2031H27.6832C26.3973 15.2601 23.4605 13.2031 20.0433 13.2031Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1165'
            d='M17.0433 13.2031C16.1826 13.2031 12.9686 15.2601 11.6826 18.2031H24.6832C23.3973 15.2601 20.4605 13.2031 17.0433 13.2031Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1163'
            d='M15.876 5.70241C15.876 6.80748 16.315 7.86729 17.0964 8.64869C17.8778 9.4301 18.9376 9.86908 20.0426 9.86908C21.1477 9.86908 22.2075 9.4301 22.9889 8.64869C23.7703 7.86729 24.2093 6.80748 24.2093 5.70241C24.2093 4.59734 23.7703 3.53753 22.9889 2.75613C22.2075 1.97472 21.1477 1.53574 20.0426 1.53574C18.9376 1.53574 17.8778 1.97472 17.0964 2.75613C16.315 3.53753 15.876 4.59734 15.876 5.70241Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1164'
            d='M15.6816 5.70241C15.6816 6.80748 16.1206 7.86729 16.902 8.64869C17.6834 9.4301 16.9373 9.86908 18.0424 9.86908C19.1474 9.86908 20.2072 9.4301 20.9886 8.64869C21.77 7.86729 22.209 6.80748 22.209 5.70241C22.209 4.59734 21.77 3.53753 20.9886 2.75613C20.2072 1.97472 19.1474 1.53574 18.0424 1.53574C16.9373 1.53574 17.6834 1.97472 16.902 2.75613C16.1206 3.53753 15.6816 4.59734 15.6816 5.70241Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1161'
            d='M15.877 5.70241C15.877 6.80748 16.3159 7.86729 17.0973 8.64869C17.8787 9.4301 18.9386 9.86908 20.0436 9.86908C21.1487 9.86908 22.2085 9.4301 22.9899 8.64869C23.7713 7.86729 24.2103 6.80748 24.2103 5.70241C24.2103 4.59734 23.7713 3.53753 22.9899 2.75613C22.2085 1.97472 21.1487 1.53574 20.0436 1.53574C18.9386 1.53574 17.8787 1.97472 17.0973 2.75613C16.3159 3.53753 15.877 4.59734 15.877 5.70241Z'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1162'
            d='M12.4033 18.2031C13.6893 15.2601 16.6261 13.2031 20.0433 13.2031C23.4605 13.2031 26.3973 15.2601 27.6832 18.2031'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Group 2662'>
          <path
            id='Ellipse 1159_2'
            d='M9.32256 35.6674C5.90536 35.6674 2.96855 37.7244 1.68262 40.6674H16.9625C15.6766 37.7244 12.7398 35.6674 9.32256 35.6674Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1165_2'
            d='M6.32256 35.6673C5.68262 35.6673 2.24785 37.7243 0.961914 40.6673H13.9625C12.6766 37.7243 9.73976 35.6673 6.32256 35.6673Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1163_2'
            d='M5.15527 28.1666C5.15527 29.2717 5.59426 30.3315 6.37566 31.1129C7.15707 31.8943 8.21688 32.3333 9.32195 32.3333C10.427 32.3333 11.4868 31.8943 12.2682 31.1129C13.0496 30.3315 13.4886 29.2717 13.4886 28.1666C13.4886 27.0616 13.0496 26.0018 12.2682 25.2204C11.4868 24.439 10.427 24 9.32195 24C8.21688 24 7.15707 24.439 6.37566 25.2204C5.59426 26.0018 5.15527 27.0616 5.15527 28.1666Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1164_2'
            d='M4.96191 28.1666C4.96191 29.2717 5.4009 30.3315 6.1823 31.1129C6.96371 31.8943 6.21756 32.3333 7.32263 32.3333C8.4277 32.3333 9.48751 31.8943 10.2689 31.1129C11.0503 30.3315 11.4893 29.2717 11.4893 28.1666C11.4893 27.0616 11.0503 26.0018 10.2689 25.2204C9.48751 24.439 8.4277 24 7.32263 24C6.21756 24 6.96371 24.439 6.1823 25.2204C5.4009 26.0018 4.96191 27.0616 4.96191 28.1666Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1161_2'
            d='M5.15527 28.1666C5.15527 29.2717 5.59426 30.3315 6.37566 31.1129C7.15707 31.8943 8.21688 32.3333 9.32195 32.3333C10.427 32.3333 11.4868 31.8943 12.2682 31.1129C13.0496 30.3315 13.4886 29.2717 13.4886 28.1666C13.4886 27.0616 13.0496 26.0018 12.2682 25.2204C11.4868 24.439 10.427 24 9.32195 24C8.21688 24 7.15707 24.439 6.37566 25.2204C5.59426 26.0018 5.15527 27.0616 5.15527 28.1666Z'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1162_2'
            d='M1.68262 40.6674C2.96855 37.7244 5.90536 35.6674 9.32256 35.6674C12.7398 35.6674 15.6766 37.7244 16.9625 40.6674'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Group 2663'>
          <path
            id='Ellipse 1159_3'
            d='M30.8226 35.6674C27.4054 35.6674 24.4686 37.7244 23.1826 40.6674H38.4625C37.1766 37.7244 34.2398 35.6674 30.8226 35.6674Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1165_3'
            d='M27.8226 35.6673C26.9639 35.6673 23.7478 37.7243 22.4619 40.6673H35.4625C34.1766 37.7243 31.2398 35.6673 27.8226 35.6673Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1163_3'
            d='M26.6553 28.1666C26.6553 29.2717 27.0943 30.3315 27.8757 31.1129C28.6571 31.8943 29.7169 32.3333 30.8219 32.3333C31.927 32.3333 32.9868 31.8943 33.7682 31.1129C34.5496 30.3315 34.9886 29.2717 34.9886 28.1666C34.9886 27.0616 34.5496 26.0018 33.7682 25.2204C32.9868 24.439 31.927 24 30.8219 24C29.7169 24 28.6571 24.439 27.8757 25.2204C27.0943 26.0018 26.6553 27.0616 26.6553 28.1666Z'
            fill='#60C3D4'
          />
          <path
            id='Ellipse 1164_3'
            d='M26.4619 28.1666C26.4619 29.2717 26.9009 30.3315 27.6823 31.1129C28.4637 31.8943 27.7176 32.3333 28.8226 32.3333C29.9277 32.3333 30.9875 31.8943 31.7689 31.1129C32.5503 30.3315 32.9893 29.2717 32.9893 28.1666C32.9893 27.0616 32.5503 26.0018 31.7689 25.2204C30.9875 24.439 29.9277 24 28.8226 24C27.7176 24 28.4637 24.439 27.6823 25.2204C26.9009 26.0018 26.4619 27.0616 26.4619 28.1666Z'
            fill='#C1ECF2'
          />
          <path
            id='Ellipse 1161_3'
            d='M26.6553 28.1666C26.6553 29.2717 27.0943 30.3315 27.8757 31.1129C28.6571 31.8943 29.7169 32.3333 30.8219 32.3333C31.927 32.3333 32.9868 31.8943 33.7682 31.1129C34.5496 30.3315 34.9886 29.2717 34.9886 28.1666C34.9886 27.0616 34.5496 26.0018 33.7682 25.2204C32.9868 24.439 31.927 24 30.8219 24C29.7169 24 28.6571 24.439 27.8757 25.2204C27.0943 26.0018 26.6553 27.0616 26.6553 28.1666Z'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1162_3'
            d='M23.1826 40.6674C24.4686 37.7244 27.4054 35.6674 30.8226 35.6674C34.2398 35.6674 37.1766 37.7244 38.4625 40.6674'
            stroke='#16606E'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ColorfulOrganization
