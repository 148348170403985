import type { SVGProps } from 'react'

const SvgNotificationOffline = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' role='img' {...props}>
      <title>Offline</title>
      <path
        d='M6.00001 9.65889C8.36692 9.65889 10.2857 7.74011 10.2857 5.37318C10.2857 3.00625 8.36692 1.08746 6.00001 1.08746C3.63308 1.08746 1.71429 3.00625 1.71429 5.37318C1.71429 7.74011 3.63308 9.65889 6.00001 9.65889Z'
        fill='#BA1A1A'
        stroke='#FF9999'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgNotificationOffline
