import type { SVGProps } from 'react'
const SvgComputer = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.286 3.429H1.714a.857.857 0 0 0-.857.857V18c0 .473.384.857.857.857h20.572a.857.857 0 0 0 .857-.857V4.286a.857.857 0 0 0-.857-.857ZM10.286 18.857 8.57 23.143M13.714 18.857l1.715 4.286M6.857 23.143h10.286'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgComputer
