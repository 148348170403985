import type { SVGProps } from 'react'

const Templates = (props: SVGProps<SVGSVGElement>) => (
  <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.96634 1.42871H2.03366C1.63537 1.42871 1.3125 1.81247 1.3125 2.28585V11.7144C1.3125 12.1878 1.63537 12.5716 2.03366 12.5716H9.96634C10.3647 12.5716 10.6875 12.1878 10.6875 11.7144V2.28585C10.6875 1.81247 10.3647 1.42871 9.96634 1.42871Z'
      stroke='currentcolor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.3125 7H10.6875' stroke='currentcolor' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.29462 3.36914H5.99997' stroke='currentcolor' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.29462 5.0835H8.70529' stroke='currentcolor' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M3.27478 12.5715L7.20915 8.90108C7.27699 8.84479 7.3625 8.81396 7.4508 8.81396C7.5391 8.81396 7.62461 8.84479 7.69245 8.90108L10.6876 11.0679'
      stroke='currentcolor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.65709 9.89128C4.07824 9.89128 4.41965 9.54992 4.41965 9.12877C4.41965 8.70762 4.07824 8.36621 3.65709 8.36621C3.23594 8.36621 2.89453 8.70762 2.89453 9.12877C2.89453 9.54992 3.23594 9.89128 3.65709 9.89128Z'
      stroke='currentcolor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Templates
