import type { SVGProps } from 'react'
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m6 14.422 2.52 3.22a.921.921 0 0 0 1.117.274.924.924 0 0 0 .323-.246L18 8'
      stroke='currentColor'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.143 1.714c6.154 0 11.143 4.99 11.143 11.143 0 6.154-4.99 11.143-11.143 11.143C5.989 24 1 19.011 1 12.857 1 6.703 5.989 1.714 12.143 1.714Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgCheckCircle
