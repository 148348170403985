import type { SVGProps } from 'react'
const AddOutreach = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none' {...props}>
    <path
      d='M10.4264 14.9674C10.2357 15.0143 10.0361 15.0105 9.84723 14.9566C9.65839 14.9027 9.4869 14.8005 9.34975 14.66L7.11432 12.4364L4.71483 13.6763L4.80711 10.1206L1.99745 7.3129C1.86524 7.18093 1.76792 7.0182 1.71423 6.83934C1.66053 6.66049 1.65214 6.4711 1.68982 6.2882C1.72632 6.0871 1.8159 5.89939 1.9493 5.74446C2.0827 5.58954 2.25509 5.47302 2.44863 5.40696L13.4515 1.73854C13.6576 1.66198 13.8814 1.64618 14.0963 1.69303C14.3112 1.73987 14.5081 1.84739 14.6636 2.0028C14.8191 2.15821 14.9267 2.35497 14.9736 2.5697C15.0205 2.78443 15.0047 3.00809 14.9281 3.21411L14.0103 5.96286L13.7809 6.65005'
      stroke='currentcolor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14.0909 2.57574L5.30304 9.84848' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.5 9.25L13.5 15.25' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.5 12.25L10.5 12.25' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default AddOutreach
