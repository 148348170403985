import type { SVGProps } from 'react'
const BatchHeavy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none' {...props}>
    <path
      d='M8.96575 8.31159C8.79623 8.3852 8.61341 8.42318 8.42861 8.42318C8.24379 8.42318 8.06097 8.3852 7.89146 8.31159L1.37717 5.29445C1.27139 5.2411 1.1825 5.15943 1.12039 5.05854C1.05828 4.95765 1.02539 4.8415 1.02539 4.72302C1.02539 4.60455 1.05828 4.4884 1.12039 4.38751C1.1825 4.28662 1.27139 4.20495 1.37717 4.15159L7.89146 1.11159C8.06097 1.03798 8.24379 1 8.42861 1C8.61341 1 8.79623 1.03798 8.96575 1.11159L15.48 4.12873C15.5858 4.18209 15.6747 4.26376 15.7368 4.36465C15.799 4.46554 15.8318 4.58169 15.8318 4.70016C15.8318 4.81864 15.799 4.93479 15.7368 5.03568C15.6747 5.13657 15.5858 5.21824 15.48 5.27159L8.96575 8.31159Z'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8571 8.09863L8.88571 11.3101C8.73682 11.3779 8.57507 11.4131 8.41143 11.4131C8.24778 11.4131 8.08603 11.3779 7.93714 11.3101L1 8.09863'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8571 11.0859L8.88571 14.2974C8.73682 14.3653 8.57507 14.4005 8.41143 14.4005C8.24778 14.4005 8.08603 14.3653 7.93714 14.2974L1 11.0859'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default BatchHeavy
