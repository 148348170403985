import type { SVGProps } from 'react'
const Batch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M9.53704 8.88581C9.36752 8.95942 9.1847 8.99739 8.9999 8.99739C8.81508 8.99739 8.63226 8.95942 8.46275 8.88581L1.94846 5.86866C1.84268 5.81531 1.75378 5.73365 1.69168 5.63275C1.62957 5.53186 1.59668 5.41572 1.59668 5.29724C1.59668 5.17877 1.62957 5.06262 1.69168 4.96173C1.75378 4.86084 1.84268 4.77917 1.94846 4.72581L8.46275 1.68581C8.63226 1.6122 8.81508 1.57422 8.9999 1.57422C9.1847 1.57422 9.36752 1.6122 9.53704 1.68581L16.0513 4.70295C16.1571 4.75631 16.246 4.83798 16.3081 4.93887C16.3703 5.03976 16.4031 5.15591 16.4031 5.27438C16.4031 5.39286 16.3703 5.50901 16.3081 5.6099C16.246 5.71079 16.1571 5.79246 16.0513 5.84581L9.53704 8.88581Z'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.4284 9.40039L9.457 12.6118C9.30811 12.6797 9.14636 12.7149 8.98272 12.7149C8.81907 12.7149 8.65732 12.6797 8.50843 12.6118L1.57129 9.40039'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.4284 13.1143L9.457 16.3257C9.30811 16.3936 9.14636 16.4288 8.98272 16.4288C8.81907 16.4288 8.65732 16.3936 8.50843 16.3257L1.57129 13.1143'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default Batch
