import type { SVGProps } from 'react'
const ColorfulMailbox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='40' viewBox='0 0 41 40' fill='none' {...props}>
    <mask id='mask0_2891_199593' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='4' y='9' width='33' height='18'>
      <path
        d='M13.57 9.57129H36.4271V26.7141H4.99854V18.1427C4.99854 15.8694 5.90159 13.6893 7.50905 12.0818C9.11651 10.4743 11.2967 9.57129 13.57 9.57129Z'
        fill='#60C3D4'
      />
    </mask>
    <g mask='url(#mask0_2891_199593)'>
      <path
        d='M13.57 9.57129H36.4271V26.7141H4.99854V18.1427C4.99854 15.8694 5.90159 13.6893 7.50905 12.0818C9.11651 10.4743 11.2967 9.57129 13.57 9.57129Z'
        fill='#60C3D4'
      />
      <path
        d='M11.57 7.57129H34.4271V24.7141H2.99854V16.1427C2.99854 13.8694 3.90159 11.6893 5.50905 10.0818C7.11651 8.47435 9.29668 7.57129 11.57 7.57129Z'
        fill='#C1ECF2'
      />
    </g>
    <path
      d='M13.57 9.57178H12.3797L15.7131 16.2384V26.7146H4.99854V18.1432C4.99854 15.8699 5.90159 13.6897 7.50905 12.0823C9.11651 10.4748 11.2967 9.57178 13.57 9.57178Z'
      fill='#EDFAFA'
    />
    <path
      d='M13.57 9.57129H34.4271C35.5317 9.57129 36.4271 10.4667 36.4271 11.5713V26.7141H4.99854V18.1427C4.99854 15.8694 5.90159 13.6893 7.50905 12.0818C9.11651 10.4743 11.2967 9.57129 13.57 9.57129Z'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.9517 26.7144V18.143C15.9517 15.8697 15.0486 13.6895 13.4411 12.0821C12.6512 11.2921 11.5465 10.4048 10.7132 10.2461'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M20.7129 26.7144V38.1429' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.8559 18.1429V1H22.1416' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default ColorfulMailbox
