import type { SVGProps } from 'react'
const AddTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none' {...props}>
    <path
      d='M16.7142 10.6199C16.8644 10.367 16.904 10.0721 16.8241 9.80025L16.2703 7.91407C15.7922 7.93202 15.3273 7.80163 14.9471 7.543C14.567 7.28437 14.2929 6.91185 14.1669 6.48287C14.041 6.0539 14.0702 5.59229 14.2501 5.16921C14.4301 4.74613 14.7507 4.38507 15.1626 4.1417L14.6088 2.25552C14.529 1.98364 14.3363 1.757 14.0732 1.62545C13.81 1.49389 13.498 1.4682 13.2057 1.55403L1.97705 4.85104C1.68474 4.93686 1.43612 5.12718 1.28588 5.38011C1.13563 5.63305 1.09607 5.92788 1.1759 6.19975L1.72853 8.08184C2.20964 8.0601 2.67862 8.18864 3.06231 8.44741C3.446 8.70618 3.72282 9.08061 3.84958 9.5123C3.97633 9.94398 3.94589 10.4086 3.76301 10.8338C3.58012 11.2589 3.25508 11.6206 2.8386 11.8624L3.39122 13.7445C3.47105 14.0164 3.66373 14.243 3.92687 14.3746C4.19001 14.5061 4.50206 14.5318 4.79436 14.446L7.00003 13.7983'
      stroke='currentcolor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9.51041 2.63907L11 7.5' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13 10L13 16' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 13L10 13' stroke='currentcolor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default AddTicket
