import type { SVGProps } from 'react'
const Ticket = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.0354 12.2066C1.0354 12.5097 1.15581 12.8004 1.37014 13.0148C1.58446 13.2291 1.87515 13.3495 2.17826 13.3495H13.8217C14.1248 13.3495 14.4155 13.2291 14.6298 13.0148C14.8441 12.8004 14.9645 12.5097 14.9645 12.2066V10.1038C14.5031 9.97812 14.0958 9.70417 13.8054 9.32415C13.5151 8.94414 13.3577 8.47917 13.3577 8.00092C13.3577 7.52266 13.5151 7.05769 13.8054 6.67768C14.0958 6.29767 14.5031 6.02371 14.9645 5.89806V3.7952C14.9645 3.4921 14.8441 3.20141 14.6298 2.98708C14.4155 2.77275 14.1248 2.65234 13.8217 2.65234H2.17826C1.87515 2.65234 1.58446 2.77275 1.37014 2.98708C1.15581 3.20141 1.0354 3.4921 1.0354 3.7952V5.89349C1.50078 6.01617 1.91245 6.28944 2.20621 6.67067C2.49996 7.0519 2.65926 7.51964 2.65926 8.00092C2.65926 8.48219 2.49996 8.94993 2.20621 9.33116C1.91245 9.71239 1.50078 9.98566 1.0354 10.1083V12.2066Z'
      stroke='currentcolor'
      strokeWidth='1.14286'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.4114 2.66211V4.5364' stroke='currentcolor' strokeWidth='1.14286' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.4114 7.06445V8.93874' stroke='currentcolor' strokeWidth='1.14286' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.4114 11.4746V13.3489' stroke='currentcolor' strokeWidth='1.14286' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default Ticket
