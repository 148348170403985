import type { SVGProps } from 'react'
const ColorfulStopwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2560_15406)'>
      <path
        d='M11.5249 23.5029C17.0478 23.5029 21.5249 19.0258 21.5249 13.5029C21.5249 7.98008 17.0478 3.50293 11.5249 3.50293C6.00205 3.50293 1.5249 7.98008 1.5249 13.5029C1.5249 19.0258 6.00205 23.5029 11.5249 23.5029Z'
        fill='#97DCF7'
      />
      <path
        d='M4.4542 20.574C2.57885 18.6981 1.5255 16.1541 1.52588 13.5016C1.52606 12.1882 1.78494 10.8877 2.28773 9.67438C2.79051 8.46104 3.52736 7.3586 4.4562 6.43003C5.38504 5.50145 6.48768 4.76491 7.70117 4.26247C8.91465 3.76003 10.2152 3.50152 11.5286 3.50171C14.1811 3.50208 16.7249 4.55615 18.6002 6.43203L4.4542 20.574Z'
        fill='#DCF3FC'
      />
      <path
        d='M11.5249 23.5029C17.0478 23.5029 21.5249 19.0258 21.5249 13.5029C21.5249 7.98008 17.0478 3.50293 11.5249 3.50293C6.00205 3.50293 1.5249 7.98008 1.5249 13.5029C1.5249 19.0258 6.00205 23.5029 11.5249 23.5029Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.5249 0.50293H12.5249' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5249 0.50293V3.50293' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.5249 3.50293L21.0249 5.00293' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.2748 4.25293L18.3418 6.18693' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5249 13.5029H6.5249' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5249 8.50293L8.0249 10.0029' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5249 18.5029L8.0249 17.0029' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.5249 13.5029H16.5249' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5249 20.5029V18.5029' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5249 6.50293V8.50293' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5249 18.5029L15.0249 17.0029' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5249 8.50293L15.0249 10.0029' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_2560_15406'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulStopwatch
