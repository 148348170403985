import type { SVGProps } from 'react'
const LinkChainBroken = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <g clipPath='url(#clip0_8955_45488)'>
      <path d='M12.0015 16.0231V7.97716' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.97196 14.9458L6.97196 17.963C6.97196 20.7403 9.22342 22.9917 12.0007 22.9917C14.7779 22.9917 17.0294 20.7403 17.0294 17.963L17.0294 14.9458'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0294 9.05456V6.03734C17.0294 3.26008 14.778 1.00866 12.0007 1.00864C9.22334 1.00873 6.97196 3.26012 6.97195 6.03739L6.97196 9.0546'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.774414 12H1.79491' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.81543 8.9386L4.34617 10.4693' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.34617 13.5307L2.81543 15.0614' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M23.2256 12H22.2051' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.1846 8.93861L20.1641 9.95911' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.1846 15.0614L20.1641 14.0409' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_8955_45488'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default LinkChainBroken
