import type { SVGProps } from 'react'
const SvgAlarmClock = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8 14A5.077 5.077 0 1 0 8 3.845a5.077 5.077 0 0 0 0 10.153ZM2 3.846A8.022 8.022 0 0 1 4.308 2M14 3.846A8.023 8.023 0 0 0 11.692 2'
      stroke='currentColor'
      strokeWidth={1.143}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8 6.153v2.77h2.308' stroke='currentColor' strokeWidth={1.143} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgAlarmClock
