import type { SVGProps } from 'react'

const AgencyLight = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Map Travel Places Government Building 2 by Streamlinehq' clipPath='url(#clip0_370_57327)'>
      <g id='Group'>
        <path
          id='Vector'
          d='M3.34814 4.84696C3.34814 4.21577 3.85981 3.7041 4.491 3.7041H11.6757C12.3069 3.7041 12.8185 4.21577 12.8185 4.84696V6.38079H3.34814V4.84696Z'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M7.99967 3.70419L7.99951 1.0144H9.42853'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M0.97998 14.8672H15.1875'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M1.99463 7.52372C1.99463 6.89254 2.50631 6.38086 3.13749 6.38086H13.0297C13.6609 6.38086 14.1725 6.89254 14.1725 7.52372V14.8673H1.99463V7.52372Z'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinejoin='round'
        />
        <path
          id='Vector_5'
          d='M9.2085 9.93903C9.2085 9.19686 9.81014 8.59521 10.5523 8.59521C11.2945 8.59521 11.8962 9.19686 11.8962 9.93903V14.8674H9.2085V9.93903Z'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_6'
          d='M4.27197 9.93903C4.27197 9.19686 4.87363 8.59521 5.6158 8.59521C6.35797 8.59521 6.95962 9.19686 6.95962 9.93903V14.8674H4.27197V9.93903Z'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_370_57327'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default AgencyLight
