import type { SVGProps } from 'react'

const ThreeRingBinder = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Contact-Book--Streamline-Core.svg'>
      <g id='contact-book--contact-notebook-note'>
        <path
          id='Vector'
          d='M11.715 2.16211H3.22354C2.63733 2.16211 2.16211 2.63733 2.16211 3.22354V12.7764C2.16211 13.3626 2.63733 13.8378 3.22354 13.8378H11.715C12.3012 13.8378 12.7764 13.3626 12.7764 12.7764V3.22354C12.7764 2.63733 12.3012 2.16211 11.715 2.16211Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M1.10156 4.81567H3.22442'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path id='Vector_3' d='M1.10156 8H3.22442' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          id='Vector_4'
          d='M1.10156 11.1842H3.22442'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_5'
          d='M5.87891 5.34644H9.06319'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_6'
          d='M14.9004 3.75427V5.34642'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_7'
          d='M14.9004 7.46924V9.06138'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)
export default ThreeRingBinder
