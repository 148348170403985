import type { SVGProps } from 'react'
const ColorfulGenericThumbs = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.41414 3.95122C2.25341 3.95122 2.09925 3.87985 1.98559 3.75281C1.87193 3.62577 1.80808 3.45347 1.80808 3.27381C1.80808 3.09415 1.87193 2.92184 1.98559 2.7948C2.09925 2.66776 2.25341 2.5964 2.41414 2.5964H2.81818C2.65745 2.5964 2.50329 2.52503 2.38963 2.39799C2.27597 2.27095 2.21212 2.09864 2.21212 1.91898C2.21212 1.5446 2.48364 1.46738 2.81818 1.46738H5.44445C6.59637 1.46738 7.58586 1.69318 9.00001 1.91898V5.75765C8.01011 5.75765 5.64647 7.78988 5.64647 9.59631C5.64647 10.3103 4.76202 10.5763 4.43435 9.2716C4.23233 8.46729 4.83839 6.88666 4.83839 6.88666H1.60606C1.44532 6.88666 1.29117 6.81529 1.17751 6.68826C1.06385 6.56122 1 6.38891 1 6.20925C1 5.83487 1.27152 5.30604 1.60606 5.30604H2.0101C1.84936 5.30604 1.69521 5.23467 1.58155 5.10763C1.46789 4.98059 1.40404 4.80829 1.40404 4.62863C1.40404 4.44897 1.46789 4.27667 1.58155 4.14963C1.69521 4.02259 1.84936 3.95122 2.0101 3.95122H2.41414Z'
      fill='#DDBAA2'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 1.46738H11.1818V6.55623H9V1.46738Z' fill='#DDBAA2' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.0907 2.92161C10.1626 2.92161 10.2329 2.90028 10.2927 2.86033C10.3525 2.82037 10.3991 2.76358 10.4266 2.69713C10.4542 2.63068 10.4614 2.55757 10.4473 2.48703C10.4333 2.41649 10.3987 2.3517 10.3478 2.30084C10.297 2.24999 10.2322 2.21535 10.1616 2.20132C10.0911 2.18729 10.018 2.19449 9.95153 2.22202C9.88508 2.24954 9.82829 2.29615 9.78834 2.35595C9.74838 2.41575 9.72705 2.48605 9.72705 2.55797C9.72705 2.65442 9.76536 2.74691 9.83356 2.8151C9.90175 2.8833 9.99424 2.92161 10.0907 2.92161Z'
      fill='black'
    />
    <path
      d='M17.107 14.127C17.1946 14.127 17.2813 14.1445 17.3622 14.1785C17.4431 14.2126 17.5165 14.2625 17.5785 14.3254C17.6404 14.3883 17.6895 14.463 17.723 14.5451C17.7565 14.6273 17.7737 14.7154 17.7737 14.8044C17.7737 14.8933 17.7565 14.9814 17.723 15.0636C17.6895 15.1458 17.6404 15.2205 17.5785 15.2834C17.5165 15.3463 17.4431 15.3962 17.3622 15.4302C17.2813 15.4643 17.1946 15.4818 17.107 15.4818H16.6626C16.8394 15.4818 17.009 15.5532 17.134 15.6802C17.259 15.8072 17.3293 15.9795 17.3293 16.1592C17.3293 16.5336 17.0306 16.6108 16.6626 16.6108H13.7737C12.5066 16.6108 12.2182 16.385 10.6626 16.1592V12.3205C11.7515 12.3205 13.5515 10.2883 13.5515 8.48187C13.5515 7.76788 14.5244 7.50188 14.8848 8.80657C15.107 9.61089 14.4404 11.1915 14.4404 11.1915H17.9959C18.1727 11.1915 18.3423 11.2629 18.4673 11.3899C18.5924 11.517 18.6626 11.6893 18.6626 11.8689C18.6626 12.2433 18.3639 12.7721 17.9959 12.7721H17.5515C17.7283 12.7721 17.8979 12.8435 18.0229 12.9705C18.1479 13.0976 18.2182 13.2699 18.2182 13.4495C18.2182 13.6292 18.1479 13.8015 18.0229 13.9286C17.8979 14.0556 17.7283 14.127 17.5515 14.127H17.107Z'
      fill='#FDF1E7'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 11.5218H11.1818V16.6106H9V11.5218Z' fill='#FDF1E7' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.0912 12.9241C10.1631 12.9241 10.2334 12.9028 10.2932 12.8629C10.353 12.8229 10.3996 12.7661 10.4271 12.6997C10.4547 12.6332 10.4619 12.5601 10.4478 12.4896C10.4338 12.419 10.3992 12.3542 10.3483 12.3034C10.2975 12.2525 10.2327 12.2179 10.1621 12.2039C10.0916 12.1898 10.0185 12.197 9.95202 12.2245C9.88557 12.2521 9.82878 12.2987 9.78882 12.3585C9.74887 12.4183 9.72754 12.4886 9.72754 12.5605C9.72754 12.6569 9.76585 12.7494 9.83405 12.8176C9.90224 12.8858 9.99473 12.9241 10.0912 12.9241Z'
      fill='black'
    />
  </svg>
)

export default ColorfulGenericThumbs
