import type { SVGProps } from 'react'
const Agency = ({ fill = '#C1ECF2', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_156_35997)'>
      <path
        d='M3.34766 4.84696C3.34766 4.21577 3.85932 3.7041 4.49051 3.7041H11.6752C12.3064 3.7041 12.818 4.21577 12.818 4.84696V6.38079H3.34766V4.84696Z'
        fill={fill}
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path d='M8.00016 3.70419L8 1.0144H9.42902' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.980469 14.8672H15.188' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M1.99609 7.52372C1.99609 6.89254 2.50777 6.38086 3.13895 6.38086H13.0311C13.6623 6.38086 14.174 6.89254 14.174 7.52372V14.8673H1.99609V7.52372Z'
        fill={fill}
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M9.20898 9.93903C9.20898 9.19686 9.81063 8.59521 10.5528 8.59521C11.295 8.59521 11.8967 9.19686 11.8967 9.93903V14.8674H9.20898V9.93903Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.27344 9.93903C4.27344 9.19686 4.87509 8.59521 5.61727 8.59521C6.35944 8.59521 6.96108 9.19686 6.96108 9.93903V14.8674H4.27344V9.93903Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_156_35997'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default Agency
