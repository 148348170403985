import type { SVGProps } from 'react'
const Bell = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M5.14294 12.5723H6.85723' stroke='currentColor' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.80806 5.23574C9.80806 4.2258 9.40684 3.25722 8.69266 2.54307C7.97855 1.82893 7.00997 1.42773 6.00002 1.42773C4.99007 1.42773 4.02149 1.82893 3.30736 2.54307C2.59321 3.25722 2.19201 4.2258 2.19201 5.23574V9.01839C2.19201 9.39712 2.04156 9.76034 1.77375 10.0281C1.50596 10.296 1.23591 10.4464 0.857178 10.4464H11.1429C10.7641 10.4464 10.4941 10.296 10.2263 10.0281C9.95849 9.76034 9.80806 9.39712 9.80806 9.01839V5.23574Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default Bell
