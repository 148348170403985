import type { SVGProps } from 'react'
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#info_svg__a)' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M12 .857C18.154.857 23.143 5.846 23.143 12c0 6.154-4.989 11.143-11.143 11.143C5.846 23.143.857 18.154.857 12 .857 5.846 5.846.857 12 .857ZM12 17.5v-5.143' />
      <path d='M12 6.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z' />
    </g>
    <defs>
      <clipPath id='info_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgInfo
