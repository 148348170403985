import type { SVGProps } from 'react'
const SvgPoint = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g>
      <path d='M.648 4S-.14 19.831 11 20' stroke='currentColor' strokeWidth={1.25} strokeLinecap='round' />
      <path d='M-5.622 8.955S-1.155 20.165 11 20.049' stroke='currentColor' strokeWidth={1.25} />
      <path d='M-5.622 31.045S-1.155 19.835 11 19.951' stroke='currentColor' strokeWidth={1.25} />
      <path d='M.648 36S-.14 20.169 11 20' stroke='currentColor' strokeWidth={1.25} strokeLinecap='round' />
      <path d='M11 20.625a.625.625 0 1 0 0-1.25v1.25Zm-12 0h12v-1.25H-1v1.25Z' fill='currentColor' />
    </g>
  </svg>
)
export default SvgPoint
