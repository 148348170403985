import type { SVGProps } from 'react'
const SvgNotebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#Notebook_svg__a)' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M14.857 25.071H2.786a1.857 1.857 0 0 1-1.858-1.857V2.786A1.857 1.857 0 0 1 2.786.929h13.928M19.5 6.5 22.286.929 25.07 6.5v15.786a2.786 2.786 0 0 1-5.571 0V6.5ZM6.5.929V25.07M11.143 7.429h3.714' />
    </g>
    <defs>
      <clipPath id='Notebook_svg__a'>
        <path fill='currentColor' d='M0 0h26v26H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgNotebook
