import type { SVGProps } from 'react'
const SvgAutomations = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M24 18.056c0-6-4.92-9.273-11-9.273S2 12.056 2 18.056v6.728a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-6.728ZM13 8.783v-3M13 5.282A2.14 2.14 0 1 0 13 1a2.14 2.14 0 0 0 0 4.282ZM8.48 16.09v-1.307M17.55 16.09v-1.307' />
      <path d='M24 19.783h-1.901a2 2 0 0 0-1.666.893l-.6.904a2 2 0 0 1-1.666.893H7.832a2 2 0 0 1-1.665-.893l-.6-.904a2 2 0 0 0-1.666-.893H2' />
    </g>
  </svg>
)
export default SvgAutomations
