import type { SVGProps } from 'react'
const SvgInboxBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.714 15.929a3.715 3.715 0 1 0 0-7.43 3.715 3.715 0 0 0 0 7.43ZM15.343 14.843l-5.257-5.257M6.144 11.357H2.715a1.143 1.143 0 0 1-1.143-1.143v-8a1.143 1.143 0 0 1 1.143-1.143h11.429a1.143 1.143 0 0 1 1.143 1.143v4'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.572 2.512 8.43 7.357l6.858-4.845' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgInboxBlock
