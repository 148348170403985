import type { SVGProps } from 'react'
const SvgPaperclip = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#paperclip_svg__a)'>
      <path
        d='m14.695 7.757-6.72 6.776a2.8 2.8 0 0 1-3.998 0l-2.01-2.045a2.857 2.857 0 0 1 0-4.01l7.16-7.182a2.258 2.258 0 0 1 3.208 0l.8.802a2.258 2.258 0 0 1 0 3.207l-6.345 6.37a1.127 1.127 0 0 1-1.604 0l-.395-.406a1.128 1.128 0 0 1 0-1.604L9.048 5.44'
        stroke='currentColor'
        strokeWidth={1.143}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='paperclip_svg__a'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPaperclip
