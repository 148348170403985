import type { SVGProps } from 'react'
const SvgRule = (props: SVGProps<SVGSVGElement>) => (
  <svg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.73162 1.66699L1.82686 7.73556C1.45064 8.30387 1.78399 8.52414 2.35257 8.52414H5.54115L2.58876 16.5241L11.7392 7.22128C11.8208 7.1427 11.8771 7.04166 11.9012 6.931C11.9252 6.82033 11.9158 6.70502 11.8741 6.5997C11.8325 6.49439 11.7605 6.40382 11.6673 6.3395C11.5741 6.27517 11.4639 6.23999 11.3507 6.23842H7.73162L11.3507 1.66699H5.73162Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgRule
