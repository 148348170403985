import type { SVGProps } from 'react'
const SvgGeometricShapes = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <g clipPath='url(#clip0_3427_142927)'>
      <mask id='path-1-inside-1_3427_142927' fill='white'>
        <path d='M5.88086 11.4043C2.84329 11.4043 0.380859 8.94187 0.380859 5.9043C0.380859 2.86673 2.84329 0.404297 5.88086 0.404297C8.91842 0.404297 11.3809 2.86673 11.3809 5.9043' />
      </mask>
      <path
        d='M5.88086 9.9043C3.67172 9.9043 1.88086 8.11344 1.88086 5.9043H-1.11914C-1.11914 9.7703 2.01487 12.9043 5.88086 12.9043V9.9043ZM1.88086 5.9043C1.88086 3.69515 3.67172 1.9043 5.88086 1.9043V-1.0957C2.01487 -1.0957 -1.11914 2.0383 -1.11914 5.9043H1.88086ZM5.88086 1.9043C8.08999 1.9043 9.88086 3.69515 9.88086 5.9043H12.8809C12.8809 2.0383 9.74685 -1.0957 5.88086 -1.0957V1.9043Z'
        fill='black'
        mask='url(#path-1-inside-1_3427_142927)'
      />
      <path
        d='M7.25595 5.6543C6.35845 5.6543 5.63086 6.38186 5.63086 7.27938V13.5292C5.63086 14.4267 6.35843 15.1543 7.25595 15.1543H13.5058C14.4034 15.1543 15.1309 14.4266 15.1309 13.5292V7.27938C15.1309 6.3819 14.4034 5.6543 13.5058 5.6543H7.25595Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3427_142927'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgGeometricShapes
