import type { SVGProps } from 'react'
const SvgMailReply = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M12.43 10.785 10.714 12.5l1.714 1.715' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.715 12.5h3.428a2.285 2.285 0 0 1 2.286 2.286v1.143M7.858 11.357H2.715a1.143 1.143 0 0 1-1.143-1.143v-8a1.143 1.143 0 0 1 1.143-1.143h11.429a1.143 1.143 0 0 1 1.143 1.143v6.857'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.572 2.512 8.43 7.357l6.858-4.845' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgMailReply
