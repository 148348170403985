import type { SVGProps } from 'react'
const SvgNotebookHeavy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <g clipPath='url(#clip0_5018_169643)'>
      <path
        d='M9.07692 15H2.07692C1.79131 15 1.51739 14.8865 1.31542 14.6846C1.11346 14.4826 1 14.2087 1 13.9231V2.07692C1 1.79131 1.11346 1.51739 1.31542 1.31542C1.51739 1.11346 1.79131 1 2.07692 1H10.1538'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7695 4.23077L13.3849 1L15.0003 4.23077V13.3846C15.0003 13.813 14.8301 14.2239 14.5272 14.5269C14.2242 14.8298 13.8133 15 13.3849 15C12.9565 15 12.5456 14.8298 12.2427 14.5269C11.9397 14.2239 11.7695 13.813 11.7695 13.3846V4.23077Z'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4.23047 1V15' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.92383 4.76953H9.07767' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_5018_169643'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgNotebookHeavy
