import type { SVGProps } from 'react'
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M17.286 9h6.857v6.857' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M24.286 9 13.6 20.686a.857.857 0 0 1-1.2 0l-2.943-2.943a.857.857 0 0 0-1.2 0L2 24'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.605 1.85a.857.857 0 1 0-1.715 0v1.044h-1.04a.857.857 0 1 0 0 1.714h1.04V5.65a.857.857 0 0 0 1.715 0V4.61h1.044a.857.857 0 1 0 0-1.715h-1.044V1.851Z'
      fill='currentColor'
    />
    <path
      d='M4.99 5.8v3.546m0 0-2.387 2.388m2.388-2.388 2.388 2.388M4.99 9.346 1.807 7.755m3.184 1.591 3.184-1.591'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgAnalytics
