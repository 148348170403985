import type { SVGProps } from 'react'
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#trash_svg__a)' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M1.714 6h20.572M4.286 6h15.428v15.429A1.714 1.714 0 0 1 18 23.143H6a1.714 1.714 0 0 1-1.714-1.714V6ZM7.714 6v-.857a4.286 4.286 0 1 1 8.572 0V6M9.429 11.145v6.86M14.571 11.145v6.86' />
    </g>
    <defs>
      <clipPath id='trash_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTrash
