import type { SVGProps } from 'react'

const CustomerSupport: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Customer Support 3'>
      <g id='customer-support-3--customer-headset-help-microphone-phone-support'>
        <path
          id='Vector'
          d='M5.14258 11.9999V7.49133C5.15597 6.60509 5.3441 5.73021 5.69619 4.91682C6.04828 4.10342 6.55741 3.36748 7.1944 2.75119C7.8314 2.13489 8.58375 1.65035 9.40833 1.32532C10.2329 1.00028 11.1135 0.841151 11.9997 0.85704C12.8859 0.841151 13.7665 1.00028 14.5911 1.32532C15.4157 1.65035 16.168 2.13489 16.805 2.75119C17.442 3.36748 17.9512 4.10342 18.3033 4.91682C18.6553 5.73021 18.8435 6.60509 18.8569 7.49133V11.9999'
          stroke='#C12E00'
          strokeWidth='1.71429'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M2.57122 9.42871H4.2855C4.51283 9.42871 4.73085 9.51902 4.8916 9.67976C5.05234 9.84051 5.14265 10.0585 5.14265 10.2859V15.4287C5.14265 15.656 5.05234 15.8741 4.8916 16.0348C4.73085 16.1955 4.51283 16.2859 4.2855 16.2859H2.57122C2.11656 16.2859 1.68053 16.1052 1.35904 15.7838C1.03755 15.4623 0.856934 15.0262 0.856934 14.5716V11.143C0.856934 10.6883 1.03755 10.2523 1.35904 9.93081C1.68053 9.60932 2.11656 9.42871 2.57122 9.42871Z'
          stroke='#C12E00'
          strokeWidth='1.71429'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M21.4284 16.2859H19.7141C19.4867 16.2859 19.2687 16.1955 19.108 16.0348C18.9472 15.8741 18.8569 15.656 18.8569 15.4287V10.2859C18.8569 10.0585 18.9472 9.84051 19.108 9.67976C19.2687 9.51902 19.4867 9.42871 19.7141 9.42871H21.4284C21.883 9.42871 22.3191 9.60932 22.6405 9.93081C22.962 10.2523 23.1426 10.6883 23.1426 11.143V14.5716C23.1426 15.0262 22.962 15.4623 22.6405 15.7838C22.3191 16.1052 21.883 16.2859 21.4284 16.2859Z'
          stroke='#C12E00'
          strokeWidth='1.71429'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M18.8569 13.7144V17.5715C18.8569 18.4808 18.4957 19.3529 17.8527 19.9959C17.2097 20.6388 16.3377 21.0001 15.4284 21.0001H12.8569'
          stroke='#C12E00'
          strokeWidth='1.71429'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default CustomerSupport
