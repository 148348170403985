import type { SVGProps } from 'react'

const AddGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Group-Meeting-Call--Streamline-Core.svg'>
      <g id='Group-Meeting-Call--Streamline-Core.svg_2'>
        <g id='Group 2661'>
          <path
            id='Ellipse 1149'
            d='M2.55615 13.381C2.55615 13.823 2.73175 14.2469 3.04431 14.5595C3.35687 14.872 3.78079 15.0476 4.22282 15.0476C4.66485 15.0476 5.08877 14.872 5.40133 14.5595C5.7139 14.2469 5.88949 13.823 5.88949 13.381C5.88949 12.9389 5.7139 12.515 5.40133 12.2025C5.08877 11.8899 4.66485 11.7143 4.22282 11.7143C3.78079 11.7143 3.35687 11.8899 3.04431 12.2025C2.73175 12.515 2.55615 12.9389 2.55615 13.381Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1150'
            d='M1.16675 18.8813C1.68112 17.7041 2.85584 16.8813 4.22273 16.8813C5.58961 16.8813 6.76433 17.7041 7.2787 18.8813'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Group 2659'>
          <path
            id='Ellipse 1161'
            d='M7.77832 3.38096C7.77832 3.82299 7.95392 4.24692 8.26648 4.55948C8.57904 4.87204 9.00296 5.04763 9.44499 5.04763C9.88702 5.04763 10.3109 4.87204 10.6235 4.55948C10.9361 4.24692 11.1117 3.82299 11.1117 3.38096C11.1117 2.93893 10.9361 2.51501 10.6235 2.20245C10.3109 1.88989 9.88702 1.71429 9.44499 1.71429C9.00296 1.71429 8.57904 1.88989 8.26648 2.20245C7.95392 2.51501 7.77832 2.93893 7.77832 3.38096Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1162'
            d='M6.38892 8.88129C6.90329 7.70409 8.07801 6.88129 9.44489 6.88129C10.8118 6.88129 11.9865 7.70409 12.5009 8.88129'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          id='Vector_4'
          d='M14.7864 17.9285V11.6428'
          stroke='currentColor'
          strokeWidth='1.71'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_5'
          d='M11.6438 14.7858H17.9295'
          stroke='currentColor'
          strokeWidth='1.71'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default AddGroup
