import type { SVGProps } from 'react'

const NotificationBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.997 2.833a9.162 9.162 0 0 0-9.15 9.603 9.169 9.169 0 0 0 1.523 4.64l-1.537 4.09 5.146-.93a9.161 9.161 0 0 0 12.953-6.173 9.169 9.169 0 0 0-8.935-11.23v0Z'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.857 9.857A2.143 2.143 0 1 1 12 12v1.429'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 15.571a1.072 1.072 0 1 0 1.071 1.072A1.086 1.086 0 0 0 12 15.57Z' fill='currentColor' />
    <path
      d='M20.2857 9.57143C22.6526 9.57143 24.5714 7.65265 24.5714 5.28571C24.5714 2.91878 22.6526 1 20.2857 1C17.9188 1 16 2.91878 16 5.28571C16 7.65265 17.9188 9.57143 20.2857 9.57143Z'
      fill='#D13C3C'
      stroke='currentcolor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default NotificationBadge
