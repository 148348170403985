import type { SVGProps } from 'react'
const ColorfulCivicEngagement = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M23 5H8V10H23V5Z' fill='#97DCF7' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23 10H1V15H23V10Z' fill='#D7F3F6' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23 15H1V20H23V15Z' fill='#97DCF7' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 5H1V10H8V5Z' fill='#97DCF7' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default ColorfulCivicEngagement
