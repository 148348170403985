import type { SVGProps } from 'react'
const SvgLace = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeWidth={1.25}>
      <path d='M11.375 19.856c0 .755-.384 1.539-1.217 2.32-.834.78-2.075 1.512-3.66 2.14C3.33 25.571-1.088 26.36-6 26.36c-4.912 0-9.33-.79-12.498-2.044-1.585-.628-2.826-1.36-3.66-2.14-.833-.781-1.217-1.565-1.217-2.32 0-.754.384-1.538 1.217-2.318.834-.781 2.075-1.513 3.66-2.141 3.168-1.255 7.586-2.045 12.498-2.045 4.912 0 9.33.79 12.498 2.045 1.585.628 2.826 1.36 3.66 2.14.833.78 1.217 1.565 1.217 2.32Z' />
      <path d='M7.187 27.81c0 .507-.292 1.078-1.023 1.682-.727.6-1.816 1.17-3.217 1.659-2.796.977-6.7 1.593-11.04 1.593-4.342 0-8.246-.616-11.042-1.593-1.401-.49-2.49-1.059-3.217-1.66-.73-.603-1.023-1.174-1.023-1.681 0-.507.292-1.078 1.023-1.682.727-.6 1.816-1.17 3.217-1.659 2.796-.977 6.7-1.593 11.041-1.593s8.245.616 11.041 1.593c1.401.49 2.49 1.058 3.217 1.659.73.604 1.023 1.175 1.023 1.682ZM7.187 12.038c0 .506-.292 1.078-1.023 1.682-.727.6-1.816 1.169-3.217 1.659-2.796.977-6.7 1.593-11.04 1.593-4.342 0-8.246-.616-11.042-1.594-1.401-.49-2.49-1.058-3.217-1.658-.73-.604-1.023-1.176-1.023-1.682 0-.507.292-1.079 1.023-1.682.727-.6 1.816-1.17 3.217-1.66 2.796-.976 6.7-1.592 11.041-1.592S.151 7.72 2.947 8.697c1.401.49 2.49 1.058 3.217 1.659.73.604 1.023 1.175 1.023 1.682Z' />
      <path d='M3.05 7.425c0 .407-.235.883-.861 1.4-.622.515-1.56 1.005-2.774 1.43-2.422.846-5.808 1.38-9.578 1.38-3.77 0-7.156-.534-9.578-1.38-1.214-.425-2.151-.915-2.774-1.43-.626-.517-.86-.993-.86-1.4 0-.407.234-.883.86-1.4.623-.514 1.56-1.005 2.774-1.43 2.422-.846 5.808-1.38 9.578-1.38 3.77 0 7.156.534 9.578 1.38 1.214.425 2.152.916 2.774 1.43.626.517.86.993.86 1.4ZM3.05 32.575c0 .407-.235.883-.861 1.4-.622.514-1.56 1.005-2.774 1.43-2.422.846-5.808 1.38-9.578 1.38-3.77 0-7.156-.534-9.578-1.38-1.214-.425-2.151-.916-2.774-1.43-.626-.517-.86-.993-.86-1.4 0-.407.234-.883.86-1.4.623-.514 1.56-1.005 2.774-1.43 2.422-.846 5.808-1.38 9.578-1.38 3.77 0 7.156.534 9.578 1.38 1.214.425 2.152.916 2.774 1.43.626.517.86.993.86 1.4Z' />
    </g>
  </svg>
)
export default SvgLace
