import type { SVGProps } from 'react'
const ColorfulPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M36 26L22 30L24 18V6C24 4.93912 24.4214 3.92172 25.1716 3.17157C25.9217 2.42143 26.9391 2 28 2H50C51.0608 2 52.0784 2.42143 52.8284 3.17157C53.5784 3.92172 54 4.93912 54 6V22C54 23.0609 53.5784 24.0783 52.8284 24.8284C52.0784 25.5786 51.0608 26 50 26H36Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 2H28C26.9391 2 25.9217 2.42143 25.1716 3.17157C24.4214 3.92172 24 4.93912 24 6V18L22 30L36 26H38V50C38 52.2092 36.2092 54 34 54H6C3.79086 54 2 52.2092 2 50V6C2 3.79086 3.79086 2 6 2Z'
      fill='#EDFAFA'
    />
    <path
      d='M6 53.9998H34C35.1947 53.9998 36.2671 53.4761 37 52.6457L3.17157 3.17139C2.44772 3.89524 2 4.89524 2 5.99981V49.9998C2 52.209 3.79086 53.9998 6 53.9998Z'
      fill='#C1ECF2'
    />
    <path
      d='M36 26L22 30L24 18V6C24 4.93912 24.4214 3.92172 25.1716 3.17157C25.9217 2.42143 26.9391 2 28 2H50C51.0608 2 52.0784 2.42143 52.8284 3.17157C53.5784 3.92172 54 4.93912 54 6V22C54 23.0609 53.5784 24.0783 52.8284 24.8284C52.0784 25.5786 51.0608 26 50 26H36Z'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 2H6C3.79086 2 2 3.79086 2 6V50C2 52.2092 3.79086 54 6 54H34C36.2091 54 38 52.2092 38 50V35'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M18 44H22' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <circle cx='31' cy='14' r='2' fill='#D7F3F6' stroke='#16606E' strokeWidth='1.5' />
    <circle cx='39' cy='14' r='2' fill='#D7F3F6' stroke='#16606E' strokeWidth='1.5' />
    <circle cx='47' cy='14' r='2' fill='#D7F3F6' stroke='#16606E' strokeWidth='1.5' />
  </svg>
)

export default ColorfulPhone
