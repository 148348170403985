import type { SVGProps } from 'react'
const SvgPolygon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m5.5 5.904 15-2.308M3.66 8.5l2.18 12m2.66 1.75 9-2m2.66-2.75 2.18-12M20.5 1H25v4.5h-4.5zM1 4h4.5v4.5H1zM4 20.5h4.5V25H4zM17.5 17.5H22V22h-4.5z'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgPolygon
