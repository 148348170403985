import type { SVGProps } from 'react'
const Archive = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.71436 5.71436H14.2858V13.7144C14.2858 14.0175 14.1654 14.3082 13.951 14.5225C13.7367 14.7368 13.446 14.8572 13.1429 14.8572H2.85721C2.55411 14.8572 2.26342 14.7368 2.04909 14.5225C1.83476 14.3082 1.71436 14.0175 1.71436 13.7144V5.71436Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.4284 4.57139V2.28568C15.4284 1.98257 15.308 1.69188 15.0937 1.47756C14.8794 1.26323 14.5887 1.14282 14.2856 1.14282H1.71415C1.41104 1.14282 1.12035 1.26323 0.906024 1.47756C0.691697 1.69188 0.571289 1.98257 0.571289 2.28568V4.57139C0.571289 4.8745 0.691697 5.16519 0.906024 5.37952C1.12035 5.59384 1.41104 5.71425 1.71415 5.71425H14.2856C14.5887 5.71425 14.8794 5.59384 15.0937 5.37952C15.308 5.16519 15.4284 4.8745 15.4284 4.57139Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.28564 9.14282H9.71422' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default Archive
