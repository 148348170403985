import type { SVGProps } from 'react'
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#add_svg__a)' stroke='currentColor' strokeWidth={1.8} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M20 12H4M12 4v16' />
    </g>
    <defs>
      <clipPath id='add_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgAdd
