import type { SVGProps } from 'react'
const SvgAudienceNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12 13a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM4.5 24c0-4.203 2.851-7.794 7.271-7.992 1.861-.083 3.36.392 4.683 1.493M21 18v6M18 21h6'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.746 15a8.824 8.824 0 0 0 1.713-4.445 8.886 8.886 0 0 0-.836-4.702 8.635 8.635 0 0 0-3.135-3.54A8.353 8.353 0 0 0 12 1c-1.586 0-3.14.454-4.488 1.312a8.634 8.634 0 0 0-3.135 3.54 8.886 8.886 0 0 0-.836 4.703A8.824 8.824 0 0 0 5.254 15'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgAudienceNew
