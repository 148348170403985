import type { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#edit_svg__a)'>
      <path
        d='m8.571 20.983-7.714 2.16 2.16-7.714L17.143 1.37a1.714 1.714 0 0 1 2.451 0l3.035 3.052a1.713 1.713 0 0 1 0 2.434L8.57 20.983Z'
        stroke='currentColor'
        strokeWidth={1.714}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='edit_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgEdit
