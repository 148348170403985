import type { SVGProps } from 'react'
const ColorfulSent = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2906_17696)'>
      <path
        d='M7.55794 10.0089L9.42787 11.8689C9.5426 11.9864 9.68605 12.0719 9.84401 12.117C10.002 12.1621 10.169 12.1652 10.3285 12.126C10.4891 12.0888 10.6373 12.0108 10.759 11.8997C10.8806 11.7886 10.9717 11.6482 11.0233 11.4917L14.0941 2.29444C14.1582 2.12211 14.1714 1.93501 14.1322 1.75539C14.093 1.57577 14.003 1.41118 13.8729 1.28118C13.7428 1.15118 13.5781 1.06125 13.3984 1.02206C13.2186 0.982875 13.0314 0.996089 12.8589 1.06013L3.6551 4.12876C3.4932 4.18402 3.349 4.28148 3.23741 4.41108C3.12582 4.54067 3.05089 4.69769 3.02035 4.86591C2.98884 5.01891 2.99585 5.17733 3.04077 5.32694C3.08569 5.47656 3.16709 5.61268 3.27768 5.72307L5.62796 8.07169L5.55076 11.046L7.55794 10.0089Z'
        fill='#C9B3EC'
        stroke='#6B3DC2'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13.3891 1.75928L6.03809 7.84291' stroke='#6B3DC2' strokeWidth='1.1' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.60098 9.69727L1.25098 11.0473M3.56621 12.6322L1.25098 14.9473M6.50098 13.5973L5.15098 14.9473'
        stroke='#6B3DC2'
        strokeWidth='1.1'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2906_17696'>
        <rect width='15.6' height='15.6' fill='white' transform='translate(0.200195 0.200195)' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulSent
