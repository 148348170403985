import type { SVGProps } from 'react'
const ColorfulPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='40' viewBox='0 0 41 40' fill='none' {...props}>
    <g clipPath='url(#clip0_2891_199565)'>
      <mask id='mask0_2891_199565' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='7' y='3' width='15' height='15'>
        <path
          d='M14.5237 17.2857C16.4181 17.2857 18.2349 16.5332 19.5745 15.1936C20.914 13.8541 21.6666 12.0373 21.6666 10.1429C21.6666 8.24845 20.914 6.43164 19.5745 5.09209C18.2349 3.75255 16.4181 3 14.5237 3C12.6293 3 10.8125 3.75255 9.47295 5.09209C8.13341 6.43164 7.38086 8.24845 7.38086 10.1429C7.38086 12.0373 8.13341 13.8541 9.47295 15.1936C10.8125 16.5332 12.6293 17.2857 14.5237 17.2857Z'
          fill='#60C3D4'
        />
      </mask>
      <g mask='url(#mask0_2891_199565)'>
        <path
          d='M14.3811 17.0001C16.2755 17.0001 18.0924 16.2475 19.4319 14.908C20.7714 13.5684 21.524 11.7516 21.524 9.85721C21.524 7.96281 20.7714 6.146 19.4319 4.80645C18.0924 3.4669 16.2755 2.71436 14.3811 2.71436C12.4867 2.71436 10.6699 3.4669 9.33038 4.80645C7.99083 6.146 7.23828 7.96281 7.23828 9.85721C7.23828 11.7516 7.99083 13.5684 9.33038 14.908C10.6699 16.2475 12.4867 17.0001 14.3811 17.0001Z'
          fill='#60C3D4'
        />
        <path
          d='M11.5237 17.2857C13.4181 17.2857 15.2349 16.5332 16.5745 15.1936C17.914 13.8541 18.6666 12.0373 18.6666 10.1429C18.6666 8.24845 17.914 6.43164 16.5745 5.09209C15.2349 3.75255 13.4181 3 11.5237 3C9.62931 3 7.8125 3.75255 6.47295 5.09209C5.13341 6.43164 4.38086 8.24845 4.38086 10.1429C4.38086 12.0373 5.13341 13.8541 6.47295 15.1936C7.8125 16.5332 9.62931 17.2857 11.5237 17.2857Z'
          fill='#C1ECF2'
        />
      </g>
      <mask id='mask1_2891_199565' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='1' y='22' width='27' height='16'>
        <path
          d='M1.6665 37.286H27.3808V35.7374C27.3625 34.0101 26.9971 32.3041 26.3062 30.7208C25.6154 29.1376 24.6133 27.7094 23.3595 26.5211C22.1057 25.3329 20.6258 24.4089 19.0078 23.804C17.3897 23.1992 15.6666 22.9258 13.9408 23.0002C10.6559 23.1561 7.55488 24.562 5.27292 26.93C2.99095 29.2981 1.70073 32.449 1.6665 35.7374V37.286Z'
          fill='#60C3D4'
        />
      </mask>
      <g mask='url(#mask1_2891_199565)'>
        <path
          d='M1.6665 37.286H27.3808V35.7374C27.3625 34.0101 26.9971 32.3041 26.3062 30.7208C25.6154 29.1376 24.6133 27.7094 23.3595 26.5211C22.1057 25.3329 20.6258 24.4089 19.0078 23.804C17.3897 23.1992 15.6666 22.9258 13.9408 23.0002C10.6559 23.1561 7.55488 24.562 5.27292 26.93C2.99095 29.2981 1.70073 32.449 1.6665 35.7374V37.286Z'
          fill='#60C3D4'
        />
        <path
          d='M-2.3335 37.286H23.3808V35.7374C23.3625 34.0101 22.9971 32.3041 22.3062 30.7208C21.6154 29.1376 20.6133 27.7094 19.3595 26.5211C18.1057 25.3329 16.6258 24.4089 15.0078 23.804C13.3897 23.1992 11.6666 22.9258 9.94079 23.0002C6.65589 23.1561 3.55488 24.562 1.27292 26.93C-1.00905 29.2981 -2.29927 32.449 -2.3335 35.7374V37.286Z'
          fill='#C1ECF2'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2378 14.4286C21.1349 13.2343 21.6664 11.7514 21.6664 10.1429C21.6664 8.53429 21.1349 7.05143 20.2378 5.85714C21.1373 4.65781 22.3913 3.7719 23.8223 3.3249C25.2533 2.8779 26.7886 2.89247 28.2108 3.36655C29.6331 3.84062 30.8701 4.75017 31.7467 5.96635C32.6232 7.18254 33.0949 8.6437 33.0949 10.1429C33.0949 11.642 32.6232 13.1032 31.7467 14.3194C30.8701 15.5355 29.6331 16.4451 28.2108 16.9192C26.7886 17.3932 25.2533 17.4078 23.8223 16.9608C22.3913 16.5138 21.1373 15.6279 20.2378 14.4286ZM27.3807 37.2857H38.8092V35.7371C38.7909 34.0098 38.4255 32.3038 37.7347 30.7206C37.0439 29.1373 36.0418 27.7091 34.788 26.5209C33.5342 25.3327 32.0543 24.4087 30.4362 23.8038C28.8182 23.1989 27.095 22.9256 25.3692 23C23.5847 23.0842 21.8373 23.5386 20.2378 24.3343C22.3636 25.3914 24.1557 27.0155 25.4163 29.0274C26.6768 31.0392 27.3566 33.3603 27.3807 35.7343V37.2857Z'
        fill='white'
      />
      <path
        d='M14.5237 17.2857C16.4181 17.2857 18.2349 16.5332 19.5745 15.1936C20.914 13.8541 21.6666 12.0373 21.6666 10.1429C21.6666 8.24845 20.914 6.43164 19.5745 5.09209C18.2349 3.75255 16.4181 3 14.5237 3C12.6293 3 10.8125 3.75255 9.47295 5.09209C8.13341 6.43164 7.38086 8.24845 7.38086 10.1429C7.38086 12.0373 8.13341 13.8541 9.47295 15.1936C10.8125 16.5332 12.6293 17.2857 14.5237 17.2857Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.6665 37.286H27.3808V35.7374C27.3625 34.0101 26.9971 32.3041 26.3062 30.7208C25.6154 29.1376 24.6133 27.7094 23.3595 26.5211C22.1057 25.3329 20.6258 24.4089 19.0078 23.804C17.3897 23.1992 15.6666 22.9258 13.9408 23.0002C10.6559 23.1561 7.55488 24.562 5.27292 26.93C2.99095 29.2981 1.70073 32.449 1.6665 35.7374V37.286Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.9521 17.2857C27.8466 17.2857 29.6634 16.5332 31.0029 15.1936C32.3425 13.8541 33.095 12.0373 33.095 10.1429C33.095 8.24845 32.3425 6.43164 31.0029 5.09209C29.6634 3.75255 27.8466 3 25.9521 3'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.0953 37.2858H38.8096V35.7372C38.7797 32.8441 37.7771 30.0452 35.9631 27.7912C34.1492 25.5372 31.6294 23.9593 28.8096 23.3115'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2891_199565'>
        <rect width='40' height='40' fill='white' transform='translate(0.666504)' />
      </clipPath>
    </defs>
  </svg>
)

export default ColorfulPeople
