import type { SVGProps } from 'react'
const Lightbulb = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='23' viewBox='0 0 22 23' fill='none' {...props}>
    <g clipPath='url(#clip0_5067_79747)'>
      <path
        d='M15.9785 12.7346C15.9846 11.8607 15.7565 11.0012 15.3182 10.2452C14.8799 9.48921 14.2472 8.86432 13.4858 8.43541C12.7244 8.0065 11.8621 7.78921 10.9884 7.80609C10.1147 7.82297 9.26145 8.0734 8.51721 8.5314C7.77296 8.98939 7.16489 9.63825 6.75608 10.4106C6.34729 11.183 6.15267 12.0507 6.19245 12.9236C6.23223 13.7966 6.50496 14.643 6.9823 15.375C7.45966 16.107 8.12423 16.6978 8.90705 17.0863V18.7181C8.90705 18.8624 8.96435 19.0008 9.06636 19.1027C9.16838 19.2048 9.30674 19.2621 9.45101 19.2621H12.7147C12.859 19.2621 12.9974 19.2048 13.0994 19.1027C13.2014 19.0008 13.2587 18.8624 13.2587 18.7181V17.0863C14.0719 16.6856 14.7573 16.0661 15.2377 15.2974C15.7181 14.5286 15.9747 13.6411 15.9785 12.7346Z'
        stroke='currentColor'
        strokeWidth='1.71'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.90784 21.9481H13.2595' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 2.04242V4.39957' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.2857 4.28955L15.62 5.97098' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.8685 9.16101H18.5114' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.71423 4.28955L6.37995 5.97098' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.13135 9.16101H3.48849' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_5067_79747'>
        <rect width='22' height='22' fill='white' transform='translate(0 0.769592)' />
      </clipPath>
    </defs>
  </svg>
)
export default Lightbulb
