import React from 'react'

import * as iconMap from '../icons'

export type IconVariant = keyof typeof iconMap

type Props = React.SVGProps<SVGSVGElement> & {
  className?: string
  variant: IconVariant
}

const Icon: React.FC<Props> = ({ variant, ...props }) => {
  const Icon = iconMap[variant]
  return <Icon aria-hidden='true' data-test-id={`${variant}-icon`} focusable='false' preserveAspectRatio='xMidYMid meet' {...props} />
}

export default Icon
