import type { SVGProps } from 'react'
const ColorfulSent = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.5 1.5H17.5C17.5 1.5 18.5 1.5 18.5 2.5V21.5C18.5 21.5 18.5 22.5 17.5 22.5H4.5C4.5 22.5 3.5 22.5 3.5 21.5V2.5C3.5 2.5 3.5 1.5 4.5 1.5Z'
      fill='#DCF3FC'
    />
    <path d='M2.5 4.5H5.5' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.5 9.5H5.5' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.5 14.5H5.5' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.5 19.5H5.5' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M21.5 4.5V7.5H18.5V3.5H20.5C20.7652 3.5 21.0196 3.60536 21.2071 3.79289C21.3946 3.98043 21.5 4.23478 21.5 4.5Z'
      fill='#97DCF7'
    />
    <path d='M18.5 7.5H21.5V11.5H18.5V7.5Z' fill='#97DCF7' />
    <path d='M18.5 11.5H21.5V15.5H18.5V11.5Z' fill='#97DCF7' />
    <path
      d='M21.5 15.5V18.5C21.5 18.7652 21.3946 19.0196 21.2071 19.2071C21.0196 19.3946 20.7652 19.5 20.5 19.5H18.5V15.5H21.5Z'
      fill='#97DCF7'
    />
    <path
      d='M4.5 1.5H17.5C17.5 1.5 18.5 1.5 18.5 2.5V21.5C18.5 21.5 18.5 22.5 17.5 22.5H4.5C4.5 22.5 3.5 22.5 3.5 21.5V2.5C3.5 2.5 3.5 1.5 4.5 1.5Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5 9C14.5 9.13261 14.4473 9.25979 14.3536 9.35355C14.2598 9.44732 14.1326 9.5 14 9.5H9C8.86739 9.5 8.74021 9.44732 8.64645 9.35355C8.55268 9.25979 8.5 9.13261 8.5 9V6C8.5 5.86739 8.55268 5.74021 8.64645 5.64645C8.74021 5.55268 8.86739 5.5 9 5.5H14C14.1326 5.5 14.2598 5.55268 14.3536 5.64645C14.4473 5.74021 14.5 5.86739 14.5 6V9Z'
      fill='#97DCF7'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 4.5V7.5H18.5V3.5H20.5C20.7652 3.5 21.0196 3.60536 21.2071 3.79289C21.3946 3.98043 21.5 4.23478 21.5 4.5Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M18.5 7.5H21.5V11.5H18.5V7.5Z' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.5 11.5H21.5V15.5H18.5V11.5Z' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M21.5 15.5V18.5C21.5 18.7652 21.3946 19.0196 21.2071 19.2071C21.0196 19.3946 20.7652 19.5 20.5 19.5H18.5V15.5H21.5Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulSent
