import type { SVGProps } from 'react'
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18 .857H6c-.947 0-1.714.768-1.714 1.714V21.43c0 .946.767 1.714 1.714 1.714h12c.947 0 1.714-.768 1.714-1.714V2.57c0-.946-.767-1.714-1.714-1.714ZM11.143 18.857h1.714'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgPhone
