import type { SVGProps } from 'react'
const SvgAutomationNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.33 13.857a.857.857 0 0 0-1.715 0v3.758h-3.758a.857.857 0 0 0 0 1.715h3.758v3.758a.857.857 0 0 0 1.715 0V19.33h3.758a.857.857 0 0 0 0-1.715H19.33v-3.758Z'
      fill='currentColor'
    />
    <path
      d='M7.097 1 1.24 10.103c-.565.852-.065 1.183.788 1.183h4.783l-4.428 12L16.108 9.33a.858.858 0 0 0-.583-1.474h-5.428L15.525 1H7.097Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgAutomationNew
