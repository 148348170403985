import type { SVGProps } from 'react'

export const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.63086 13.5085V4.02103C1.63086 3.53397 1.96234 3.10942 2.43485 2.99129L5.87657 2.13086L10.1223 3.19229L13.0492 2.46057C13.719 2.2931 14.368 2.79977 14.368 3.49032V12.9778C14.368 13.4649 14.0365 13.8895 13.564 14.0076L10.1223 14.868L5.87657 13.8066L2.94973 14.5383C2.27981 14.7058 1.63086 14.1991 1.63086 13.5085Z'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5.87695 2.13086V13.8066' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.123 14.8671V3.19141' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default MapIcon
