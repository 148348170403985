import type { SVGProps } from 'react'

const UserGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Group-Meeting-Call--Streamline-Core.svg'>
      <g id='Group-Meeting-Call--Streamline-Core.svg_2'>
        <g id='Group 2661'>
          <path
            id='Ellipse 1149'
            d='M2.05664 11.881C2.05664 12.323 2.23224 12.7469 2.5448 13.0595C2.85736 13.372 3.28128 13.5476 3.72331 13.5476C4.16534 13.5476 4.58926 13.372 4.90182 13.0595C5.21438 12.7469 5.38998 12.323 5.38998 11.881C5.38998 11.4389 5.21438 11.015 4.90182 10.7025C4.58926 10.3899 4.16534 10.2143 3.72331 10.2143C3.28128 10.2143 2.85736 10.3899 2.5448 10.7025C2.23224 11.015 2.05664 11.4389 2.05664 11.881Z'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1150'
            d='M0.666992 17.3813C1.18137 16.2041 2.35609 15.3813 3.72297 15.3813C5.08985 15.3813 6.26457 16.2041 6.77895 17.3813'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Group 2659'>
          <path
            id='Ellipse 1161'
            d='M6.27832 2.88096C6.27832 3.32299 6.45392 3.74692 6.76648 4.05948C7.07904 4.37204 7.50296 4.54763 7.94499 4.54763C8.38702 4.54763 8.81094 4.37204 9.1235 4.05948C9.43606 3.74692 9.61166 3.32299 9.61166 2.88096C9.61166 2.43893 9.43606 2.01501 9.1235 1.70245C8.81094 1.38989 8.38702 1.21429 7.94499 1.21429C7.50296 1.21429 7.07904 1.38989 6.76648 1.70245C6.45392 2.01501 6.27832 2.43893 6.27832 2.88096Z'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1162'
            d='M4.88867 8.38129C5.40305 7.20409 6.57777 6.38129 7.94465 6.38129C9.31153 6.38129 10.4863 7.20409 11.0006 8.38129'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Group 2660'>
          <path
            id='Ellipse 1137'
            d='M10.6113 11.881C10.6113 12.323 10.7869 12.7469 11.0995 13.0595C11.412 13.372 11.836 13.5476 12.278 13.5476C12.72 13.5476 13.1439 13.372 13.4565 13.0595C13.7691 12.7469 13.9447 12.323 13.9447 11.881C13.9447 11.4389 13.7691 11.015 13.4565 10.7025C13.1439 10.3899 12.72 10.2143 12.278 10.2143C11.836 10.2143 11.412 10.3899 11.0995 10.7025C10.7869 11.015 10.6113 11.4389 10.6113 11.881Z'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 1138'
            d='M9.22168 17.3813C9.73604 16.2041 10.9108 15.3813 12.2777 15.3813C13.6446 15.3813 14.8193 16.2041 15.3337 17.3813'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default UserGroup
