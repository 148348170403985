import type { SVGProps } from 'react'

const UserNeutral = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='User Single Neutral' clipPath='url(#clip0_1321_41509)'>
      <g id='user-single-neutral--close-geometric-human-person-single-user'>
        <path
          id='Vector'
          d='M7.99986 7.99997C9.8934 7.99997 11.4284 6.46494 11.4284 4.57139C11.4284 2.67785 9.8934 1.14282 7.99986 1.14282C6.10632 1.14282 4.57129 2.67785 4.57129 4.57139C4.57129 6.46494 6.10632 7.99997 7.99986 7.99997Z'
          fill='#C9B3EC'
          stroke='#330072'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M7.99962 10.2856C4.90939 10.2856 2.25988 12.1725 1.14038 14.8571H14.8588C13.7394 12.1725 11.0898 10.2856 7.99962 10.2856Z'
          fill='#C9B3EC'
          stroke='#330072'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1321_41509'>
        <rect width='16' height='16' fill='white' transform='translate(-0.000244141)' />
      </clipPath>
    </defs>
  </svg>
)

export default UserNeutral
