import type { SVGProps } from 'react'
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeWidth={1.25} strokeLinejoin='round'>
      <path d='M-9.172.288a.2.2 0 0 1 .344 0l2.208 3.703a.2.2 0 0 0 .304.048l3.244-2.84a.2.2 0 0 1 .327.107l.956 4.204a.2.2 0 0 0 .273.14l3.963-1.698a.2.2 0 0 1 .278.202l-.39 4.293a.2.2 0 0 0 .218.218l4.293-.39a.2.2 0 0 1 .202.278l-1.697 3.963a.2.2 0 0 0 .14.273l4.203.956a.2.2 0 0 1 .106.327l-2.839 3.245a.2.2 0 0 0 .048.303l3.703 2.208a.2.2 0 0 1 0 .344L7.009 22.38a.2.2 0 0 0-.048.303l2.84 3.245a.2.2 0 0 1-.107.327l-4.204.956a.2.2 0 0 0-.14.273l1.698 3.963a.2.2 0 0 1-.202.278l-4.293-.39a.2.2 0 0 0-.218.218l.39 4.293a.2.2 0 0 1-.278.202l-3.963-1.697a.2.2 0 0 0-.273.14l-.956 4.203a.2.2 0 0 1-.327.106l-3.244-2.839a.2.2 0 0 0-.304.048l-2.208 3.703a.2.2 0 0 1-.344 0l-2.208-3.703a.2.2 0 0 0-.303-.048l-3.245 2.84a.2.2 0 0 1-.327-.107l-.956-4.204a.2.2 0 0 0-.273-.14l-3.963 1.698a.2.2 0 0 1-.278-.202l.39-4.293a.2.2 0 0 0-.218-.218l-4.293.39a.2.2 0 0 1-.202-.278l1.697-3.963a.2.2 0 0 0-.14-.273l-4.203-.956a.2.2 0 0 1-.106-.327l2.839-3.245a.2.2 0 0 0-.048-.303l-3.703-2.208a.2.2 0 0 1 0-.344l3.703-2.208a.2.2 0 0 0 .048-.303l-2.84-3.245a.2.2 0 0 1 .107-.327l4.204-.956a.2.2 0 0 0 .14-.273l-1.698-3.963a.2.2 0 0 1 .202-.278l4.293.39a.2.2 0 0 0 .218-.218l-.39-4.293a.2.2 0 0 1 .278-.202l3.963 1.697a.2.2 0 0 0 .273-.14l.956-4.203a.2.2 0 0 1 .327-.106l3.245 2.839a.2.2 0 0 0 .303-.048L-9.172.288Z' />
      <path d='M-9.172 6.55a.2.2 0 0 1 .344 0l1.424 2.388a.2.2 0 0 0 .304.048l2.093-1.832a.2.2 0 0 1 .327.107l.617 2.712a.2.2 0 0 0 .273.14l2.557-1.096a.2.2 0 0 1 .278.202l-.251 2.77a.2.2 0 0 0 .217.217l2.77-.251a.2.2 0 0 1 .202.278L.888 14.79a.2.2 0 0 0 .139.273l2.712.617a.2.2 0 0 1 .106.327L2.014 18.1a.2.2 0 0 0 .048.303l2.389 1.425a.2.2 0 0 1 0 .344l-2.39 1.424a.2.2 0 0 0-.047.304l1.831 2.093a.2.2 0 0 1-.106.327l-2.712.617a.2.2 0 0 0-.14.273l1.096 2.557a.2.2 0 0 1-.202.278l-2.77-.252a.2.2 0 0 0-.217.218l.251 2.77a.2.2 0 0 1-.278.202l-2.557-1.095a.2.2 0 0 0-.273.14l-.617 2.711a.2.2 0 0 1-.327.107L-7.1 31.014a.2.2 0 0 0-.304.048l-1.424 2.389a.2.2 0 0 1-.344 0l-1.425-2.39a.2.2 0 0 0-.303-.047l-2.093 1.832a.2.2 0 0 1-.327-.107l-.617-2.712a.2.2 0 0 0-.273-.14l-2.557 1.096a.2.2 0 0 1-.278-.202l.251-2.77a.2.2 0 0 0-.217-.217l-2.77.251a.2.2 0 0 1-.202-.278l1.095-2.557a.2.2 0 0 0-.14-.273l-2.711-.617a.2.2 0 0 1-.107-.327l1.832-2.093a.2.2 0 0 0-.048-.303l-2.389-1.425a.2.2 0 0 1 0-.344l2.39-1.424a.2.2 0 0 0 .047-.304l-1.832-2.093a.2.2 0 0 1 .107-.327l2.712-.617a.2.2 0 0 0 .14-.273l-1.096-2.557a.2.2 0 0 1 .202-.278l2.77.251a.2.2 0 0 0 .217-.217l-.251-2.77a.2.2 0 0 1 .278-.202l2.557 1.095a.2.2 0 0 0 .273-.14l.617-2.711a.2.2 0 0 1 .327-.107l2.093 1.832a.2.2 0 0 0 .303-.048l1.425-2.389Z' />
    </g>
  </svg>
)
export default SvgStar
