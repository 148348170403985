import type { SVGProps } from 'react'
const Person = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <g clipPath='url(#clip0_2891_192124)'>
      <path
        d='M8 7.99512C9.933 7.99512 11.5 6.42811 11.5 4.49512C11.5 2.56212 9.933 0.995117 8 0.995117C6.067 0.995117 4.5 2.56212 4.5 4.49512C4.5 6.42811 6.067 7.99512 8 7.99512Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 15C2.59406 12.6455 5.09267 11 7.99999 11C10.9073 11 13.4059 12.6455 14.5 15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2891_192124'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default Person
