import type { SVGProps } from 'react'
const ColorfulEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3077_73984)'>
      <path d='M10.4993 20.4165V33.0632L1.16602 26.8332L10.4993 20.4165Z' fill='#C1ECF2' />
      <path d='M54.8333 26.8332L45.5 33.0632V20.4165L54.8333 26.8332Z' fill='#C1ECF2' />
      <path
        d='M54.8327 26.8335V51.3335C54.8327 52.2618 54.4639 53.152 53.8076 53.8084C53.1512 54.4647 52.2609 54.8335 51.3327 54.8335H4.66602C3.73776 54.8335 2.84752 54.4647 2.19114 53.8084C1.53476 53.152 1.16602 52.2618 1.16602 51.3335V26.8335L10.4993 33.0635L19.296 38.9202L19.8327 38.5002H36.166L36.7027 38.9202L45.4993 33.0635L54.8327 26.8335Z'
        fill='#EDFAFA'
      />
      <path
        d='M45.5 3.49984V33.0632L36.7033 38.9198L36.1667 38.4998H19.8333L19.2967 38.9198L10.5 33.0632V3.49984C10.5 2.881 10.7458 2.28751 11.1834 1.84992C11.621 1.41234 12.2145 1.1665 12.8333 1.1665H43.1667C43.7855 1.1665 44.379 1.41234 44.8166 1.84992C45.2542 2.28751 45.5 2.881 45.5 3.49984Z'
        fill='#D7F3F6'
      />
      <path
        d='M43.1667 1.1665H12.8333C12.2145 1.1665 11.621 1.41234 11.1834 1.84992C10.7458 2.28751 10.5 2.881 10.5 3.49984V33.0632L11.6293 33.8145L44.0837 1.35784C43.7943 1.23216 43.4822 1.16705 43.1667 1.1665V1.1665Z'
        fill='white'
      />
      <path
        d='M27.9993 28.0002C33.154 28.0002 37.3327 23.8215 37.3327 18.6668C37.3327 13.5122 33.154 9.3335 27.9993 9.3335C22.8447 9.3335 18.666 13.5122 18.666 18.6668C18.666 23.8215 22.8447 28.0002 27.9993 28.0002Z'
        fill='#C1ECF2'
      />
      <path
        d='M45.4993 20.4165L54.8327 26.8332V51.3332C54.8327 52.2614 54.4639 53.1517 53.8076 53.808C53.1512 54.4644 52.2609 54.8332 51.3327 54.8332H4.66602C3.73776 54.8332 2.84752 54.4644 2.19114 53.808C1.53476 53.1517 1.16602 52.2614 1.16602 51.3332V26.8332L10.4993 20.4165'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.16602 47.8333L19.8327 38.5H36.166L47.8327 47.8333'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M54.834 26.8335L40.834 36.1668' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.16602 26.8335L15.166 36.1668' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M45.5 33.0562V3.49984C45.5 2.881 45.2542 2.28751 44.8166 1.84992C44.379 1.41234 43.7855 1.1665 43.1667 1.1665H12.8333C12.2145 1.1665 11.621 1.41234 11.1834 1.84992C10.7458 2.28751 10.5 2.881 10.5 3.49984V33.0562'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28 22.1665C29.933 22.1665 31.5 20.5995 31.5 18.6665C31.5 16.7335 29.933 15.1665 28 15.1665C26.067 15.1665 24.5 16.7335 24.5 18.6665C24.5 20.5995 26.067 22.1665 28 22.1665Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.501 18.6668V20.4168C31.501 21.1904 31.8082 21.9322 32.3552 22.4792C32.9022 23.0262 33.6441 23.3335 34.4176 23.3335C35.1912 23.3335 35.933 23.0262 36.48 22.4792C37.027 21.9322 37.3343 21.1904 37.3343 20.4168V19.1008C37.3754 16.6119 36.4503 14.2038 34.7535 12.3825C33.0566 10.5612 30.7199 9.46835 28.2343 9.33348C25.9219 9.25879 23.6641 10.0456 21.899 11.5414C20.1339 13.0371 18.9873 15.1351 18.6815 17.4284C18.3757 19.7217 18.9326 22.0469 20.2442 23.9528C21.5557 25.8588 23.5284 27.2097 25.7796 27.7435C27.2989 28.1014 28.8828 28.0814 30.3926 27.6851'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3077_73984'>
        <rect width='56' height='56' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ColorfulEmail
