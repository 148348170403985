import type { SVGProps } from 'react'
const Megaphone = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2411_5009)'>
      <path d='M9.20459 1.7002L15.0002 11.8425' stroke='currentcolor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14.249 10.8572L1.62955 13.7874L0.821289 12.3874L9.66869 2.92383'
        stroke='currentcolor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.37451 13.1485L5.06939 14.352C5.32147 14.784 5.73486 15.0983 6.21861 15.2256C6.70236 15.3527 7.21685 15.2826 7.64889 15.0305C8.08095 14.7785 8.39517 14.3651 8.52241 13.8813C8.64966 13.3976 8.57954 12.8831 8.32746 12.451L8.21617 12.2583'
        stroke='currentcolor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2411_5009'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default Megaphone
