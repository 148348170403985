import type { SVGProps } from 'react'
const ColorfulUser = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2935_15082)'>
      <path
        d='M14.8281 8.06909L10.2372 14.1977L7.17291 11.9022'
        stroke='#D64700'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.04937 5.5626C7.12632 5.5626 7.99937 4.68955 7.99937 3.6126C7.99937 2.53564 7.12632 1.6626 6.04937 1.6626C4.97241 1.6626 4.09937 2.53564 4.09937 3.6126C4.09937 4.68955 4.97241 5.5626 6.04937 5.5626Z'
        fill='#FFCA99'
        stroke='#D64700'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.31639 13.0837L1.31531 13.1156C1.31618 12.1245 1.61073 11.1559 2.16177 10.3321C2.71281 9.50833 3.49561 8.86631 4.41131 8.48716C5.327 8.108 6.33452 8.00871 7.30662 8.20182C8.27871 8.39494 9.17178 8.87179 9.87302 9.57216'
        stroke='#D64700'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2935_15082'>
        <rect width='15.6' height='14.625' fill='white' transform='translate(0.199951 0.6875)' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulUser
