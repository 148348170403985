import type { SVGProps } from 'react'

const UserQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 19 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='User Question Query'>
      <g id='user-question-query--actions-question-geometric-human-person-single-user'>
        <path
          id='Vector'
          d='M6.71456 7.2857C8.29252 7.2857 9.57171 6.00651 9.57171 4.42855C9.57171 2.8506 8.29252 1.57141 6.71456 1.57141C5.13661 1.57141 3.85742 2.8506 3.85742 4.42855C3.85742 6.00651 5.13661 7.2857 6.71456 7.2857Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M12.8574 8.3332C12.8574 7.88112 12.9915 7.4392 13.2426 7.06333C13.4938 6.68744 13.8508 6.39447 14.2684 6.22148C14.6861 6.04847 15.1457 6.00321 15.5891 6.09141C16.0324 6.1796 16.4397 6.39729 16.7594 6.71695C17.079 7.03662 17.2967 7.44389 17.385 7.88728C17.4731 8.33066 17.4278 8.79024 17.2549 9.2079C17.0819 9.62557 16.7889 9.98254 16.413 10.2337C16.0371 10.4848 15.5953 10.6189 15.1431 10.6189V12.1428'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g id='Group 2670'>
          <path
            id='Vector_3'
            d='M15.1431 15.4285C14.9853 15.4285 14.8574 15.3006 14.8574 15.1428C14.8574 14.9849 14.9853 14.8571 15.1431 14.8571'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M15.1436 15.4285C15.3014 15.4285 15.4293 15.3006 15.4293 15.1428C15.4293 14.9849 15.3014 14.8571 15.1436 14.8571'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          id='Ellipse 1772'
          d='M9.24692 10.2373C8.49928 9.81342 7.63498 9.57141 6.71415 9.57141C3.87383 9.57141 1.57129 11.8739 1.57129 14.7143V15.2857H11.857V14.7143C11.857 14.0602 11.7349 13.4347 11.5123 12.8592'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default UserQuestion
