import type { SVGProps } from 'react'
const SvgNotebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.143 16.428H2.714a1.143 1.143 0 0 1-1.143-1.142V2.714a1.143 1.143 0 0 1 1.143-1.143h8.572M16.429 8.714V5L14.714 1.57 13 5v3.714M5 1.571v14.857M7.857 5.571h2.286M14.75 11v6M17.75 14h-6'
      stroke='currentcolor'
      strokeWidth={1.4}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgNotebook
