import type { SVGProps } from 'react'

export const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2341_24274)'>
      <path
        d='M14.9001 1.10156H1.10156C1.10215 2.65503 1.62619 4.16298 2.5891 5.38203C3.55201 6.60107 4.8976 7.46008 6.40871 7.82041V14.9001L9.59299 12.7773V7.82041C11.1041 7.46008 12.4497 6.60107 13.4126 5.38203C14.3755 4.16298 14.8995 2.65503 14.9001 1.10156Z'
        stroke='currentColor'
        strokeWidth='1.71'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2341_24274'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default MapIcon
