import type { SVGProps } from 'react'
const AutoBatch = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.53719 8.88581C9.36767 8.95942 9.18485 8.99739 9.00005 8.99739C8.81524 8.99739 8.63241 8.95942 8.46291 8.88581L1.94861 5.86866C1.84283 5.81531 1.75394 5.73365 1.69183 5.63275C1.62972 5.53186 1.59683 5.41572 1.59683 5.29724C1.59683 5.17877 1.62972 5.06262 1.69183 4.96173C1.75394 4.86084 1.84283 4.77917 1.94861 4.72581L8.46291 1.68581C8.63241 1.6122 8.81524 1.57422 9.00005 1.57422C9.18485 1.57422 9.36767 1.6122 9.53719 1.68581L16.0514 4.70295C16.1573 4.75631 16.2462 4.83798 16.3082 4.93887C16.3704 5.03976 16.4032 5.15591 16.4032 5.27438C16.4032 5.39286 16.3704 5.50901 16.3082 5.6099C16.2462 5.71079 16.1573 5.79246 16.0514 5.84581L9.53719 8.88581Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.57144 9.40015L8.50859 12.6116C8.65748 12.6795 8.81923 12.7147 8.98287 12.7147C9.14652 12.7147 9.30827 12.6795 9.45716 12.6116L11.2 11.8087'
      stroke='currentColor'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.57144 13.1144L8.50859 16.3258C8.65748 16.3937 8.81923 16.4289 8.98287 16.4289C9.14652 16.4289 9.30827 16.3937 9.45716 16.3258L11.2 15.523'
      stroke='currentColor'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.4977 10.5269L13.2893 14.8517C13.2643 15.2599 13.5036 15.2743 13.799 15.1037L15.4558 14.1472L16.3217 19.1898L18.2856 11.6108C18.3044 11.5455 18.3034 11.4761 18.2827 11.4113C18.2619 11.3466 18.2225 11.2895 18.1692 11.2473C18.116 11.2051 18.0514 11.1796 17.9837 11.1741C17.916 11.1687 17.8481 11.1835 17.7888 11.2166L15.9083 12.3023L16.4174 8.84123L13.4977 10.5269Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default AutoBatch
