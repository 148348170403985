import type { SVGProps } from 'react'
const LinkChain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <g clipPath='url(#clip0_8955_45470)'>
      <path d='M12.0015 16.0231V7.97716' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.97196 14.9458L6.97196 17.963C6.97196 20.7403 9.22342 22.9917 12.0007 22.9917C14.7779 22.9917 17.0294 20.7403 17.0294 17.963L17.0294 14.9458'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0294 9.05456L17.0294 6.03734C17.0294 3.26008 14.778 1.00866 12.0007 1.00864C9.22334 1.00874 6.97196 3.26012 6.97195 6.03739L6.97196 9.0546'
        stroke='currentColor'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8955_45470'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default LinkChain
