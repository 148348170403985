import type { SVGProps } from 'react'
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none' {...props}>
    <path d='M11.8588 5.64258L6.14453 11.3569' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.14453 5.64258L11.8588 11.3569' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.00084 15.9284C13.1036 15.9284 16.4294 12.6026 16.4294 8.49986C16.4294 4.39717 13.1036 1.07129 9.00084 1.07129C4.89815 1.07129 1.57227 4.39717 1.57227 8.49986C1.57227 12.6026 4.89815 15.9284 9.00084 15.9284Z'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgCheckCircle
