import type { SVGProps } from 'react'
const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1.125em' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.1429 7.07143H2.85716C2.14708 7.07143 1.57144 7.64707 1.57144 8.35714V16.0714C1.57144 16.7815 2.14708 17.3571 2.85716 17.3571H13.1429C13.853 17.3571 14.4286 16.7815 14.4286 16.0714V8.35714C14.4286 7.64707 13.853 7.07143 13.1429 7.07143Z'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5 7.07142V5.14285C12.5 3.94938 12.026 2.80478 11.182 1.96088C10.3381 1.11696 9.19348 0.642853 8 0.642853C6.80652 0.642853 5.66193 1.11696 4.81802 1.96088C3.97411 2.80478 3.5 3.94938 3.5 5.14285V7.07142'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 12.8571C8.35504 12.8571 8.64286 12.5693 8.64286 12.2143C8.64286 11.8592 8.35504 11.5714 8 11.5714C7.64497 11.5714 7.35715 11.8592 7.35715 12.2143C7.35715 12.5693 7.64497 12.8571 8 12.8571Z'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Lock
