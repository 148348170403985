import type { SVGProps } from 'react'
const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#success_svg__a)' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M3.647 22.629 15.1 18.36a2.143 2.143 0 0 0 .822-3.514L8.74 7.663a2.16 2.16 0 0 0-3.532.908L.956 20.023a2.092 2.092 0 0 0 2.691 2.606v0ZM20.447 12.223a3.53 3.53 0 0 0-2.503-.36M16.538 7.337a3.6 3.6 0 0 0 .566-2.486M12.596 1.234c.6 1.84.521 3.836-.223 5.623M22.247 6.48a.857.857 0 1 1 0-1.714.857.857 0 0 1 0 1.714Z' />
    </g>
    <defs>
      <clipPath id='success_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSuccess
