import type { SVGProps } from 'react'
const Collapse = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.7679 8.52286L9.48794 11.8029C9.44954 11.843 9.40341 11.8749 9.35235 11.8967C9.30128 11.9185 9.24633 11.9297 9.1908 11.9297C9.13527 11.9297 9.08032 11.9185 9.02926 11.8967C8.97819 11.8749 8.93206 11.843 8.89366 11.8029L5.61366 8.52286'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.76224 4.5L16.6194 4.5' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default Collapse
