import type { SVGProps } from 'react'
const ColorfulVip = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 4.2L11.7692 7.5L8 2L4.23077 7.5L1 4.2V11.35C1 11.7876 1.17019 12.2073 1.47314 12.5167C1.77608 12.8262 2.18696 13 2.61538 13H13.3846C13.813 13 14.2239 12.8262 14.5269 12.5167C14.8298 12.2073 15 11.7876 15 11.35V4.2Z'
      fill='#6B3DC2'
      stroke='#C9B3EC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulVip
