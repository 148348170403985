import type { SVGProps } from 'react'
const Unstar = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.7009 12.5082L12.3527 10.4936C12.3363 10.4012 12.3433 10.3062 12.3729 10.2172C12.4026 10.1283 12.454 10.0481 12.5226 9.98407L15.208 7.31988C15.2874 7.24559 15.3441 7.15023 15.3713 7.04495C15.3986 6.93967 15.3953 6.82879 15.362 6.72528C15.3286 6.62177 15.2665 6.5299 15.1827 6.46039C15.0991 6.39089 14.9974 6.34662 14.8896 6.33275L11.1639 5.78081C11.0699 5.7704 10.9802 5.73551 10.9039 5.67964C10.8275 5.62377 10.7671 5.54886 10.7288 5.46238L9.04108 2.0552C8.99512 1.95596 8.92172 1.87195 8.82957 1.81307C8.73742 1.75419 8.63034 1.7229 8.52098 1.7229C8.41161 1.7229 8.30453 1.75419 8.21238 1.81307C8.12023 1.87195 8.04684 1.95596 8.00088 2.0552L6.31321 5.46238C6.27482 5.54886 6.21444 5.62377 6.13808 5.67964C6.08383 5.71933 6.02287 5.74844 5.95837 5.7657'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.21386 6.17554L2.1524 6.33279C2.04189 6.34137 1.93623 6.38182 1.84823 6.44922C1.76023 6.51663 1.69366 6.6081 1.65659 6.71257C1.61953 6.81703 1.61354 6.93001 1.63938 7.03781C1.66521 7.14561 1.72174 7.2436 1.80213 7.31992L4.51939 9.94164C4.58792 10.0057 4.63936 10.0858 4.66903 10.1748C4.69868 10.2638 4.70562 10.3588 4.68921 10.4511L4.04174 14.2404C4.023 14.3481 4.03484 14.4589 4.07594 14.5601C4.11704 14.6614 4.18574 14.749 4.27422 14.8133C4.36269 14.8774 4.4674 14.9154 4.57642 14.9229C4.68544 14.9306 4.79439 14.9073 4.89089 14.856L8.245 13.0835C8.33089 13.0413 8.42529 13.0193 8.52097 13.0193C8.61665 13.0193 8.71106 13.0413 8.79694 13.0835L11.6067 14.5684'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M15.4203 15.2276L1.6217 1.42908' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default Unstar
