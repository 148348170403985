import type { SVGProps } from 'react'
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#calendar_svg__a)' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M2.571 3.429A1.714 1.714 0 0 0 .857 5.143v16.286a1.714 1.714 0 0 0 1.714 1.714H21.43a1.714 1.714 0 0 0 1.714-1.714V5.143a1.714 1.714 0 0 0-1.714-1.714H18M.857 9.429h22.286M6 .857V6M18 .857V6M6 3.429h8.571' />
    </g>
    <defs>
      <clipPath id='calendar_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCalendar
