import type { SVGProps } from 'react'
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#tag_svg__a)' stroke='currentColor' strokeWidth={2.314} strokeLinecap='round' strokeLinejoin='round'>
      <path d='m22.828 17.023-6.669 6.668a1.286 1.286 0 0 1-1.817 0L1.108 10.457a.617.617 0 0 1-.189-.497l1.012-6.566a.634.634 0 0 1 .6-.6l6.565-1.011a.617.617 0 0 1 .498.188l13.234 13.235a1.285 1.285 0 0 1 0 1.817v0Z' />
      <path d='M7.108 8.828a.857.857 0 1 0 0-1.714.857.857 0 0 0 0 1.714Z' />
    </g>
    <defs>
      <clipPath id='tag_svg__a'>
        <path fill='currentColor' transform='translate(.062 .925)' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTag
