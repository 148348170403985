import type { SVGProps } from 'react'

const Website = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Browser-Website-1--Streamline-Core' clipPath='url(#clip0_632_37407)'>
      <g id='Browser-Website-1--Streamline-Core.svg'>
        <path
          id='Vector'
          d='M13.8019 1.14282H2.19753C1.6149 1.14282 1.14258 1.61514 1.14258 2.19777V13.8021C1.14258 14.3848 1.6149 14.8571 2.19753 14.8571H13.8019C14.3845 14.8571 14.8569 14.3848 14.8569 13.8021V2.19777C14.8569 1.61514 14.3845 1.14282 13.8019 1.14282Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path id='Vector_2' d='M1.14258 5H14.857' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <path id='Vector 3734' d='M3 3H4' stroke='currentColor' strokeLinecap='round' />
      <path id='Vector 3735' d='M6 3H7' stroke='currentColor' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_632_37407'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default Website
