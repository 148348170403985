import type { SVGProps } from 'react'

const ReturnToChatBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.49767 1.91297C7.17157 1.91338 5.8704 2.27358 4.73277 2.95522C3.59514 3.63685 2.66364 4.61438 2.03749 5.7837C1.41133 6.95301 1.11398 8.27032 1.17709 9.59531C1.24021 10.9203 1.66143 12.2034 2.39588 13.3078L1.1665 16.5796L5.28324 15.835C6.27453 16.3196 7.36238 16.5741 8.46569 16.5795C9.56901 16.585 10.6593 16.3413 11.6554 15.8666C12.6514 15.3919 13.5275 14.6984 14.2183 13.8378C14.9091 12.9773 15.3969 11.9719 15.6451 10.8965C15.7453 10.4626 15.8055 10.0218 15.8256 9.57963'
      stroke='#330072'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0236 6.96062C15.6016 6.96062 16.8808 5.68143 16.8808 4.10348C16.8808 2.52553 15.6016 1.24634 14.0236 1.24634C12.4457 1.24634 11.1665 2.52553 11.1665 4.10348C11.1665 5.68143 12.4457 6.96062 14.0236 6.96062Z'
      fill='#D13C3C'
      stroke='#FF9999'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ReturnToChatBubble
