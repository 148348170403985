import type { SVGProps } from 'react'
const MarkUnread = (props: SVGProps<SVGSVGElement>) => (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.6996 8.27979V14.3546C14.6996 14.7302 14.3951 15.0347 14.0194 15.0347H1.68015C1.30452 15.0347 1 14.7302 1 14.3546V5.82078C1 5.44514 1.30452 5.14062 1.68015 5.14062H9.88964'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.21289 5.37305L6.84494 10.3399C7.12302 10.5852 7.48104 10.7205 7.85186 10.7205C8.22268 10.7205 8.58071 10.5852 8.85876 10.3399L11.6748 7.8565'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='14.5' cy='3.46484' r='2.5' fill='currentColor' />
  </svg>
)

export default MarkUnread
