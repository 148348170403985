import type { SVGProps } from 'react'
const ColorfulOpinions = (props: SVGProps<SVGSVGElement>) => (
  <svg width='26' height='22' viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.94444 4.05786C2.72343 4.05786 2.51147 3.95972 2.35519 3.78504C2.19891 3.61036 2.11111 3.37345 2.11111 3.12642C2.11111 2.87938 2.19891 2.64247 2.35519 2.46779C2.51147 2.29311 2.72343 2.19498 2.94444 2.19498H3.5C3.27899 2.19498 3.06702 2.09684 2.91074 1.92216C2.75446 1.74749 2.66667 1.51057 2.66667 1.26354C2.66667 0.748762 3.04 0.642578 3.5 0.642578H7.11111C8.695 0.642578 10.0556 0.953058 12 1.26354V6.54169C10.6389 6.54169 7.38889 9.33601 7.38889 11.8199C7.38889 12.8016 6.17278 13.1673 5.72222 11.3734C5.44444 10.2675 6.27778 8.09409 6.27778 8.09409H1.83333C1.61232 8.09409 1.40036 7.99596 1.24408 7.82128C1.0878 7.6466 1 7.40969 1 7.16265C1 6.64788 1.37333 5.92073 1.83333 5.92073H2.38889C2.16788 5.92073 1.95591 5.8226 1.79963 5.64792C1.64335 5.47324 1.55556 5.23633 1.55556 4.9893C1.55556 4.74226 1.64335 4.50535 1.79963 4.33067C1.95591 4.15599 2.16788 4.05786 2.38889 4.05786H2.94444Z'
      fill='#97DCF7'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 0.642578H15V7.63974H12V0.642578Z' fill='#97DCF7' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.5 2.64258C13.5989 2.64258 13.6956 2.61325 13.7778 2.55831C13.86 2.50337 13.9241 2.42528 13.9619 2.33392C13.9998 2.24256 14.0097 2.14202 13.9904 2.04503C13.9711 1.94804 13.9235 1.85895 13.8536 1.78902C13.7836 1.7191 13.6945 1.67148 13.5975 1.65219C13.5006 1.63289 13.4 1.64279 13.3087 1.68064C13.2173 1.71848 13.1392 1.78257 13.0843 1.86479C13.0293 1.94702 13 2.04369 13 2.14258C13 2.27519 13.0527 2.40236 13.1464 2.49613C13.2402 2.5899 13.3674 2.64258 13.5 2.64258Z'
      fill='black'
    />
    <path
      d='M23.1468 18.0486C23.2672 18.0486 23.3864 18.0727 23.4976 18.1195C23.6088 18.1663 23.7099 18.2349 23.795 18.3214C23.8801 18.4079 23.9476 18.5106 23.9937 18.6236C24.0398 18.7366 24.0635 18.8577 24.0635 18.9801C24.0635 19.1024 24.0398 19.2235 23.9937 19.3365C23.9476 19.4495 23.8801 19.5522 23.795 19.6387C23.7099 19.7252 23.6088 19.7938 23.4976 19.8406C23.3864 19.8874 23.2672 19.9115 23.1468 19.9115H22.5357C22.7788 19.9115 23.012 20.0096 23.1839 20.1843C23.3558 20.359 23.4524 20.5959 23.4524 20.8429C23.4524 21.3577 23.0417 21.4639 22.5357 21.4639H18.5635C16.8212 21.4639 16.4246 21.1534 14.2857 20.8429V15.5648C15.7829 15.5648 18.2579 12.7705 18.2579 10.2866C18.2579 9.30488 19.5957 8.93914 20.0913 10.7331C20.3968 11.839 19.4801 14.0124 19.4801 14.0124H24.369C24.6122 14.0124 24.8453 14.1105 25.0172 14.2852C25.1891 14.4599 25.2857 14.6968 25.2857 14.9438C25.2857 15.4586 24.875 16.1857 24.369 16.1857H23.7579C24.001 16.1857 24.2342 16.2839 24.4061 16.4585C24.578 16.6332 24.6746 16.8701 24.6746 17.1172C24.6746 17.3642 24.578 17.6011 24.4061 17.7758C24.2342 17.9505 24.001 18.0486 23.7579 18.0486H23.1468Z'
      fill='#DCF3FC'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 14.4668H15V21.464H12V14.4668Z' fill='#DCF3FC' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.5 16.3955C13.5989 16.3955 13.6956 16.3662 13.7778 16.3112C13.86 16.2563 13.9241 16.1782 13.9619 16.0868C13.9998 15.9955 14.0097 15.895 13.9904 15.798C13.9711 15.701 13.9235 15.6119 13.8536 15.542C13.7836 15.472 13.6945 15.4244 13.5975 15.4051C13.5006 15.3858 13.4 15.3957 13.3087 15.4336C13.2173 15.4714 13.1392 15.5355 13.0843 15.6177C13.0293 15.6999 13 15.7966 13 15.8955C13 16.0281 13.0527 16.1553 13.1464 16.2491C13.2402 16.3428 13.3674 16.3955 13.5 16.3955Z'
      fill='black'
    />
  </svg>
)
export default ColorfulOpinions
