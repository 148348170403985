import type { SVGProps } from 'react'
const SvgCampaignNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.385 13.77V23M13.77 18.385H23M9.923 16.846H4.538A1.538 1.538 0 0 1 3 15.308V4.538A1.538 1.538 0 0 1 4.538 3h15.385a1.538 1.538 0 0 1 1.539 1.538v6.923'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='m3 4.938 9.23 6.523 9.232-6.523' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgCampaignNew
