import type { SVGProps } from 'react'
const ArrowCurvedLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none' {...props}>
    <path
      d='M10.7134 3.57153L6.42773 7.85725L10.7134 12.143'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.2849 16.4286V13.5715C19.2849 12.0559 18.6828 10.6025 17.6112 9.53085C16.5396 8.45922 15.0861 7.85718 13.5706 7.85718H6.42773'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ArrowCurvedLeft
