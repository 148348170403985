import type { SVGProps } from 'react'

const ColorfulOfficialBuilding = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Official Building 3' clipPath='url(#clip0_580_116651)'>
      <g id='Vector'>
        <path
          d='M2.65137 39.1033L4.12803 34.6667C4.18328 34.5008 4.28929 34.3565 4.43107 34.2542C4.57285 34.1519 4.74321 34.0968 4.91803 34.0967H35.3847C35.5595 34.0968 35.7299 34.1519 35.8717 34.2542C36.0134 34.3565 36.1195 34.5008 36.1747 34.6667L37.6514 39.0967'
          fill='#C1ECF2'
        />
        <path
          d='M2.65137 39.1033L4.12803 34.6667C4.18328 34.5008 4.28929 34.3565 4.43107 34.2542C4.57285 34.1519 4.74321 34.0968 4.91803 34.0967H35.3847C35.5595 34.0968 35.7299 34.1519 35.8717 34.2542C36.0134 34.3565 36.1195 34.5008 36.1747 34.6667L37.6514 39.0967'
          stroke='#16606E'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <path id='Vector_2' d='M29.3213 15.77H34.318V34.1034H29.3213V15.77Z' fill='#C1ECF2' />
      <path id='Vector_3' d='M17.6514 15.77H22.6514V34.1034H17.6514V15.77Z' fill='#C1ECF2' />
      <path id='Vector_4' d='M5.98438 15.77H10.9877V34.1034H5.98438V15.77Z' fill='#C1ECF2' />
      <path id='Vector_5' d='M29.3213 15.77H34.318V20.8334H29.3213V15.77Z' fill='#60C3D4' />
      <path id='Vector_6' d='M17.6514 15.77H22.6514V20.8334H17.6514V15.77Z' fill='#60C3D4' />
      <path id='Vector_7' d='M5.98438 15.77H10.9877V20.8334H5.98438V15.77Z' fill='#60C3D4' />
      <path
        id='Vector_8'
        d='M29.3213 15.77H34.318V34.1034H29.3213V15.77Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_9'
        d='M17.6514 15.77H22.6514V34.1034H17.6514V15.77Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_10'
        d='M5.98438 15.77H10.9877V34.1034H5.98438V15.77Z'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path id='Vector_11' d='M39.3177 39.1033H0.984375' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        id='Vector_12'
        d='M37.4342 11.4167L36.1676 15.2001C36.1121 15.3642 36.0069 15.507 35.8665 15.6086C35.7261 15.7102 35.5575 15.7654 35.3842 15.7667H4.91755C4.74428 15.7654 4.57571 15.7102 4.43531 15.6086C4.29491 15.507 4.18967 15.3642 4.13422 15.2001L2.86755 11.4167C2.83 11.3109 2.81504 11.1984 2.82366 11.0864C2.83227 10.9745 2.86426 10.8656 2.91755 10.7667H37.3842C37.4375 10.8656 37.4695 10.9745 37.4781 11.0864C37.4867 11.1984 37.4718 11.3109 37.4342 11.4167Z'
        fill='#C1ECF2'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_13'
        d='M37.3846 10.7667H2.91797C2.99316 10.6247 3.1093 10.5085 3.2513 10.4333L19.7346 0.99999C19.8637 0.934279 20.0065 0.900024 20.1513 0.900024C20.2961 0.900024 20.4389 0.934279 20.568 0.99999L37.0513 10.4333C37.1933 10.5085 37.3094 10.6247 37.3846 10.7667Z'
        fill='white'
        stroke='#16606E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_580_116651'>
        <rect width='40' height='40' fill='white' transform='translate(0.151367)' />
      </clipPath>
    </defs>
  </svg>
)

export default ColorfulOfficialBuilding
