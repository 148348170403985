import type { SVGProps } from 'react'
const SvgUserWorkLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#UserWorkLaptop_svg__a)'>
      <path
        d='M10.25 6.5a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5ZM10.5 9.714a4.006 4.006 0 0 1 1.724.389 4.5 4.5 0 0 1 1.461 1.114c.418.478.75 1.046.975 1.67.226.625.341 1.294.34 1.97'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 9.25a.75.75 0 0 0-.731.917l1.143 5a.75.75 0 0 0 .731.583H12a.75.75 0 0 0 0-1.5H9.598l-1.01-4.417a.75.75 0 0 0-.73-.583H1Zm1.74 5-.8-3.5h5.32l.8 3.5H2.74Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='UserWorkLaptop_svg__a'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgUserWorkLaptop
