import type { SVGProps } from 'react'
const ColorfulSpammer = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2949_242718)'>
      <path
        d='M7.99986 15.4294C3.89717 15.4294 0.571289 12.1036 0.571289 8.00084C0.571289 3.89815 3.89717 0.572266 7.99986 0.572266C12.1026 0.572266 15.4284 3.89815 15.4284 8.00084C15.4284 12.1036 12.1026 15.4294 7.99986 15.4294Z'
        stroke='#BDBDBD'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13.257 13.2584L2.74268 2.74414' stroke='#BDBDBD' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_2949_242718'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulSpammer
