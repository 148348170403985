import type { SVGProps } from 'react'
const Blocks = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.6 1H1.9C1.40294 1 1 1.40294 1 1.9V4.6C1 5.09705 1.40294 5.5 1.9 5.5H4.6C5.09705 5.5 5.5 5.09705 5.5 4.6V1.9C5.5 1.40294 5.09705 1 4.6 1Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1 1H9.4C8.90294 1 8.5 1.40294 8.5 1.9V4.6C8.5 5.09705 8.90294 5.5 9.4 5.5H12.1C12.5971 5.5 13 5.09705 13 4.6V1.9C13 1.40294 12.5971 1 12.1 1Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.6 8.5H1.9C1.40294 8.5 1 8.90294 1 9.4V12.1C1 12.5971 1.40294 13 1.9 13H4.6C5.09705 13 5.5 12.5971 5.5 12.1V9.4C5.5 8.90294 5.09705 8.5 4.6 8.5Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1 8.5H9.4C8.90294 8.5 8.5 8.90294 8.5 9.4V12.1C8.5 12.5971 8.90294 13 9.4 13H12.1C12.5971 13 13 12.5971 13 12.1V9.4C13 8.90294 12.5971 8.5 12.1 8.5Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Blocks
