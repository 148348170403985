import type { SVGProps } from 'react'
const BellRinging = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M7.85718 15.1429H10.1429' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.5714 6.57143C13.5714 5.35901 13.0898 4.19625 12.2324 3.33894C11.3751 2.48163 10.2124 2 8.99995 2C7.78753 2 6.62477 2.48163 5.76746 3.33894C4.91015 4.19625 4.42852 5.35901 4.42852 6.57143V10.5714C4.42852 11.0261 4.2479 11.4622 3.92642 11.7837C3.60493 12.1051 3.16888 12.2857 2.71423 12.2857H15.2857C14.831 12.2857 14.3949 12.1051 14.0734 11.7837C13.7519 11.4622 13.5714 11.0261 13.5714 10.5714V6.57143Z'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.57141 6.42289C1.57203 5.33505 1.83145 4.26297 2.32826 3.2952C2.82506 2.32743 3.54498 1.49175 4.42855 0.857178'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.4286 6.42289C16.428 5.33505 16.1686 4.26297 15.6718 3.2952C15.175 2.32743 14.455 1.49175 13.5714 0.857178'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default BellRinging
