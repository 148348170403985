import type { SVGProps } from 'react'
const ColorfulInterests = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2445_10661)'>
      <path
        d='M20.51 8.15998L14.46 11.2C14.3134 10.9054 14.11 10.6427 13.8616 10.4269C13.6133 10.2111 13.3247 10.0464 13.0125 9.94237C12.7004 9.83831 12.3708 9.79691 12.0426 9.82052C11.7144 9.84414 11.3941 9.93231 11.1 10.08L10.43 8.73998C10.2691 8.4252 10.1966 8.07271 10.22 7.71998C10.2418 7.37115 10.3549 7.03418 10.5479 6.74283C10.741 6.45148 11.0073 6.216 11.32 6.05998L17.82 2.78998C17.793 3.14182 17.8621 3.4944 18.02 3.80998L19.82 7.37998C19.9745 7.69809 20.2131 7.96784 20.51 8.15998Z'
        fill='#97DCF7'
      />
      <path
        d='M18.02 3.80998C17.8621 3.4944 17.793 3.14182 17.82 2.78998L11.32 6.05998C11.0073 6.216 10.741 6.45148 10.5479 6.74283C10.3549 7.03418 10.2418 7.37115 10.22 7.71998C10.1966 8.07271 10.2691 8.4252 10.43 8.73998L10.951 9.78098L19.089 5.92998L18.02 3.80998Z'
        fill='#DCF3FC'
      />
      <path
        d='M11.1 10.08C10.6669 10.3002 10.307 10.6413 10.0638 11.062C9.82063 11.4826 9.70468 11.9648 9.72999 12.45L5.52 14.57C5.28566 14.6879 5.03036 14.7584 4.76872 14.7775C4.50709 14.7965 4.24426 14.7638 3.9953 14.6811C3.74634 14.5984 3.51614 14.4675 3.31789 14.2957C3.11965 14.1239 2.95724 13.9146 2.84 13.68C2.69575 13.4019 2.62031 13.0932 2.62 12.78C2.62111 12.4081 2.72548 12.0438 2.92147 11.7278C3.11746 11.4117 3.39737 11.1563 3.73 10.99L10.22 7.71997C10.1965 8.0727 10.2691 8.42519 10.43 8.73997L11.1 10.08Z'
        fill='#97DCF7'
      />
      <path
        d='M10.43 8.73997C10.2691 8.42519 10.1965 8.0727 10.22 7.71997L3.73 10.99C3.39737 11.1563 3.11746 11.4117 2.92147 11.7278C2.72548 12.0438 2.62111 12.4081 2.62 12.78C2.62194 13.0744 2.69031 13.3646 2.82 13.629L10.95 9.78097L10.43 8.73997Z'
        fill='#DCF3FC'
      />
      <path
        d='M11.1 10.08C10.6669 10.3002 10.307 10.6413 10.0638 11.062C9.82063 11.4826 9.70468 11.9648 9.72999 12.45L5.52 14.57C5.28566 14.6879 5.03036 14.7584 4.76872 14.7775C4.50709 14.7965 4.24426 14.7638 3.9953 14.6811C3.74634 14.5984 3.51614 14.4675 3.31789 14.2957C3.11965 14.1239 2.95724 13.9146 2.84 13.68C2.69575 13.4019 2.62031 13.0932 2.62 12.78C2.62111 12.4081 2.72548 12.0438 2.92147 11.7278C3.11746 11.4117 3.39737 11.1563 3.73 10.99L10.22 7.71997C10.1965 8.0727 10.2691 8.42519 10.43 8.73997L11.1 10.08Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.51 8.15998L14.46 11.2C14.3134 10.9054 14.11 10.6427 13.8616 10.4269C13.6133 10.2111 13.3247 10.0464 13.0125 9.94237C12.7004 9.83831 12.3708 9.79691 12.0426 9.82052C11.7144 9.84414 11.3941 9.93231 11.1 10.08L10.43 8.73998C10.2691 8.4252 10.1966 8.07271 10.22 7.71998C10.2418 7.37115 10.3549 7.03418 10.5479 6.74283C10.741 6.45148 11.0073 6.216 11.32 6.05998L17.82 2.78998C17.793 3.14182 17.8621 3.4944 18.02 3.80998L19.82 7.37998C19.9745 7.69809 20.2131 7.96784 20.51 8.15998Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.603027 14.802L2.83703 13.679' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M23.4001 7.81999L22.5001 8.26999C22.188 8.42823 21.8397 8.50138 21.4903 8.48207C21.1409 8.46276 20.8028 8.35167 20.5101 8.15999C20.2132 7.96785 19.9745 7.6981 19.8201 7.37999L18.0201 3.80999C17.8621 3.49442 17.793 3.14184 17.8201 2.78999C17.8384 2.44024 17.9488 2.10155 18.1402 1.80826C18.3317 1.51497 18.5973 1.27752 18.9101 1.11999L19.8001 0.679993L23.4001 7.81999Z'
        fill='#DCF3FC'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.35 14.55C12.7571 14.8476 12.0704 14.8979 11.4405 14.6898C10.8106 14.4817 10.2889 14.0322 9.99 13.44C9.83174 13.1331 9.74297 12.7951 9.73 12.45C9.70597 12.0001 9.804 11.552 10.0137 11.1532C10.2234 10.7544 10.537 10.4196 10.9213 10.1844C11.3057 9.94918 11.7464 9.8222 12.197 9.81689C12.6475 9.81157 13.0912 9.92813 13.4809 10.1542C13.8707 10.3803 14.1921 10.7076 14.4111 11.1013C14.6302 11.4951 14.7387 11.9407 14.7253 12.3911C14.7119 12.8415 14.577 13.2799 14.3349 13.6599C14.0928 14.04 13.7525 14.3475 13.35 14.55Z'
        fill='#DCF3FC'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.397 14.824V23.324' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.39697 23.324L10.679 14.284' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.3969 23.324L14.0129 14.068' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_2445_10661'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulInterests
