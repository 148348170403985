import type { SVGProps } from 'react'
const ColorfulDraft = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2925_17504)'>
      <path
        d='M1.08369 14.9165C0.874722 14.7076 0.757324 14.4241 0.757324 14.1286V4.1L4.10018 0.757141H11.9002C12.1957 0.757141 12.4792 0.874539 12.6881 1.08351C12.897 1.29248 13.0145 1.5759 13.0145 1.87143V14.1286C13.0145 14.4241 12.897 14.7076 12.6881 14.9165C12.4792 15.1254 12.1957 15.2429 11.9002 15.2429H1.87161C1.57608 15.2429 1.29266 15.1254 1.08369 14.9165Z'
        fill='#96DFEB'
      />
      <path d='M4.65735 0.757141L4.65736 4.65714H0.757324' fill='#D7F3F6' />
      <path
        d='M4.65735 0.757141L4.65736 4.65714H0.757324'
        stroke='#2B7C8B'
        strokeWidth='1.11'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0135 1.87143C13.0135 1.5759 12.8961 1.29248 12.6871 1.08351C12.4782 0.874539 12.1947 0.757141 11.8992 0.757141H4.09921L0.756348 4.1V14.1286C0.756348 14.4241 0.873745 14.7076 1.08271 14.9165C1.29168 15.1254 1.5751 15.2429 1.87063 15.2429H11.8992C12.1947 15.2429 12.4782 15.1254 12.6871 14.9165C12.8961 14.7076 13.0135 14.4241 13.0135 14.1286V12.4571'
        stroke='#2B7C8B'
        strokeWidth='1.11'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.669 12.4589L6.32764 13.0603L6.88453 9.67442L12.1518 4.42947C12.2553 4.32507 12.3785 4.24221 12.5142 4.18566C12.65 4.12912 12.7955 4.10001 12.9426 4.10001C13.0895 4.10001 13.2352 4.12912 13.3709 4.18566C13.5066 4.24221 13.6299 4.32507 13.7334 4.42947L14.914 5.61007C15.0184 5.71363 15.1012 5.83681 15.1578 5.97253C15.2143 6.10826 15.2434 6.25383 15.2434 6.40087C15.2434 6.5479 15.2143 6.69348 15.1578 6.8292C15.1012 6.96494 15.0184 7.08812 14.914 7.19166L9.669 12.4589Z'
        fill='#D7F3F6'
      />
      <path
        d='M9.669 12.4589L6.32764 13.0603L6.88453 9.67442L12.1518 4.42947C12.2553 4.32507 12.3785 4.24221 12.5142 4.18566C12.6499 4.12912 12.7955 4.10001 12.9426 4.10001C13.0895 4.10001 13.2352 4.12912 13.3709 4.18566C13.5066 4.24221 13.6299 4.32507 13.7334 4.42947L14.914 5.61007C15.0184 5.71363 15.1012 5.83681 15.1578 5.97253C15.2143 6.10826 15.2434 6.25383 15.2434 6.40087C15.2434 6.5479 15.2143 6.69348 15.1578 6.8292C15.1012 6.96494 15.0184 7.08812 14.914 7.19166L9.669 12.4589Z'
        stroke='#2B7C8B'
        strokeWidth='1.11'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2925_17504'>
        <rect width='15.6' height='15.6' fill='white' transform='translate(0.200195 0.199997)' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulDraft
