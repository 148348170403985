import type { SVGProps } from 'react'
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M13.5723 10.2858L15.858 9.71436L16.4294 12.0001' />
      <path d='M15.8576 9.71436C15.3499 11.1528 14.4278 12.4088 13.2076 13.324C11.9873 14.2392 10.5234 14.7726 9.0004 14.8572C7.59253 14.8574 6.2187 14.4244 5.06552 13.6168C3.91234 12.8091 3.0357 11.6661 2.55469 10.3429' />
      <path d='M4.42941 5.71429L2.14369 6.28571L1.57227 4' />
      <path d='M2.14453 6.28568C3.10453 3.65711 6.0531 1.14282 9.00167 1.14282C10.4164 1.1468 11.7953 1.58826 12.9492 2.40668C14.1033 3.22511 14.9758 4.38042 15.4474 5.71425' />
    </g>
  </svg>
)
export default SvgRefresh
