import type { SVGProps } from 'react'
const SvgEyeOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.68 10.708c.284.354.442.815.442 1.292 0 .477-.158.938-.442 1.292-1.8 2.18-5.897 6.423-10.68 6.423-4.783 0-8.88-4.243-10.68-6.423A2.07 2.07 0 0 1 .878 12c0-.477.158-.938.442-1.292C3.12 8.528 7.217 4.286 12 4.286c4.783 0 8.88 4.243 10.68 6.422Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 15.429a3.429 3.429 0 1 0 0-6.858 3.429 3.429 0 0 0 0 6.858Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgEyeOpen
