import type { SVGProps } from 'react'
const Forbidden = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.99986 15.4287C3.89717 15.4287 0.571289 12.1028 0.571289 8.0001C0.571289 3.89742 3.89717 0.571533 7.99986 0.571533C12.1026 0.571533 15.4284 3.89742 15.4284 8.0001C15.4284 12.1028 12.1026 15.4287 7.99986 15.4287Z'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M13.257 13.2572L2.74268 2.74292' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default Forbidden
