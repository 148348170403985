import type { SVGProps } from 'react'
const ColorfulNewsSource = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.0908 13.5714V5.05201L13.3635 5.052V13.5714C13.3635 13.9502 13.5072 14.3136 13.7629 14.5815C14.0186 14.8493 14.3655 14.9999 14.7271 14.9999C15.0888 14.9999 15.4357 14.8493 15.6914 14.5815C15.9471 14.3136 16.0908 13.9502 16.0908 13.5714Z'
      fill='#9F580A'
    />
    <path
      d='M13.7629 14.5813C14.0186 14.8492 14.3655 14.9998 14.7271 14.9998H3.00007C2.71074 14.9998 2.43328 14.8794 2.22869 14.6651C2.02411 14.4507 1.90918 14.16 1.90918 13.857V3.57142C1.90918 3.41987 1.96665 3.27452 2.06894 3.16736C2.17123 3.06021 2.30996 3 2.45462 3H12.8181C12.9627 3 13.1015 3.06021 13.2038 3.16736C13.306 3.27452 13.3635 3.41987 13.3635 3.57142V13.5713C13.3635 13.9501 13.5072 14.3135 13.7629 14.5813Z'
      fill='#9F580A'
    />
    <path
      d='M16.0908 5.05191V13.5713C16.0908 13.9501 15.9471 14.3135 15.6914 14.5813C15.4357 14.8492 15.0888 14.9998 14.7271 14.9998M14.7271 14.9998C14.3655 14.9998 14.0186 14.8492 13.7629 14.5813C13.5072 14.3135 13.3635 13.9501 13.3635 13.5713V3.57142C13.3635 3.41987 13.306 3.27452 13.2038 3.16736C13.1015 3.06021 12.9627 3 12.8181 3H2.45462C2.30996 3 2.17123 3.06021 2.06894 3.16736C1.96665 3.27452 1.90918 3.41987 1.90918 3.57142V13.857C1.90918 14.16 2.02411 14.4507 2.22869 14.6651C2.43328 14.8794 2.71074 14.9998 3.00007 14.9998H14.7271Z'
      stroke='#FACA15'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.0909 6H5.18188V8.72723H10.0909V6Z' fill='#9F580A' />
    <path d='M10.0909 6H5.18188V8.72723H10.0909V6Z' stroke='#FACA15' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.18188 11.9998H10.0909' stroke='#FACA15' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default ColorfulNewsSource
