import type { SVGProps } from 'react'
const ColorfulMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.7154 4.38889L11.4615 5.77778V4.11111C11.4615 3.81643 11.34 3.53381 11.1236 3.32544C10.9072 3.11706 10.6137 3 10.3077 3H1.65385C1.34783 3 1.05434 3.11706 0.837953 3.32544C0.621565 3.53381 0.5 3.81643 0.5 4.11111V11.8889C0.5 12.1836 0.621565 12.4662 0.837953 12.6746C1.05434 12.8829 1.34783 13 1.65385 13H10.3077C10.6137 13 10.9072 12.8829 11.1236 12.6746C11.34 12.4662 11.4615 12.1836 11.4615 11.8889V10.2222L14.7154 11.6111C14.8031 11.6437 14.8978 11.6552 14.9911 11.6445C15.0845 11.6338 15.1738 11.6013 15.2511 11.5498C15.3285 11.4983 15.3916 11.4294 15.4349 11.3491C15.4783 11.2688 15.5006 11.1794 15.5 11.0889V4.91111C15.5006 4.82057 15.4783 4.73124 15.4349 4.6509C15.3916 4.57057 15.3285 4.50166 15.2511 4.45018C15.1738 4.39869 15.0845 4.3662 14.9911 4.35551C14.8978 4.34483 14.8031 4.35629 14.7154 4.38889Z'
      fill='#408525'
      stroke='#CCEDBD'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulMedia
