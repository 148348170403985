import type { SVGProps } from 'react'
const ColorfulFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.8166 8.67991C13.088 9.00557 12.8564 9.5 12.4325 9.5H4C3.72386 9.5 3.5 9.27614 3.5 9V2C3.5 1.72386 3.72386 1.5 4 1.5H12.4325C12.8564 1.5 13.088 1.99443 12.8166 2.32009L10.4334 5.17991C10.2789 5.36533 10.2789 5.63467 10.4334 5.82009L12.8166 8.67991Z'
      fill='#BA1A1A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.5 1C3.22386 1 3 1.22386 3 1.5V9.5V15.5C3 15.7761 3.22386 16 3.5 16C3.77614 16 4 15.7761 4 15.5V10H13.5C13.694 10 13.8705 9.88777 13.9528 9.71208C14.0351 9.53639 14.0083 9.32895 13.8841 9.17991L10.8175 5.5L13.8841 1.82009C14.0083 1.67105 14.0351 1.46361 13.9528 1.28792C13.8705 1.11223 13.694 1 13.5 1H3.5ZM4 2V9H12.4325L9.78256 5.82009C9.62804 5.63467 9.62804 5.36533 9.78256 5.17991L12.4325 2H4Z'
      fill='#FFB3B3'
    />
  </svg>
)
export default ColorfulFlag
