import type { SVGProps } from 'react'

const Fax = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Print-Text--Streamline-Streamline--3.0'>
      <path
        id='Vector'
        d='M4.5 12.4994H2.5C2.23478 12.4994 1.98043 12.394 1.79289 12.2065C1.60536 12.019 1.5 11.7646 1.5 11.4994V6.49939C1.5 6.23417 1.60536 5.97982 1.79289 5.79228C1.98043 5.60475 2.23478 5.49939 2.5 5.49939H15.5C15.7652 5.49939 16.0196 5.60475 16.2071 5.79228C16.3946 5.97982 16.5 6.23417 16.5 6.49939V11.4994C16.5 11.7646 16.3946 12.019 16.2071 12.2065C16.0196 12.394 15.7652 12.4994 15.5 12.4994H13.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path id='Vector_2' d='M3.5 7.49939H4.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        id='Vector_3'
        d='M4.5 9.49939H13.5V16.4994H4.5V9.49939Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_4'
        d='M13.5 5.49939H4.5V2.49939C4.5 2.23417 4.60536 1.97982 4.79289 1.79228C4.98043 1.60475 5.23478 1.49939 5.5 1.49939H12.5C12.7652 1.49939 13.0196 1.60475 13.2071 1.79228C13.3946 1.97982 13.5 2.23417 13.5 2.49939V5.49939Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path id='Vector_5' d='M6.5 11.4994H11.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path id='Vector_6' d='M6.5 13.4994H10' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
)
export default Fax
