import type { SVGProps } from 'react'
const ArrowCurvedRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M9.99944 3.57129L14.2852 7.857L9.99944 12.1427'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.42801 16.4284V13.5712C1.42801 12.0557 2.03005 10.6022 3.10169 9.53061C4.17332 8.45897 5.62678 7.85693 7.1423 7.85693H14.2852'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ArrowCurvedRight
