import type { SVGProps } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 18 17' fill='none' {...props}>
    <path
      d='M1.66797 11.77V12.9129C1.66797 13.5191 1.90878 14.1005 2.33744 14.5291C2.76609 14.9578 3.34747 15.1986 3.95368 15.1986H14.2394C14.8456 15.1986 15.427 14.9578 15.8556 14.5291C16.2843 14.1005 16.5251 13.5191 16.5251 12.9129V11.77'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3848 5.33325L9.00015 1.38453L5.61554 5.33325'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 1.33317L9 10.6665' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgUpload
