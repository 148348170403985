import type { SVGProps } from 'react'
const Handshake = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Business Handshake'>
      <path id='Vector 3733' d='M1.75 11V4L3.75 5L7.25 4L13.75 12.5L1.75 11Z' fill='#FFCA99' />
      <g id='business-handshake--deal-contract-business-money-payment-agreement'>
        <g id='Rectangle 9'>
          <path d='M13.9006 12.1834L10.3252 14.8317C9.56926 15.4006 8.56265 15.438 7.76488 14.9268L1.57031 10.9575' fill='#FFCA99' />
          <path
            d='M13.9006 12.1834L10.3252 14.8317C9.56926 15.4006 8.56265 15.438 7.76488 14.9268L1.57031 10.9575'
            stroke='#C12E00'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Vector 1282'>
          <path
            d='M16.4275 10.3165L13.9007 12.1745L9.95317 8.17306L8.58142 9.23096C7.96005 9.71015 7.06639 9.58461 6.60096 8.95272C6.17235 8.37081 6.25707 7.55808 6.79647 7.07718L8.57293 5.49342C9.15967 4.97032 9.90822 4.66478 10.6932 4.62793L13.2999 4.50561L16.4275 2.71436'
            fill='#FFCA99'
          />
          <path
            d='M16.4275 10.3165L13.9007 12.1745L9.95317 8.17306L8.58142 9.23096C7.96005 9.71015 7.06639 9.58461 6.60096 8.95272C6.17235 8.37081 6.25707 7.55808 6.79647 7.07718L8.57293 5.49342C9.15967 4.97032 9.90822 4.66478 10.6932 4.62793L13.2999 4.50561L16.4275 2.71436'
            stroke='#C12E00'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Vector 1289'>
          <path d='M9.95312 8.17952C10.9004 9.07338 12.2439 8.61736 12.7434 7.8877L9.95312 8.17952Z' fill='#FFCA99' />
          <path
            d='M9.95312 8.17952C10.9004 9.07338 12.2439 8.61736 12.7434 7.8877'
            stroke='#C12E00'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='Rectangle 8'>
          <path
            d='M1.57031 3.26904L4.10663 4.7154L6.36806 4.30052C7.13429 4.15995 7.91776 4.3914 8.50309 4.93123L8.82471 5.2748'
            fill='#FFCA99'
          />
          <path
            d='M1.57031 3.26904L4.10663 4.7154L6.36806 4.30052C7.13429 4.15995 7.91776 4.3914 8.50309 4.93123L8.82471 5.2748'
            stroke='#C12E00'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)
export default Handshake
