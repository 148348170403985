import type { SVGProps } from 'react'
const SvgDraft = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m13 15.581-5.143.926.857-5.211 9.823-9.789a1.715 1.715 0 0 1 2.434 0l1.818 1.817a1.713 1.713 0 0 1 0 2.435L13 15.58Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.714 16.439v5.142A1.714 1.714 0 0 1 17 23.296H2.714A1.715 1.715 0 0 1 1 21.58V3.296A1.714 1.714 0 0 1 2.714 1.58h9.143'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgDraft
