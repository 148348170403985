import type { SVGProps } from 'react'

const SvgNoEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none' {...props}>
    <path
      d='M1.53516 5.38393V13.5536C1.53516 14.1453 2.01485 14.625 2.60658 14.625H11M15.0612 14.3906C15.3066 14.1943 15.4637 13.8923 15.4637 13.5536V4.44643C15.4637 3.8547 14.984 3.375 14.3923 3.375H3.34241'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.53516 6.12061L6.15491 10.4071M15.4637 4.71436L9.78786 9.55725'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M16 15.5625L1 1.5' stroke='currentColor' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgNoEmail
