import type { SVGProps } from 'react'
const MailReplyAll = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.9282 11.2852L10.2139 12.9994L11.9282 14.7137'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2139 13H13.6424C14.2486 13 14.83 13.2408 15.2587 13.6695C15.6874 14.0982 15.9282 14.6795 15.9282 15.2857V16.4286'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.49944 11.2852L6.78516 12.9994L8.49944 14.7137'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.92843 11.857H2.21415C1.91104 11.857 1.62035 11.7366 1.40602 11.5223C1.1917 11.3079 1.07129 11.0173 1.07129 10.7141V2.71415C1.07129 2.41104 1.1917 2.12035 1.40602 1.90602C1.62035 1.6917 1.91104 1.57129 2.21415 1.57129H13.6427C13.9458 1.57129 14.2365 1.6917 14.4508 1.90602C14.6651 2.12035 14.7856 2.41104 14.7856 2.71415V9.57129'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.07129 3.01172L7.92843 7.85743L14.7856 3.01172'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default MailReplyAll
