import type { SVGProps } from 'react'

const ColorfulThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.77062 8.67102L2.66594 9.02893V7.5064H0.47168V15.567L2.39725 15.8356L3.33764 15.2983L13.2342 15.8356L14.1746 14.9848L13.9059 13.8205L14.6224 13.2384V11.4023L15.4285 10.6411L14.9359 8.67102L14.6224 6.47645H10.1891L10.9952 2.80442V1.46099L9.83087 1.14746L8.80091 1.81927L6.96489 5.53617L4.77062 8.67102Z'
      fill='transparent'
    />
    <path
      d='M13.5 8.70703H14.3C14.9667 8.70703 15.5 9.24036 15.5 9.90703C15.5 10.5737 14.9667 11.107 14.3 11.107H13.5'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5 13.5064H13.7C14.3667 13.5064 14.9 12.9731 14.9 12.3064C14.9 11.6398 14.3667 11.1064 13.7 11.1064H13.5'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.4997 8.70654H13.9663C14.633 8.70654 15.1663 8.17321 15.1663 7.50654C15.1663 6.83987 14.633 6.30654 13.9663 6.30654H9.96634L10.033 6.17321C10.6997 5.03987 11.0997 3.70654 11.1663 2.30654C11.233 1.83987 11.033 1.43987 10.633 1.17321C10.3663 0.973205 10.0997 0.973205 9.76634 1.03987C9.43301 1.10654 9.16634 1.30654 9.03301 1.57321C7.67301 3.95254 6.76834 6.95321 4.43301 8.63987H2.83301'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.4997 13.5068H13.033C13.7663 13.5068 14.2997 14.1068 14.1663 14.8402C14.0997 15.4402 13.5663 15.8402 12.9663 15.8402H8.63301C8.36634 15.8402 8.03301 15.8402 7.76634 15.7735L4.23301 15.1735H2.83301'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.83333 7.50684H0.5V15.8402H2.83333V7.50684Z'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ColorfulThumbsUp
