import type { SVGProps } from 'react'
const SvgWhistle = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.71345 6.14307H15.8563C16.0079 6.14307 16.1532 6.20327 16.2604 6.31044C16.3675 6.41759 16.4277 6.56294 16.4277 6.7145V10.2116C16.426 10.35 16.374 10.4831 16.2815 10.5861C16.189 10.6891 16.0623 10.755 15.9249 10.7716L11.8563 11.2859C11.8563 12.3031 11.5547 13.2974 10.9896 14.1432C10.4245 14.9889 9.62127 15.6481 8.68154 16.0374C7.7418 16.4266 6.70775 16.5284 5.71012 16.3299C4.71253 16.1315 3.79619 15.6417 3.07688 14.9225C2.35768 14.2032 1.86785 13.2868 1.66945 12.2892C1.47093 11.2916 1.57276 10.2576 1.96202 9.31783C2.35128 8.37811 3.01048 7.57489 3.85619 7.0098C4.70202 6.44469 5.69628 6.14307 6.71345 6.14307Z'
      stroke='#C27803'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.71345 12.9999C5.76667 12.9999 4.99916 12.2324 4.99916 11.2856C4.99916 10.3388 5.76667 9.57129 6.71345 9.57129C7.66023 9.57129 8.42773 10.3388 8.42773 11.2856C8.42773 12.2324 7.66023 12.9999 6.71345 12.9999Z'
      stroke='#C27803'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.7148 1.57129V3.28557V1.57129Z' fill='#C27803' />
    <path d='M10.7148 1.57129V3.28557' stroke='#C27803' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.42773 3.85728L6.99916 2.42871' stroke='#C27803' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.9992 3.85728L14.4277 2.42871' stroke='#C27803' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgWhistle
