import type { SVGProps } from 'react'
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.997 2.833a9.162 9.162 0 0 0-9.15 9.603 9.169 9.169 0 0 0 1.523 4.64l-1.537 4.09 5.146-.93a9.161 9.161 0 0 0 12.953-6.173 9.169 9.169 0 0 0-8.935-11.23v0Z'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.857 9.857A2.143 2.143 0 1 1 12 12v1.429'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 15.571a1.072 1.072 0 1 0 1.071 1.072A1.086 1.086 0 0 0 12 15.57Z' fill='currentColor' />
  </svg>
)
export default SvgHelp
