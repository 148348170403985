import type { SVGProps } from 'react'
const SvgAnalyticsNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3 27h22a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2ZM6 6h4M6 11h9'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='m23 11-6 10-7-4-4 6' stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgAnalyticsNew
