import type { SVGProps } from 'react'
const SvgScheduleRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#schedule_remove_svg__a)' stroke='currentColor' strokeLinecap='round'>
      <path d='M10.5 5.5v5.323L7.5 14' strokeWidth={1.71} />
      <path d='m23 14-9 9M14 14l9 9' strokeWidth={1.714} strokeLinejoin='round' />
      <path d='M20 10.5a9.5 9.5 0 1 0-9.5 9.5' strokeWidth={1.71} />
    </g>
    <defs>
      <clipPath id='schedule_remove_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgScheduleRemove
