import type { SVGProps } from 'react'
const UnsuccessfulVerificationMark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M9 1L10.9777 2.91324L13.7023 2.52786L14.1777 5.23817L16.6085 6.52786L15.4 9L16.6085 11.4721L14.1777 12.7618L13.7023 15.4721L10.9777 15.0868L9 17L7.02229 15.0868L4.29772 15.4721L3.82229 12.7618L1.39155 11.4721L2.6 9L1.39155 6.52786L3.82229 5.23817L4.29772 2.52786L7.02229 2.91324L9 1Z'
      fill='#FFE6E6'
      stroke='#D13C3C'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 6L6 12' stroke='#D13C3C' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 6L12 12' stroke='#D13C3C' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default UnsuccessfulVerificationMark
