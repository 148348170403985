import type { SVGProps } from 'react'
const ColorfulTag = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2935_15236)'>
      <path
        d='M15.0381 10.6149L10.7035 14.9495C10.5468 15.106 10.3344 15.1939 10.1129 15.1939C9.89148 15.1939 9.67907 15.106 9.52238 14.9495L0.920089 6.34718C0.876865 6.30586 0.843425 6.2554 0.822218 6.19949C0.80101 6.14358 0.792571 6.08363 0.797517 6.02404L1.45495 1.75632C1.46031 1.65463 1.50312 1.55852 1.57513 1.48651C1.64714 1.4145 1.74325 1.37169 1.84495 1.36632L6.11266 0.708894C6.17225 0.703948 6.2322 0.712387 6.28811 0.733595C6.34402 0.754802 6.39448 0.788242 6.4358 0.831466L15.0381 9.43375C15.1946 9.59045 15.2825 9.80286 15.2825 10.0243C15.2825 10.2458 15.1946 10.4582 15.0381 10.6149Z'
        fill='#96DFEB'
        stroke='#2B7C8B'
        strokeWidth='1.11429'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.82008 5.2886C5.12778 5.2886 5.37723 5.03916 5.37723 4.73146C5.37723 4.42376 5.12778 4.17432 4.82008 4.17432C4.51238 4.17432 4.26294 4.42376 4.26294 4.73146C4.26294 5.03916 4.51238 5.2886 4.82008 5.2886Z'
        stroke='#2B7C8B'
        strokeWidth='1.11429'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2935_15236'>
        <rect width='15.6' height='15.6' fill='white' transform='translate(0.240356 0.151855)' />
      </clipPath>
    </defs>
  </svg>
)
export default ColorfulTag
