import type { SVGProps } from 'react'
const OfficeNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8 16.0001H3.5C2.94772 16.0001 2.5 15.5523 2.5 15.0001V4.00006C2.5 3.16797 3.26723 2.71436 4 2.71436H16C16.7328 2.71436 17.5 3.16797 17.5 4.00006V7.00006'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6 5V1' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 5V1' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 5V1' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 8H12' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 11H9' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12.125 14H18.375C18.7499 14 19 14.25 19 14.625V18.375C19 18.75 18.7499 19 18.375 19H12.125C11.75 19 11.5 18.75 11.5 18.375V14.625C11.5 14.25 11.75 14 12.125 14Z'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5 14V12.6667C12.5 11.2 13.7375 10 15.2501 10C16.7625 10 18 11.2 18 12.6667V14'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default OfficeNote
