import type { SVGProps } from 'react'

const Braces = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.99998 2H4.5037C3.79342 2 3.21761 2.78761 3.21761 3.75916V8.63176C3.21761 9.83054 2.77137 10.9468 2.03355 11.5938L0.999985 12.5L2.03355 13.4062C2.77137 14.0532 3.21761 15.1695 3.21761 16.3682V21.2408C3.21761 22.2124 3.79342 23 4.5037 23H4.99998'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 2H19.4963C20.2065 2 20.7823 2.78761 20.7823 3.75916V8.63176C20.7823 9.83054 21.2285 10.9468 21.9664 11.5938L23 12.5L21.9664 13.4062C21.2285 14.0532 20.7823 15.1695 20.7823 16.3682V21.2408C20.7823 22.2124 20.2065 23 19.4963 23H19'
      stroke='currentColor'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Braces
