import type { SVGProps } from 'react'
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#error_svg__a)' stroke='currentColor' strokeWidth={1.714} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M12 8.571v5.143M12 19.714A.857.857 0 1 0 12 18a.857.857 0 0 0 0 1.714Z' />
      <path d='M13.526 1.8a1.714 1.714 0 0 0-3.052 0L1.046 20.657a1.715 1.715 0 0 0 1.525 2.486H21.43a1.714 1.714 0 0 0 1.525-2.486L13.526 1.8Z' />
    </g>
    <defs>
      <clipPath id='error_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgError
