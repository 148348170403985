import type { SVGProps } from 'react'
const ColorfulFax = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none' {...props}>
    <mask id='mask0_2891_199617' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='1' y='10' width='38' height='16'>
      <path
        d='M39 23.1716C39 24.0355 38.692 24.864 38.1439 25.4748H1.85615C1.30797 24.864 1 24.0355 1 23.1716V13.4002C1 12.5364 1.30797 11.7079 1.85615 11.0971C2.40433 10.4862 3.14783 10.1431 3.92308 10.1431H36.0769C36.8522 10.1431 37.5957 10.4862 38.1439 11.0971C38.692 11.7079 39 12.5364 39 13.4002V23.1716Z'
        fill='#60C3D4'
      />
    </mask>
    <g mask='url(#mask0_2891_199617)'>
      <path
        d='M39 23.1716C39 24.0355 38.692 24.864 38.1439 25.4748H1.85615C1.30797 24.864 1 24.0355 1 23.1716V13.4002C1 12.5364 1.30797 11.7079 1.85615 11.0971C2.40433 10.4862 3.14783 10.1431 3.92308 10.1431H36.0769C36.8522 10.1431 37.5957 10.4862 38.1439 11.0971C38.692 11.7079 39 12.5364 39 13.4002V23.1716Z'
        fill='#60C3D4'
      />
      <path
        d='M39 26.1716C39 27.0355 38.692 27.864 38.1439 28.4748H1.85615C1.30797 27.864 1 27.0355 1 26.1716V16.4002C1 15.5364 1.30797 14.7079 1.85615 14.0971C2.40433 13.4862 3.14783 13.1431 3.92308 13.1431H36.0769C36.8522 13.1431 37.5957 13.4862 38.1439 14.0971C38.692 14.7079 39 15.5364 39 16.4002V26.1716Z'
        fill='#C1ECF2'
      />
    </g>
    <path
      d='M38.1439 25.4748C38.692 24.864 39 24.0355 39 23.1716V13.4002C39 12.5364 38.692 11.7079 38.1439 11.0971C37.5957 10.4862 36.8522 10.1431 36.0769 10.1431H3.92308C3.14783 10.1431 2.40433 10.4862 1.85615 11.0971C1.30797 11.7079 1 12.5364 1 13.4002V23.1716C1 24.0355 1.30797 24.864 1.85615 25.4748'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9.14216 18.2857L9.14216 29.6856L15.3462 37.2856L30.8564 37.2856L30.8564 18.2857' fill='white' />
    <path
      d='M9.14216 18.2857L9.14216 29.6856L15.3462 37.2856L30.8564 37.2856L30.8564 18.2857'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='10' y='19' width='20' height='2' fill='#96DFEB' />
    <path d='M7.59229 18.2856H32.4086' stroke='#16606E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <rect x='9.14307' y='2' width='21.7143' height='8.14285' fill='white' stroke='#16606E' strokeWidth='1.5' strokeLinejoin='round' />
  </svg>
)

export default ColorfulFax
