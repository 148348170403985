import type { SVGProps } from 'react'
const ColorfulOldPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41' fill='none' {...props}>
    <mask id='mask0_2891_199541' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='8' y='8' width='32' height='32'>
      <path
        d='M19.6002 38.3431C18.1191 39.2968 16.3561 39.7149 14.6045 39.5277C12.8528 39.3406 11.2179 38.5596 9.97166 37.3145L8.88595 36.2288C8.40438 35.742 8.13428 35.085 8.13428 34.4002C8.13428 33.7155 8.40438 33.0584 8.88595 32.5717L13.4574 28.0288C13.9393 27.549 14.5916 27.2796 15.2717 27.2796C15.9517 27.2796 16.604 27.549 17.0859 28.0288C17.5727 28.5104 18.2298 28.7805 18.9145 28.7805C19.5992 28.7805 20.2563 28.5104 20.7431 28.0288L28.0288 20.7431C28.5104 20.2563 28.7805 19.5992 28.7805 18.9145C28.7805 18.2298 28.5104 17.5727 28.0288 17.0859C27.549 16.604 27.2796 15.9517 27.2796 15.2717C27.2796 14.5916 27.549 13.9393 28.0288 13.4574L32.4288 8.88595C32.9156 8.40438 33.5727 8.13428 34.2574 8.13428C34.9421 8.13428 35.5992 8.40438 36.0859 8.88595L37.1717 9.97166C38.4391 11.2003 39.2468 12.8265 39.46 14.5788C39.6731 16.3311 39.2789 18.1035 38.3431 19.6002C33.3611 26.9968 26.9968 33.3611 19.6002 38.3431Z'
        fill='#60C3D4'
      />
    </mask>
    <g mask='url(#mask0_2891_199541)'>
      <path
        d='M21.6002 40.3431C20.1191 41.2968 18.3561 41.7149 16.6045 41.5277C14.8528 41.3406 13.2179 40.5596 11.9717 39.3145L10.8859 38.2288C10.4044 37.742 10.1343 37.085 10.1343 36.4002C10.1343 35.7155 10.4044 35.0584 10.8859 34.5717L15.4574 30.0288C15.9393 29.549 16.5916 29.2796 17.2717 29.2796C17.9517 29.2796 18.604 29.549 19.0859 30.0288C19.5727 30.5104 20.2298 30.7805 20.9145 30.7805C21.5992 30.7805 22.2563 30.5104 22.7431 30.0288L30.0288 22.7431C30.5104 22.2563 30.7805 21.5992 30.7805 20.9145C30.7805 20.2298 30.5104 19.5727 30.0288 19.0859C29.549 18.604 29.2796 17.9517 29.2796 17.2717C29.2796 16.5916 29.549 15.9393 30.0288 15.4574L34.4288 10.8859C34.9156 10.4044 35.5727 10.1343 36.2574 10.1343C36.9421 10.1343 37.5992 10.4044 38.0859 10.8859L39.1717 11.9717C40.4391 13.2003 41.2468 14.8265 41.46 16.5788C41.6731 18.3311 41.2789 20.1035 40.3431 21.6002C35.3611 28.9968 28.9968 35.3611 21.6002 40.3431Z'
        fill='#60C3D4'
      />
      <path
        d='M17.6002 36.3431C16.1191 37.2968 14.3561 37.7149 12.6045 37.5277C10.8528 37.3406 9.21792 36.5596 7.97166 35.3145L6.88595 34.2288C6.40438 33.742 6.13428 33.085 6.13428 32.4002C6.13428 31.7155 6.40438 31.0584 6.88595 30.5717L11.4574 26.0288C11.9393 25.549 12.5916 25.2796 13.2717 25.2796C13.9517 25.2796 14.604 25.549 15.0859 26.0288C15.5727 26.5104 16.2298 26.7805 16.9145 26.7805C17.5992 26.7805 18.2563 26.5104 18.7431 26.0288L26.0288 18.7431C26.5104 18.2563 26.7805 17.5992 26.7805 16.9145C26.7805 16.2298 26.5104 15.5727 26.0288 15.0859C25.549 14.604 25.2796 13.9517 25.2796 13.2717C25.2796 12.5916 25.549 11.9393 26.0288 11.4574L30.4288 6.88595C30.9156 6.40438 31.5727 6.13428 32.2574 6.13428C32.9421 6.13428 33.5992 6.40438 34.0859 6.88595L35.1717 7.97166C36.4391 9.20034 37.2468 10.8265 37.46 12.5788C37.6731 14.3311 37.2789 16.1035 36.3431 17.6002C31.3611 24.9968 24.9968 31.3611 17.6002 36.3431Z'
        fill='#C1ECF2'
      />
    </g>
    <path
      d='M19.6002 38.3431C18.1191 39.2968 16.3561 39.7149 14.6045 39.5277C12.8528 39.3406 11.2179 38.5596 9.97166 37.3145L8.88595 36.2288C8.40438 35.742 8.13428 35.085 8.13428 34.4002C8.13428 33.7155 8.40438 33.0584 8.88595 32.5717L13.4574 28.0288C13.9393 27.549 14.5916 27.2796 15.2717 27.2796C15.9517 27.2796 16.604 27.549 17.0859 28.0288C17.5727 28.5104 18.2298 28.7805 18.9145 28.7805C19.5992 28.7805 20.2563 28.5104 20.7431 28.0288L28.0288 20.7431C28.5104 20.2563 28.7805 19.5992 28.7805 18.9145C28.7805 18.2298 28.5104 17.5727 28.0288 17.0859C27.549 16.604 27.2796 15.9517 27.2796 15.2717C27.2796 14.5916 27.549 13.9393 28.0288 13.4574L32.4288 8.88595C32.9156 8.40438 33.5727 8.13428 34.2574 8.13428C34.9421 8.13428 35.5992 8.40438 36.0859 8.88595L37.1717 9.97166C38.4391 11.2003 39.2468 12.8265 39.46 14.5788C39.6731 16.3311 39.2789 18.1035 38.3431 19.6002C33.3611 26.9968 26.9968 33.3611 19.6002 38.3431Z'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.0001 2.42871C18.5613 2.42871 16.1464 2.90908 13.8932 3.84238C11.64 4.77568 9.59267 6.14364 7.86816 7.86816C4.38534 11.351 2.42871 16.0747 2.42871 21.0001'
      stroke='white'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.0001 2.42871C18.5613 2.42871 16.1464 2.90908 13.8932 3.84238C11.64 4.77568 9.59267 6.14364 7.86816 7.86816C4.38534 11.351 2.42871 16.0747 2.42871 21.0001'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.9999 9.57178C17.9688 9.57178 15.0619 10.7759 12.9186 12.9191C10.7754 15.0624 9.57129 17.9693 9.57129 21.0003'
      stroke='white'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.0003 9.57178C17.9693 9.57178 15.0624 10.7759 12.9191 12.9191C10.7759 15.0624 9.57178 17.9693 9.57178 21.0003'
      stroke='#16606E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ColorfulOldPhone
