import type { SVGProps } from 'react'

export const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2 7.39377C2 11.5925 5.98786 15.7911 7.46207 16.827C7.78979 17.0577 8.2091 17.0577 8.53793 16.827C10.011 15.7911 14 11.5913 14 7.39377C14 3.86295 11.312 1 7.99945 1C4.68579 1 2 3.86295 2 7.39377Z'
      stroke='currentcolor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 7C6 7.53043 6.21071 8.03914 6.58579 8.41421C6.96086 8.78929 7.46957 9 8 9C8.53043 9 9.03914 8.78929 9.41421 8.41421C9.78929 8.03914 10 7.53043 10 7C10 6.46957 9.78929 5.96086 9.41421 5.58579C9.03914 5.21071 8.53043 5 8 5C7.46957 5 6.96086 5.21071 6.58579 5.58579C6.21071 5.96086 6 6.46957 6 7Z'
      stroke='currentcolor'
      strokeWidth='1.5'
    />
  </svg>
)

export default EnvelopeIcon
