import type { SVGProps } from 'react'
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.39587 4.5L10.2559 2.28C10.4009 1.90413 10.6561 1.58082 10.988 1.35242C11.3199 1.12403 11.713 1.00119 12.1159 1H13.7559C14.1588 1.00119 14.5519 1.12403 14.8838 1.35242C15.2157 1.58082 15.4708 1.90413 15.6159 2.28L16.4759 4.5L19.3959 6.18L21.7559 5.82C22.1488 5.76666 22.5488 5.83135 22.9049 6.00585C23.261 6.18034 23.5572 6.45677 23.7559 6.8L24.5559 8.2C24.7609 8.5487 24.8553 8.95136 24.8268 9.35484C24.7982 9.75832 24.648 10.1437 24.3959 10.46L22.9359 12.32V15.68L24.4359 17.54C24.688 17.8563 24.8382 18.2417 24.8668 18.6452C24.8953 19.0486 24.8009 19.4513 24.5959 19.8L23.7959 21.2C23.5972 21.5432 23.301 21.8197 22.9449 21.9942C22.5888 22.1687 22.1888 22.2333 21.7959 22.18L19.4359 21.82L16.5159 23.5L15.6559 25.72C15.5108 26.0959 15.2557 26.4192 14.9238 26.6476C14.5919 26.876 14.1988 26.9988 13.7959 27H12.1159C11.713 26.9988 11.3199 26.876 10.988 26.6476C10.6561 26.4192 10.4009 26.0959 10.2559 25.72L9.39587 23.5L6.47587 21.82L4.11587 22.18C3.72291 22.2333 3.32296 22.1687 2.96684 21.9942C2.61072 21.8197 2.31452 21.5432 2.11587 21.2L1.31587 19.8C1.11088 19.4513 1.01643 19.0486 1.04499 18.6452C1.07356 18.2417 1.2238 17.8563 1.47587 17.54L2.93587 15.68V12.32L1.43587 10.46C1.1838 10.1437 1.03356 9.75832 1.00499 9.35484C0.976429 8.95136 1.07088 8.5487 1.27587 8.2L2.07587 6.8C2.27452 6.45677 2.57072 6.18034 2.92684 6.00585C3.28296 5.83135 3.68291 5.76666 4.07587 5.82L6.43587 6.18L9.39587 4.5ZM8.93587 14C8.93587 14.7911 9.17047 15.5645 9.61 16.2223C10.0495 16.8801 10.6742 17.3928 11.4051 17.6955C12.136 17.9983 12.9403 18.0775 13.7162 17.9231C14.4922 17.7688 15.2049 17.3878 15.7643 16.8284C16.3237 16.269 16.7047 15.5563 16.859 14.7804C17.0134 14.0044 16.9341 13.2002 16.6314 12.4693C16.3286 11.7384 15.816 11.1136 15.1582 10.6741C14.5004 10.2346 13.727 10 12.9359 10C11.875 10 10.8576 10.4214 10.1074 11.1716C9.3573 11.9217 8.93587 12.9391 8.93587 14V14Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgSettings
