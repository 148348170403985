import type { SVGProps } from 'react'
const SvgHamburgerMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#hamburger-menu_svg__a)' stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M23.143 3.429H.857M23.143 12H.857M23.143 20.572H.857' />
    </g>
    <defs>
      <clipPath id='hamburger-menu_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgHamburgerMenu
