import type { SVGProps } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.303 18.82a8.517 8.517 0 1 0 0-17.034 8.517 8.517 0 0 0 0 17.034ZM22.214 22.214l-5.892-5.892'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgSearch
