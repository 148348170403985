import type { SVGProps } from 'react'
const BellEnabled = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M7.14307 15.4286H9.42878' stroke='currentColor' strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M8.28571 2.28571C7.07329 2.28571 5.91054 2.76734 5.05322 3.62464C4.19592 4.48196 3.71429 5.64471 3.71429 6.85713V10.8571C3.71429 11.3118 3.53367 11.7479 3.21218 12.0694C2.8907 12.3908 2.45466 12.5714 2 12.5714H14.5714C14.1168 12.5714 13.6807 12.3908 13.3592 12.0694C13.0377 11.7479 12.8571 11.3118 12.8571 10.8571V7.99999'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9274 6.64171L8.92871 6.99989L9.26183 4.98349L13.0794 1.19609C13.1413 1.13392 13.215 1.08458 13.2962 1.0509C13.3774 1.01723 13.4645 0.999893 13.5524 0.999893C13.6404 0.999893 13.7274 1.01723 13.8086 1.0509C13.8898 1.08458 13.9635 1.13392 14.0255 1.19609L14.7316 1.89918C14.7941 1.96084 14.8437 2.0342 14.8775 2.11503C14.9113 2.19586 14.9287 2.28256 14.9287 2.37012C14.9287 2.45768 14.9113 2.54438 14.8775 2.6252C14.8437 2.70603 14.7941 2.77939 14.7316 2.84106L10.9274 6.64171Z'
      stroke='currentColor'
      strokeWidth='1.71429'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default BellEnabled
