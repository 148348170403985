import type { SVGProps } from 'react'
const ArrowExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.4284 10.143V15.2858C16.4284 15.5889 16.308 15.8796 16.0937 16.0939C15.8794 16.3083 15.5887 16.4287 15.2856 16.4287H2.71415C2.41104 16.4287 2.12035 16.3083 1.90602 16.0939C1.6917 15.8796 1.57129 15.5889 1.57129 15.2858V2.71439C1.57129 2.41129 1.6917 2.1206 1.90602 1.90627C2.12035 1.69194 2.41104 1.57153 2.71415 1.57153H7.857'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12.4282 1.57153H16.4282V5.57153' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.4286 1.57153L9 9.0001' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default ArrowExpand
