import type { SVGProps } from 'react'
const AutoReply = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.45646 13.4266C9.28551 13.4686 9.10656 13.4653 8.93729 13.417C8.76803 13.3687 8.61432 13.277 8.49139 13.1511L6.48773 11.158L4.337 12.2694L4.41972 9.08232L1.90135 6.56574C1.78286 6.44745 1.69563 6.3016 1.6475 6.14128C1.59937 5.98097 1.59185 5.81121 1.62562 5.64728C1.65834 5.46703 1.73863 5.29878 1.8582 5.15991C1.97777 5.02105 2.13228 4.91662 2.30576 4.8574L12.1678 1.56931C12.3526 1.50069 12.5532 1.48653 12.7458 1.52852C12.9385 1.57051 13.1149 1.66688 13.2543 1.80618C13.3937 1.94547 13.4902 2.12183 13.5322 2.3143C13.5742 2.50677 13.56 2.70724 13.4914 2.8919L12.6688 5.35567'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12.7409 2.3197L4.86411 8.83842' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12.4272 7.70874L10.8135 11.7266C10.6562 12.104 10.8775 12.1961 11.2126 12.1317L13.0913 11.7707L12.2575 16.8187L16.5959 10.3012C16.635 10.2457 16.6568 10.1798 16.6584 10.1119C16.66 10.0439 16.6414 9.97707 16.605 9.91973C16.5685 9.86239 16.5159 9.81717 16.4537 9.78983C16.3915 9.76248 16.3225 9.75423 16.2556 9.76612L14.1232 10.1759L15.738 7.07254L12.4272 7.70874Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default AutoReply
