import type { SVGProps } from 'react'
const SuccessfulVerificationMark = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.6953 1.28128C16.3076 0.906242 15.6924 0.906242 15.3047 1.28128L12.195 4.28956L7.91106 3.68362C7.37698 3.60808 6.87924 3.96971 6.78605 4.50099L6.03851 8.76255L2.21652 10.7904C1.74004 11.0432 1.54992 11.6283 1.7868 12.1129L3.68692 16L1.7868 19.8871C1.54992 20.3717 1.74004 20.9568 2.21652 21.2096L6.03851 23.2374L6.78605 27.499C6.87924 28.0303 7.37698 28.3919 7.91106 28.3164L12.195 27.7104L15.3047 30.7187C15.6924 31.0938 16.3076 31.0938 16.6953 30.7187L19.805 27.7104L24.0889 28.3164C24.623 28.3919 25.1208 28.0303 25.214 27.499L25.9615 23.2374L29.7835 21.2096C30.26 20.9568 30.4501 20.3717 30.2132 19.8871L28.3131 16L30.2132 12.1129C30.4501 11.6283 30.26 11.0432 29.7835 10.7904L25.9615 8.76255L25.214 4.50099C25.1208 3.96971 24.623 3.60808 24.0889 3.68362L19.805 4.28956L16.6953 1.28128Z'
      fill='#CCEDBD'
      stroke='#408525'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.875 17.4815L14.0604 21.0142C14.1728 21.1119 14.3044 21.1819 14.4457 21.2191C14.587 21.2564 14.7343 21.26 14.8771 21.2296C15.0212 21.2011 15.1578 21.1401 15.2776 21.0509C15.3974 20.9617 15.4975 20.8463 15.5712 20.7126L22.125 10.75'
      stroke='#408525'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SuccessfulVerificationMark
