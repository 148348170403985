import type { SVGProps } from 'react'
const NotificationBellActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_442_11726)'>
      <path
        d='M18.4285 9.92858C20.7955 9.92858 22.7143 8.00979 22.7143 5.64286C22.7143 3.27593 20.7955 1.35715 18.4285 1.35715C16.0616 1.35715 14.1428 3.27593 14.1428 5.64286C14.1428 8.00979 16.0616 9.92858 18.4285 9.92858Z'
        fill='#D13C3C'
        stroke='#FF9999'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0274 14.2143V16.8433C17.0274 17.5137 17.2937 18.1568 17.7679 18.631C18.2419 19.105 19.0439 19.3714 19.7143 19.3714H0.857178C1.52768 19.3714 2.32954 19.105 2.80366 18.631C3.27777 18.1568 3.54413 17.5137 3.54413 16.8433L3.54411 10.6702C3.54411 8.88221 4.25439 7.16744 5.5187 5.90314C6.783 4.63884 8.49777 3.92856 10.2857 3.92856'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.57141 23.6429H12' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_442_11726'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
)
export default NotificationBellActive
