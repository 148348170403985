import type { SVGProps } from 'react'
const SvgScheduleEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#schedule_edit_svg__a)' stroke='currentColor' strokeLinecap='round'>
      <path d='m23 13.497-8.952 8.952L10 23l.57-4.048L19.503 10 23 13.497Z' strokeWidth={1.714} strokeLinejoin='round' />
      <path d='M19 6.21A9.51 9.51 0 0 0 10.507 1C5.257 1 1 5.242 1 10.474c0 3.75 2.186 6.99 5.358 8.526' strokeWidth={1.71} />
      <path d='M10.5 5.5v5.323L7.5 14' strokeWidth={1.71} />
    </g>
    <defs>
      <clipPath id='schedule_edit_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgScheduleEdit
