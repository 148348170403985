import type { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m1 14.844 5.04 6.44A1.843 1.843 0 0 0 7.48 22a1.856 1.856 0 0 0 1.44-.66L25 2'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgCheck
