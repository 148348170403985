import type { SVGProps } from 'react'
const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.5 17V2.6c0-.884.704-1.6 1.571-1.6H13.5'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 21.183V7.317C7 6.313 7.787 5.5 8.758 5.5h7.79c.68 0 1.332.279 1.812.775l2.89 2.61c.48.496.75 1.17.75 1.871v10.427C22 22.187 21.213 23 20.242 23H8.758C7.787 23 7 22.187 7 21.183Z'
      stroke='currentColor'
      strokeWidth={1.714}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M18.035 14.536h-7.07M14.5 11v7.071' stroke='currentColor' strokeWidth={1.8} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgDuplicate
