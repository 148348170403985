import type { SVGProps } from 'react'
const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#Diamond_svg__a)' stroke='currentColor' strokeWidth={1.143} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M12.16 1.76H3.84a1.223 1.223 0 0 0-.971.525l-2.08 2.88A1.2 1.2 0 0 0 .857 6.64l6.24 7.188a1.142 1.142 0 0 0 1.806 0l6.24-7.188a1.2 1.2 0 0 0 .069-1.475l-2.08-2.88a1.222 1.222 0 0 0-.972-.525v0Z' />
      <path d='m7.406 1.748-2.789 4.32L8 14.24M8.629 1.748l2.777 4.32L8 14.24M.595 6.068h14.811' />
    </g>
    <defs>
      <clipPath id='Diamond_svg__a'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgDiamond
