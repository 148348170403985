import type { SVGProps } from 'react'
const SvgCircles = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeWidth={1.25}>
      <circle cx={-6} cy={20} r={17.375} />
      <circle cx={-6} cy={20} r={13.321} />
      <circle cx={-6} cy={20} r={9.19} />
    </g>
  </svg>
)
export default SvgCircles
