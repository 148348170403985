import type { SVGProps } from 'react'

const ContactUser = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='28' viewBox='0 0 26 28' fill='none' {...props}>
    <path
      d='M24.5 26.5C23.7186 23.8864 22.184 21.6065 20.1174 19.989C18.0505 18.3716 15.5583 17.5 13 17.5C10.4417 17.5 7.94948 18.3716 5.88266 19.989C3.81587 21.6065 2.28122 23.8864 1.5 26.5'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 16.9951C17.4183 16.9951 21 13.4134 21 8.99511C21 4.57683 17.4183 0.995117 13 0.995117C8.58171 0.995117 5 4.57683 5 8.99511C5 13.4134 8.58171 16.9951 13 16.9951Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.5 12.5C15.5 12.5 14.8647 13.5 13 13.5C11.1353 13.5 10.5 12.5 10.5 12.5'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.5 7.49978C20.4826 7.49989 20.4654 7.49995 20.4481 7.49995C18.1929 7.49995 16.1681 6.53336 14.7819 5C13.3957 6.53339 11.371 7.5 9.11574 7.5C7.80615 7.5 6.57425 7.17404 5.5 6.60044'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ContactUser
