import type { SVGProps } from 'react'
const ColorfulAirplane = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.0822 5.15196L8.065 2.19876C7.98369 2.11894 7.88475 2.05937 7.77616 2.02484C7.66758 1.99031 7.5524 1.98181 7.43992 2L6.06346 2.21618C5.93898 2.23397 5.82117 2.28347 5.72134 2.35993C5.6215 2.43638 5.54301 2.53722 5.49339 2.65276C5.44377 2.7683 5.42469 2.89466 5.43799 3.0197C5.45129 3.14474 5.49652 3.26426 5.56933 3.36678L7.43912 5.71738'
      fill='#D64700'
    />
    <path
      d='M11.0822 5.15196L8.065 2.19876C7.98369 2.11894 7.88475 2.05937 7.77616 2.02484C7.66758 1.99031 7.5524 1.98181 7.43992 2L6.06346 2.21618C5.93898 2.23397 5.82117 2.28347 5.72134 2.35993C5.6215 2.43638 5.54301 2.53722 5.49339 2.65276C5.44377 2.7683 5.42469 2.89466 5.43799 3.0197C5.45129 3.14474 5.49652 3.26426 5.56933 3.36678L7.43912 5.71738'
      stroke='#FFCA99'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8742 4.50193L4.16014 6.23378L3.21123 4.91841L1.69477 5.1026C1.576 5.12263 1.46399 5.17161 1.36864 5.24521C1.27329 5.31882 1.19754 5.41478 1.14808 5.52461C1.09862 5.63444 1.07698 5.75476 1.08506 5.87494C1.09315 5.99512 1.13071 6.11146 1.19444 6.21368L3.04077 9.0752C3.19109 9.31693 3.40908 9.5092 3.6677 9.62814C3.92632 9.74708 4.21417 9.78746 4.49553 9.74426L7.18259 9.32224L6.0546 12.9681C6.0162 13.0876 6.00943 13.215 6.03494 13.338C6.06045 13.4609 6.11738 13.5751 6.20016 13.6695C6.28295 13.7639 6.38877 13.8352 6.50732 13.8765C6.62588 13.9178 6.75311 13.9277 6.87662 13.9052L8.25967 13.688C8.36525 13.6701 8.46569 13.6295 8.55406 13.569C8.64243 13.5086 8.71662 13.4296 8.77151 13.3377L11.513 8.62187L15.4634 7.95231C15.6969 7.92747 15.923 7.85562 16.128 7.74107C16.3331 7.62652 16.5128 7.47165 16.6564 7.28579C16.7999 7.09993 16.9044 6.88692 16.9635 6.65961C17.0225 6.4323 17.0349 6.19538 17 5.96313C16.9611 5.72531 16.8745 5.49781 16.7454 5.29432C16.6163 5.09083 16.4474 4.91556 16.2488 4.77905C16.0502 4.64255 15.8261 4.54762 15.5898 4.5C15.3536 4.45238 15.1102 4.45303 14.8742 4.50193Z'
      fill='#D64700'
      stroke='#FFCA99'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulAirplane
