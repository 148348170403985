import type { SVGProps } from 'react'
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.99986 16.4286C13.1026 16.4286 16.4284 13.1027 16.4284 9C16.4284 4.89731 13.1026 1.57143 8.99986 1.57143C4.89717 1.57143 1.57129 4.89731 1.57129 9C1.57129 13.1027 4.89717 16.4286 8.99986 16.4286Z'
      stroke='currentColor'
      strokeWidth='1.71'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 6.14285V9L11.9029 12.3829' stroke='currentColor' strokeWidth='1.71' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgCalendar
