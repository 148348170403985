import type { SVGProps } from 'react'
const ColorfulMapMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1.3em' viewBox='0 0 18 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.98514 1C11.103 1.00031 13.1341 1.84186 14.6316 3.33955C16.129 4.83724 16.9703 6.86841 16.9703 8.98631C16.9703 12.7313 11.2135 22.0153 9.47555 24.7318C9.42286 24.814 9.35032 24.8817 9.26463 24.9286C9.17893 24.9754 9.08282 25 8.98514 25C8.88747 25 8.79136 24.9754 8.70566 24.9286C8.61996 24.8817 8.54743 24.814 8.49474 24.7318C6.75676 22.0153 1 12.7337 1 8.98631C0.999847 7.93759 1.20628 6.89911 1.6075 5.93017C2.00872 4.96123 2.59688 4.08082 3.33838 3.33921C4.07989 2.59759 4.96021 2.00931 5.92909 1.60794C6.89797 1.20658 7.93642 1 8.98514 1Z'
      fill='#97DCF7'
    />
    <path
      d='M8.98514 24.9962C8.88739 24.9961 8.79122 24.9714 8.70552 24.9244C8.61982 24.8774 8.54733 24.8095 8.49474 24.7271C6.75676 22.0107 1 12.729 1 8.98165C1.00093 6.86446 1.84263 4.83431 3.34003 3.33756C4.83743 1.84081 6.86796 1 8.98514 1V24.9962Z'
      fill='#DCF3FC'
    />
    <path
      d='M8.98535 12.6484C11.237 12.6484 13.0624 10.8231 13.0624 8.57141C13.0624 6.31973 11.237 4.49438 8.98535 4.49438C6.73367 4.49438 4.90833 6.31973 4.90833 8.57141C4.90833 10.8231 6.73367 12.6484 8.98535 12.6484Z'
      fill='#DCF3FC'
    />
    <path
      d='M8.98535 12.6484C7.90406 12.6484 6.86705 12.2189 6.10246 11.4543C5.33787 10.6897 4.90833 9.6527 4.90833 8.57141C4.90833 7.49012 5.33787 6.45311 6.10246 5.68852C6.86705 4.92393 7.90406 4.49438 8.98535 4.49438V12.6484Z'
      fill='white'
    />
    <path
      d='M8.98535 12.6484C11.237 12.6484 13.0624 10.8231 13.0624 8.57141C13.0624 6.31973 11.237 4.49438 8.98535 4.49438C6.73367 4.49438 4.90833 6.31973 4.90833 8.57141C4.90833 10.8231 6.73367 12.6484 8.98535 12.6484Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.98514 1C11.103 1.00031 13.1341 1.84186 14.6316 3.33955C16.129 4.83724 16.9703 6.86841 16.9703 8.98631C16.9703 12.7313 11.2135 22.0153 9.47555 24.7318C9.42286 24.814 9.35032 24.8817 9.26463 24.9286C9.17893 24.9754 9.08282 25 8.98514 25C8.88747 25 8.79136 24.9754 8.70566 24.9286C8.61996 24.8817 8.54743 24.814 8.49474 24.7318C6.75676 22.0153 1 12.7337 1 8.98631C0.999847 7.93759 1.20628 6.89911 1.6075 5.93017C2.00872 4.96123 2.59688 4.08082 3.33838 3.33921C4.07989 2.59759 4.96021 2.00931 5.92909 1.60794C6.89797 1.20658 7.93642 1 8.98514 1V1Z'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ColorfulMapMarker
