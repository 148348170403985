import type { SVGProps } from 'react'
const ShowHatMagician = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 22' fill='none' {...props}>
    <path d='M2.76758 2.5293V5.60965' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.30789 4.07031H1.22754' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.9375 5.375V7.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.875 6.3125H15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M14.3202 13.0601C14.0246 13.1384 13.745 13.2426 13.4902 13.3682'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.86635 20.5837C8.32938 19.2914 6.24501 18.4545 3.97979 18.2199C3.72196 18.1981 3.48383 18.1041 3.31137 17.9562C3.13891 17.8082 3.04434 17.6166 3.04592 17.4186V10.9682C3.04591 10.8524 3.07873 10.7381 3.14213 10.6329C3.20553 10.5278 3.298 10.4344 3.41317 10.3592C3.52632 10.2853 3.65875 10.2307 3.80168 10.1989C3.94459 10.1672 4.09473 10.159 4.24212 10.1749C6.40817 10.4494 8.3897 11.2738 9.86635 12.5147V20.5837Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.86719 20.5837C11.4042 19.2914 13.4885 18.4545 15.7537 18.2199C16.0116 18.1981 16.2498 18.1041 16.4222 17.9562C16.5947 17.8082 16.6892 17.6166 16.6876 17.4186V10.9682C16.6876 10.8524 16.6548 10.7381 16.5914 10.6329C16.528 10.5278 16.4356 10.4344 16.3204 10.3592C16.2073 10.2853 16.0748 10.2307 15.9319 10.1989C15.7889 10.1672 15.6388 10.159 15.4914 10.1749C13.3254 10.4494 11.3438 11.2738 9.86719 12.5147V20.5837Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.83009 7.73882L7.70486 6.49703C7.73042 6.46989 7.74923 6.43711 7.75979 6.40135C7.77035 6.36559 7.77236 6.32785 7.76565 6.29117L7.23715 4.86487C7.22194 4.82403 7.21851 4.7797 7.22731 4.737C7.23611 4.6943 7.25674 4.65493 7.28691 4.62344C7.31703 4.59192 7.35545 4.56955 7.39768 4.55886C7.43997 4.54817 7.48437 4.54961 7.5259 4.56301L8.98358 4.99344C9.01961 5.00294 9.05733 5.00425 9.09393 4.99726C9.13053 4.99028 9.16512 4.97518 9.19511 4.95307L10.3524 4.02455C10.3863 3.99744 10.4271 3.98038 10.4703 3.97532C10.5134 3.97025 10.5571 3.97739 10.5963 3.99592C10.6356 4.01444 10.6689 4.04361 10.6924 4.08012C10.7159 4.11662 10.7287 4.15899 10.7293 4.2024L10.7526 5.72146C10.7523 5.75877 10.7616 5.79553 10.7794 5.8283C10.7972 5.86107 10.8231 5.88878 10.8545 5.90883L12.1173 6.75351C12.1534 6.77763 12.182 6.81139 12.1999 6.85096C12.2178 6.89053 12.2242 6.93432 12.2184 6.97735C12.2127 7.02039 12.1949 7.06093 12.1672 7.09439C12.1396 7.12785 12.1031 7.15287 12.0619 7.16661L10.6232 7.65515C10.5883 7.66834 10.5569 7.68917 10.5311 7.7161C10.5054 7.74304 10.486 7.77541 10.4744 7.81082L10.0439 9.26851C10.0311 9.30633 10.0087 9.3402 9.97889 9.36683C9.9491 9.39345 9.91294 9.41194 9.87392 9.42049C9.83489 9.42904 9.79431 9.42737 9.75612 9.41564C9.71793 9.40391 9.68341 9.38252 9.65591 9.35354L8.74546 8.13504C8.72189 8.10568 8.69168 8.08233 8.65732 8.06693C8.62296 8.05153 8.58543 8.0445 8.54783 8.04643L7.03005 8.10636C6.98638 8.10858 6.94293 8.09841 6.90479 8.07703C6.86664 8.05565 6.8353 8.02392 6.81441 7.9855C6.79351 7.94709 6.78387 7.90354 6.78665 7.8599C6.78944 7.81626 6.80449 7.77429 6.83009 7.73882Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.7814 5.82763L18.5308 1.20327' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default ShowHatMagician
