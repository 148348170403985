import type { SVGProps } from 'react'
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 18 16' fill='none' {...props}>
    <path
      d='M1.66797 11.7703V12.9131C1.66797 13.5193 1.90878 14.1007 2.33744 14.5294C2.76609 14.958 3.34747 15.1988 3.95368 15.1988H14.2394C14.8456 15.1988 15.427 14.958 15.8556 14.5294C16.2843 14.1007 16.5251 13.5193 16.5251 12.9131V11.7703'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.61523 6.66675L8.99985 10.6155L12.3845 6.66675'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9 10.6667L9 1.33337' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default SvgDownload
