import type { SVGProps } from 'react'
const Minus = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3427_73127)'>
      <path d='M10 6H2' stroke='currentcolor' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_3427_73127'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default Minus
