import type { SVGProps } from 'react'

const AddUser = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='User Single Neutral Male'>
      <g id='user-single-neutral-male--close-geometric-human-person-single-up-user-male'>
        <path
          id='Vector'
          d='M7.99998 12.5C6.44276 12.5 4.92577 12.9358 3.66771 13.7445C2.40966 14.5532 1.47553 15.6932 1 17'
          stroke='currentColor'
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g id='Group 2615'>
          <path id='Vector 1873' d='M13 11L13 17' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Vector 1874' d='M16 14L10 14' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <g id='Group 2616'>
          <path
            id='Vector_2'
            d='M7.24998 10.5C9.87333 10.5 12 8.37333 12 5.74998C12 3.12664 9.87333 1 7.24998 1C4.62664 1 2.5 3.12664 2.5 5.74998C2.5 8.37333 4.62664 10.5 7.24998 10.5Z'
            stroke='currentColor'
            strokeWidth='1.4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Ellipse 592'
            d='M8.75 7.5C8.75 7.5 8.36884 8 7.25 8C6.13116 8 5.75 7.5 5.75 7.5'
            stroke='currentColor'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Intersect'
            d='M11.9138 4.84562C11.9032 4.84569 11.8926 4.84572 11.8821 4.84572C10.5013 4.84572 9.26174 4.24167 8.41307 3.28342C7.56442 4.24169 6.32481 4.84576 4.94411 4.84576C4.14235 4.84576 3.38815 4.64206 2.73047 4.28359C3.34839 2.37788 5.13817 1 7.24979 1C9.56389 1 11.4915 2.65481 11.9138 4.84562Z'
            stroke='currentColor'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AddUser
