import type { SVGProps } from 'react'
const SvgCalendarNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.321 1a.75.75 0 0 0-1.5 0v1.25H2.077C1.09 2.25.25 3.03.25 4.043v9.913c0 1.013.84 1.794 1.827 1.794h11.846c.987 0 1.827-.78 1.827-1.793V4.043c0-1.012-.84-1.793-1.827-1.793H12.18V1a.75.75 0 0 0-1.5 0v1.25H8.75V1a.75.75 0 0 0-1.5 0v1.25H5.321V1Zm-1.5 2.75V5a.75.75 0 0 0 1.5 0V3.75H7.25V5a.75.75 0 0 0 1.5 0V3.75h1.929V5a.75.75 0 0 0 1.5 0V3.75h1.744c.203 0 .327.154.327.293v9.913c0 .14-.124.294-.327.294H2.077c-.203 0-.327-.153-.327-.293V4.043c0-.14.124-.293.327-.293H3.82ZM3.25 8A.75.75 0 0 1 4 7.25h8a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 8ZM4 10.679a.75.75 0 0 0 0 1.5h4.571a.75.75 0 0 0 0-1.5H4Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgCalendarNote
