import type { SVGProps } from 'react'
const SvgLike = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m5.92 9.853 4.354-6.96A1.868 1.868 0 0 1 11.886 2a1.903 1.903 0 0 1 1.971 1.886v4.989h7.526a1.971 1.971 0 0 1 1.886 2.262l-1.372 8.846a1.955 1.955 0 0 1-1.937 1.714H8.714a3.429 3.429 0 0 1-1.543-.36l-1.234-.617M5.92 9.853V20.67M1.857 9.853H5.92V20.67H1.857A.857.857 0 0 1 1 19.813V10.71a.857.857 0 0 1 .857-.857Z'
      stroke='currentColor'
      strokeWidth={1.71}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default SvgLike
