import type { SVGProps } from 'react'
const SvgLayers = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#Layers_svg__a)' stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M12.806 13.329a2.023 2.023 0 0 1-1.611 0L1.423 8.803a.96.96 0 0 1 0-1.714l9.772-4.56a2.023 2.023 0 0 1 1.611 0l9.772 4.525a.96.96 0 0 1 0 1.715l-9.772 4.56Z' />
      <path d='m23.143 13.1-10.457 4.818a1.714 1.714 0 0 1-1.423 0L.857 13.1' />
      <path d='m23.143 17.672-10.457 4.817a1.714 1.714 0 0 1-1.423 0L.857 17.672' />
    </g>
    <defs>
      <clipPath id='Layers_svg__a'>
        <path fill='currentColor' transform='translate(0 .5)' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgLayers
