import type { SVGProps } from 'react'
const SvgCampaign = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#campaign_svg__a)' stroke='currentColor' strokeWidth={1.71} strokeLinecap='round' strokeLinejoin='round'>
      <path d='m9.989 18.77 3.688 3.669a1.86 1.86 0 0 0 1.777.507 1.896 1.896 0 0 0 1.37-1.251l6.057-18.142a1.893 1.893 0 0 0-2.436-2.434L2.29 7.172a1.895 1.895 0 0 0-1.252 1.454 1.859 1.859 0 0 0 .508 1.69l4.636 4.633-.153 5.867 3.96-2.046ZM21.5 2.5 7 14.5' />
    </g>
    <defs>
      <clipPath id='campaign_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCampaign
