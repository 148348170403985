import type { SVGProps } from 'react'
const SuccessfulVerificationMark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M9 1L10.9777 2.91324L13.7023 2.52786L14.1777 5.23817L16.6085 6.52786L15.4 9L16.6085 11.4721L14.1777 12.7618L13.7023 15.4721L10.9777 15.0868L9 17L7.02229 15.0868L4.29772 15.4721L3.82229 12.7618L1.39155 11.4721L2.6 9L1.39155 6.52786L3.82229 5.23817L4.29772 2.52786L7.02229 2.91324L9 1Z'
      fill='#E9E9E9'
      stroke='#666666'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.28589 7.28655C7.28589 6.9475 7.38643 6.61606 7.5748 6.33415C7.76317 6.05223 8.0309 5.83251 8.34415 5.70276C8.65739 5.57301 9.00208 5.53906 9.33461 5.60521C9.66715 5.67135 9.97261 5.83462 10.2124 6.07437C10.4521 6.31412 10.6154 6.61957 10.6815 6.95211C10.7477 7.28465 10.7137 7.62934 10.584 7.94258C10.4542 8.25583 10.2345 8.52356 9.95258 8.71193C9.67067 8.9003 9.33923 9.00084 9.00017 9.00084V10.1437'
      stroke='#666666'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00021 11.8574C8.83068 11.8574 8.66496 11.9077 8.52401 12.0019C8.38305 12.0961 8.27319 12.2299 8.20831 12.3866C8.14344 12.5432 8.12646 12.7155 8.15954 12.8818C8.19261 13.0481 8.27424 13.2008 8.39412 13.3207C8.51399 13.4405 8.66672 13.5222 8.83299 13.5552C8.99926 13.5883 9.1716 13.5713 9.32822 13.5065C9.48485 13.4416 9.61871 13.3317 9.7129 13.1908C9.80708 13.0498 9.85735 12.8841 9.85735 12.7146C9.85439 12.4882 9.76314 12.2719 9.60303 12.1117C9.44292 11.9516 9.22662 11.8604 9.00021 11.8574Z'
      fill='#666666'
    />
  </svg>
)
export default SuccessfulVerificationMark
