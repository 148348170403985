import type { SVGProps } from 'react'
const SvgAddBatch2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.194 14.681a1 1 0 0 1-.49 1.327l-10.46 4.819a2.715 2.715 0 0 1-2.253 0l-.005-.003L1.58 16.008a1 1 0 1 1 .84-1.815l10.403 4.816a.714.714 0 0 0 .59 0h.001l10.454-4.816a1 1 0 0 1 1.326.49Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.194 19.253a1 1 0 0 1-.49 1.326l-10.46 4.82a2.715 2.715 0 0 1-2.253 0l-.005-.003L1.58 20.579a1 1 0 1 1 .84-1.815l10.403 4.815a.715.715 0 0 0 .59 0h.001l10.454-4.816a1 1 0 0 1 1.326.49ZM16.959 4.535l-2.73-1.274A3.022 3.022 0 0 0 13 3v2c.136 0 .271.027.397.08l.747.349V5h1.807c.424 0 .796-.185 1.008-.465Zm1.837.858c-.38.687-1.028 1.158-1.718 1.405l5.97 2.786-9.65 4.47c-.126.053-.261.08-.398.08v2c.414 0 .824-.084 1.204-.25l.022-.01 9.771-4.525.03-.014A1.96 1.96 0 0 0 24 7.82l-5.204-2.428Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m12.144 5.295.46-.215c.126-.053.26-.08.397-.08V3a3.023 3.023 0 0 0-1.228.261L9.26 4.433c.193.336.602.567 1.075.567h1.808v.295ZM9.052 6.738c-.665-.274-1.273-.76-1.617-1.453L2.001 7.821a1.96 1.96 0 0 0 .003 3.528l9.771 4.526.022.01c.38.165.79.25 1.204.25v-2c-.137 0-.272-.028-.398-.08l-9.65-4.47 6.1-2.847Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.144 1.193c0-.659-.448-1.193-1-1.193-.553 0-1 .534-1 1.193V3h-1.808c-.658 0-1.192.448-1.192 1s.534 1 1.192 1h1.808v1.807c0 .659.447 1.193 1 1.193.552 0 1-.534 1-1.193V5h1.807c.658 0 1.193-.448 1.193-1s-.535-1-1.193-1h-1.807V1.193Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgAddBatch2
